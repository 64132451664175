<script>
  import Loading from "../Loading/Loading.svelte";
  export let src;
  export let loading;
  export let size = "normal";
  export let onerror;
  export let style;

  const SIZES_OPTIONS = [
    "xs",
    "small",
    "normal",
    "medium",
    "large",
    "xl",
    "xxl",
    "triple_xl",
  ];
  if (!SIZES_OPTIONS.includes(size)) {
    throw new Error(
      `Invalide value: the props value of 'size' is equal to '${size}' in <AppImg> component. The validates values are: 'normal' and 'large'`
    );
  }
</script>

<figure class="c-img c-img--{size}">
  {#if loading}
    <Loading color="blue" size="32" radius="24px"/>
  {:else}
    <img {src} alt="" {onerror} {style} />
  {/if}
</figure>

<style lang="scss">
  figure {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: $bg-shadow;
    &.c-img {
      &--xs {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
      &--small {
        width: 40px;
        height: 40px;
        /* border-radius: 50%; */
      }
      &--normal {
        width: 64px;
        height: 64px;
      }
      &--medium {
        width: 72px;
        height: 72px;
      }
      &--large {
        width: 88px;
        height: 88px;
      }
      &--xl {
        width: 96px;
        height: 96px;
      }
      &--xxl {
        width: 112px;
        height: 112px;
      }
      &--triple_xl {
        width: 144px;
        height: 144px;
      }
    }
    img {
      position: absolute;
      width: 100%;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
</style>
