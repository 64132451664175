<script>
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  import Dialog, { Content } from "@smui/dialog";
  import AppText from "../Text/AppText.svelte";
  import Spacing from "../Spacing/Spacing.svelte";
  import AppButton from "../Button/AppButton.svelte";
  import { i18n } from "../../stores/i18n";
  export let dialog;
  export let title = "Google Authenticator";
  let cancelled_loading = false;
  let validated_loading = false;
  let error = false;
  const dispatch = createEventDispatcher();
  onMount(() => {
    if (dialog) {
      refs[0].focus();
    }
  });

  async function onCancelled() {
    if (cancelled_loading) return;
    cancelled_loading = true;
    if (error) {
      error = false;
    }
    try {
      await dispatch("cancell");
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      cancelled_loading = false;
    }
  }
  function onBackspace(e, index) {
    key = e.code;
    if (e.code === "Backspace" && index > 0 && values[index] === "") {
      e.preventDefault();
      refs[index - 1].select();
      key = null;
    }
  }
  async function input(e, index) {
    if (key === "Backspace" || validated_loading) {
      return;
    }

    const val = e.target.value;
    if (!/^\d*$/.test(val)) {
      values[index] = "";
      return;
    }
    if (index < values.length - 1) {
      refs[index + 1].select();
      return;
    }
    if (index === values.length - 1) {
      validated_loading = true;
      if (error) {
        error = false;
      }
      try {
        await dispatch("validate", { pw: values.join("") });
      } catch (e) {
        console.error(e);
        error = true;
      } finally {
        values = Array(6).fill("");
        validated_loading = false;
        dialog.close();
      }
    }
  }
  let values = Array(6).fill("");
  let _refs = [];
  let key = null;
  $: refs = _refs && _refs.filter(Boolean);
</script>

<Dialog
  escapeKeyAction=""
  scrimClickAction=""
  id="test"
  style="z-index:99999;"
  bind:this={dialog}
>
  <div class:loading={validated_loading || cancelled_loading}>
    <Content>
      <AppText color="blue" weight="bold" size="large">
        {title}
      </AppText>
      <Spacing y="12" divider divider_props={{ top: 2, bottom: 2 }} />
      <AppText>
        {$i18n(
          "Veuillez saisir le mot de passe à 6 chiffres",
          "Please enter the 6-digit password"
        )}
      </AppText>
      <Spacing y="8" />
      <div class="flex">
        {#each values as _, index}
          {#if index === 3}
            <Spacing x="4" />
          {/if}
          <input
            type="tel"
            maxlength="1"
            disabled={validated_loading}
            bind:this={refs[index]}
            bind:value={values[index]}
            on:keydown={(e) => onBackspace(e, index)}
            on:input={(e) => input(e, index)}
          />
        {/each}
      </div>
      <div class="error">
        {#if error}
          <AppText color="danger"
            >{$i18n(
              "Une erreur est survenue, veuillez recommencer",
              "An error has occurred, please try again"
            )}</AppText
          >
        {/if}
      </div>
      <AppButton
        block
        disabled={cancelled_loading || validated_loading}
        loading={cancelled_loading}
        color="alert"
        on:click={onCancelled}>Retour</AppButton
      >
    </Content>
  </div>
</Dialog>

<style lang="scss">
  .error {
    height: 36px;
    display: flex;
    align-items: center;
  }
  .loading {
    cursor: progress;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    width: 348px;
    input {
      border-radius: 3px;
      width: 44px;
      height: 48px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: $blue;
      border: 1px solid $blue;
      background: white;
      outline: none;
      transition: all 200ms ease;
      &:disabled {
        background: $gray;
        border: 1px solid $gray;
        color: gray;
      }
    }
  }
</style>
