<script>
  import { navigate } from "svelte-routing";
  import AppText from "../../components/Text/AppText.svelte";
  import AppButton from "../../components/Button/AppButton.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
  import AppBadge from "../../components/Badge/AppBadge.svelte";
  import { i18n, formatNumber } from "../../stores/i18n";
  import API from "../../utils/api";
  export let clubDeal;
  export let documents;
  export let gotoTab;

  const linkReference = (eventType, evt) => {
    switch (eventType) {
      case "bondRead":
        gotoTab(0);
        break;
      case "documentRead":
      case "documentRead":
      case "documentCreated":
      case "documentUpdated":
      case "documentValidated":
        gotoTab(1, evt.detail); // TODO: goto tab + open folders + goto the right document??????
        break;
    }
  };
  const getEventName = (eventType) => {
    switch (eventType) {
      case "bondCreated":
        return $i18n("Création de la dataroom", "Dataroom creation");
      case "bondUpdated":
        return $i18n("Mise à jour de la dataroom", "Dataroom updated");
      case "bondTeaserRead":
      case "bondRead":
        return $i18n("Accès à la dataroom", "Dataroom access");

      case "documentRead":
        return $i18n("Accès au document", "Document access");
      case "documentCreated":
        return $i18n("Ajout d'un document par le gestionnaire", "Addition of a document by the manager");
      case "documentUpdated":
        return $i18n("Mise à jour d'un document par le gestionnaire", "Document update by the manager");
      case "documentValidated":
        return $i18n("Validation d'un document par le gestionnaire", "Document validation by the manager");

      case "loginSuccess":
        return $i18n("Connexion", "Connection");
      case "loginFailure":
        return $i18n("Echec de connexion", "Connection failure");
    }
  };

  const cell_style = `
    text-align:center;
    background: rgb(226, 226, 226);
    color: rgb(180, 180, 180);
    font-weight: 500;
    font-family: "Lato";
    font-weight: 700;
  `;
</script>

<Spacing y="24">
  <DataTable class="table">
    <Head>
      <Row>
        <Cell>
          <AppText weight="700"
            >{$i18n("Date de l'évènement", "Event date")}</AppText
          >
        </Cell>
        <Cell>
          <AppText weight="700"
            >{$i18n("Type d'évènement", "Event type")}</AppText
          >
        </Cell>
        <Cell>
          <AppText weight="700"
            >{$i18n("Cible de l'événement", "Event target")}</AppText
          >
        </Cell>
      </Row>
    </Head>

    <Body>
      {#if clubDeal.events.length}
        {#each clubDeal.events as event}
          <Row>
            <Cell>
              <AppText
                >{new Date(event.date).toLocaleDateString("fr-Fr", {
                  month: "long",
                  year: "numeric",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </AppText>
            </Cell>
            <Cell>{getEventName(event.eventType)}</Cell>
            <Cell>
              {#if event.detail && event.detail != ""}
                <AppButton
                  size="small"
                  color={event.eventType == "bondRead" ? "light" : "primary"}
                  on:click={() => linkReference(event.eventType, event)}
                  width="196px"
                >
                  {event.detail}
                </AppButton>
              {/if}
            </Cell>
          </Row>
        {/each}
      {:else}
        <Row>
          <Cell style={cell_style} colspan="4">
            <p>
              {$i18n("Aucun évènement n'a eu lieu", "No event has taken place")}
            </p>
          </Cell>
        </Row>
      {/if}
    </Body>
  </DataTable>
</Spacing>
