<script>
  /**
   * @var {String} margin - props margin
   * @var {String} padding - props padding
   */
  export let y = "0";
  export let x = "0";
  export let divider_props = {
    top: "0",
    bottom: "0",
  };
  export let divider = false;

  const style = `
padding:${y}px ${x}px;
`;
  const divider_style = `
    margin-top: ${divider_props.top || 0}px;
    margin-bottom: ${divider_props.bottom || 0}px;
`;
</script>

<div {style}>
  <slot />
  {#if divider}
    <span style={divider_style} />
  {/if}
</div>

<style lang="scss">
  span {
    display: block;
    min-width: 100%;
    border-bottom: 1px solid $gray;
  }
</style>
