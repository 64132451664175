<script>
  import { Item } from "@smui/list";
  import { navigate } from "svelte-routing";
  import ItemUser from "../Item/ItemUser.svelte";
  import ButtonMenu from "./ButtonMenu.svelte";
  import ButtonDisconnect from "./ButtonDisconnect.svelte";
  import { createEventDispatcher } from "svelte";
  import { ACCOUNT_PATH, KYC_PATH } from "../../constants";
  import { i18n } from "../../stores/i18n";
  import { profile, profile_links, type } from "../../stores/profile";

  export let title = "";

  const dispatch = createEventDispatcher();
  function disconnectEvent() {
    dispatch("disconnect");
  }
</script>

<ButtonMenu {title} icon="account_box">
  <ItemUser on:click={() => navigate(ACCOUNT_PATH)} icon="person">
    {$i18n("Mon profil", "My profil")}
  </ItemUser>

  {#if $profile.kycUrl}
    <ItemUser
      on:click={() => window.open($profile.kycState == "validated" ? $profile.kycPdfUrl: $profile.kycUrl)}
      icon="person_search"
    >
      {$i18n("Mon KYC", "My KYC")}
    </ItemUser>
  {/if}
  <ItemUser on:click={() => window.open("/assets/terms-" + $i18n("fr", "en") + ".pdf")} icon="help_outline">
    {$i18n("CGU", "Terms")}
  </ItemUser>
  <Item>
    <ButtonDisconnect on:click={disconnectEvent}>
      {$i18n("Se déconnecter", "Disconnect")}
    </ButtonDisconnect>
  </Item>
</ButtonMenu>
