<script>
  import AppText from "./AppText.svelte";
  import Spacing from "../Spacing/Spacing.svelte";
  export let id;
  export let color = "primary";
</script>

<section {id}>
  <AppText weight="900" {color} size="medium">
    <slot />
  </AppText>
  <Spacing y="8" />
  <main>
    <Spacing divider block />
  </main>
  <!-- <Spacing x="12" /> -->
</section>

<style lang="scss">
  section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    main {
      flex: 1 1 auto;
      margin-left: 8px;
      margin-top: 3px;
    }
  }
</style>
