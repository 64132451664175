<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let active;
  export let loading;
  const click = () => {
    active ? dispatch("remove") : dispatch("add");
  };

</script>

<button class:active on:click={click} class:loading>
  <span class="loading"><div><span><b /></span></div></span>
  <span class="material-icons add">add</span>
  <span class="material-icons check ">check</span>
  <span class="material-icons remove">remove</span>
</button>

<style lang="scss">
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  button {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    outline: 0;
    border: 0;
    background: #bdbdbd;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: transparent;
      border-radius: 8px;
    }
    &:hover {
      &::before {
        background: rgba(120, 120, 120, 0.07);
      }
    }
    .material-icons {
      position: absolute;
      font-size: 20px;
      color: white;
      &.check,
      &.remove {
        opacity: 0;
      }
    }
    .loading {
      opacity: 0;
      div {
        display: block;
        width: 100%;
        height: 100%;
        /* background: $danger; */
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        border-radius: 8px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        span {
          display: inline-block;
          position: relative;
          width: 16px;
          height: 16px;
          b {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border: 2px solid #fff;
            border-radius: 50%;
            animation: rotate 800ms cubic-bezier(0.5, 0, 0.2, 0.8) infinite;
            border-color: #fff transparent transparent transparent;
          }
        }
      }
    }
    &.loading {
      .loading {
        opacity: 1;
      }
      .add,
      .check,
      .remove {
        opacity: 0;
      }
    }
    &.active:not(.loading) {
      background: #09e063;
      .material-icons {
        &.add,
        &.remove {
          opacity: 0;
        }
        &.check {
          opacity: 1;
        }
      }
      &:hover:not(.loading) {
        background: red;
        .material-icons {
          &.add,
          &.check {
            opacity: 0;
          }
          &.remove {
            opacity: 1;
          }
        }
      }
    }
  }
</style>
