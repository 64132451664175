<script>
  import { LOGO_PATH } from "../../constants";
  import AppContent from "./AppContent.svelte";
  import Text from "../Text/AppText.svelte";
  import Spacing from "../Spacing/Spacing.svelte";
  import { i18n } from "../../stores/i18n";
</script>

<AppContent>
  <div>
    <main>
      <figure>
        <img src={LOGO_PATH} alt="logo" />
      </figure>
      <Spacing x="40">
        <span>
          <Text size="xl" weight="900">{$i18n("Connexion", "Connection")}</Text>
        </span>
        <slot />
      </Spacing>
    </main>
  </div>
</AppContent>

<style lang="scss">
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    main {
      width: 440px;
      background-color: $white;
      box-shadow: $blue-shadow;
      padding: $spacing_xl;
      border-radius: $border-radius_normal;
      figure {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          height: 42px;
        }
      }
      span {
        display: block;
        margin: $spacingY_large;
      }
    }
  }
</style>
