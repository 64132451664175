<script>
  import { navigate } from "svelte-routing";
  import AppText from "../../components/Text/AppText.svelte";
  import AppButton from "../../components/Button/AppButton.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
  import AppBadge from "../../components/Badge/AppBadge.svelte";
  import { i18n, formatNumber } from "../../stores/i18n";
  import API from "../../utils/api";
  //export let disabled;
  export let data;
  export let resetTab;
  // à remplacer par let data
  const FindSubscriber = (event) => {
    return data.clubDealInvestors.find((el) => el.id === event.subscriberId);
  };
  const getSubscriberName = (event) => {
    let s = FindSubscriber(event);
    return s? `${s.firstName} ${s.lastName}`: $i18n("Inconnu", "Unknown");
  };


  const linkReference = (eventType) => {
    switch (eventType) {
      case "bondRead":
        resetTab(); // TODO: go to the "information" tab (once information and documents are separated)
        break;
      case "documentRead":
        resetTab(); // TODO: go to the "document" tab (once information and documents are separated)
        break;
    }
  };
  const getEventName = (eventType) => {
    switch (eventType) {
      case "bondRead":
        return $i18n("Accès à la dataroom", "Dataroom access");
      case "documentRead":
        return $i18n("Téléchargement d'un document", "Document download");
    }
  };

  const cell_style = `
    text-align:center;
    background: rgb(226, 226, 226);
    color: rgb(180, 180, 180);
    font-weight: 500;
    font-family: "Lato";
    font-weight: 700;
  `;
</script>

<Spacing y="24">
  <DataTable class="table" style="width: 100%">
    <Head>
      <Row>
        <Cell>
          <AppText weight="700"
            >{$i18n("Date de l'évènement", "Event date")}</AppText
          >
        </Cell>
        <Cell>
          <AppText weight="700"
            >{$i18n("Type d'évènement", "Event type")}</AppText
          >
        </Cell>
        <Cell>
          <AppText weight="700"
            >{$i18n("Nom du participant", "Participant's name")}</AppText
          >
        </Cell>
        <Cell>
          <AppText weight="700"
            >{$i18n("Cible de l'événement", "Event target")}</AppText
          >
        </Cell>
      </Row>
    </Head>

    <Body>
      {#if data.events.length}
        {#each data.events as event}
          <Row>
            <Cell>
              <AppText
                >{new Date(event.date).toLocaleDateString("fr-Fr", {
                  month: "long",
                  year: "numeric",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </AppText>
            </Cell>
            <Cell>{getEventName(event.eventType)}</Cell>
            <Cell
              >{getSubscriberName(event)}</Cell
            >
            <Cell>
              <AppButton
                size="small"
                color={event.eventType == "bondRead" ? "light" : "primary"}
                on:click={() => linkReference(event.eventType)}
                width="196px"
              >
                {event.detail == ""
                  ? $i18n("Inconnu", "Unknown")
                  : event.detail}
              </AppButton>
            </Cell>
          </Row>
        {/each}
      {:else}
        <Row>
          <Cell style={cell_style} colspan="4">
            <p>
              {$i18n("Aucun évènement n'a eu lieu", "No event has taken place")}
            </p>
          </Cell>
        </Row>
      {/if}
    </Body>
  </DataTable>
</Spacing>
