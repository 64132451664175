<script>
    import { i18n } from "../stores/i18n";
    import Checkbox from "@smui/checkbox";
    import AppText from "./Text/AppText.svelte";
    import AppButton from "./Button/AppButton.svelte";

    export let investors = [];
    export let documents = [];

    function getParentFolder(doc) {
        let idx = doc.fileName.lastIndexOf("/");
        return idx > -1? doc.fileName.substring(0, idx+1): null;
    }

    // interleave folders into documents
    export function discardChanges(){
        documents = JSON.parse(JSON.stringify(newDocuments)); 
    }
    
    let newDocuments = [];
    export function matrixUpdate(matrixDocuments, matrixInvestors){
        if (!matrixDocuments || !matrixInvestors) return;
        documents = matrixDocuments;
        investors = matrixInvestors;
        newDocuments = [];
        let lastParentFolder = null;
        for(let doc of documents.filter(d => !d.isFolder)){
            let parentFolder = getParentFolder(doc);
            if (parentFolder && parentFolder != lastParentFolder){ // new folder
                let isPublic = true;
                let allInvestorsIds = investors.map(i => i.id);
                for (let doc of getDocumentsInsideFolder({ fileName: parentFolder })){
                    let authorizedIds = doc.authorized;
                    if (! allInvestorsIds.every(id => authorizedIds.includes(id))){
                        isPublic = false;
                        break;
                    }
                }     
                if (isPublic){
                    newDocuments.push(
                        { access: "publicAccess", fileName: parentFolder, authorized: allInvestorsIds, isFolder: true },
                    );
                } else {
                    newDocuments.push(
                        { access: "protectedAccess", fileName: parentFolder, authorized: [], isFolder: true },
                    );
                }
            }
            newDocuments.push(doc);
            lastParentFolder = parentFolder;
        }
        discardChanges();
    }
    matrixUpdate(documents, investors);


    function getDocumentsInsideFolder(folder) {
        return documents.filter(doc => doc.fileName.startsWith(folder.fileName) && doc.fileName != folder.fileName);
    }

    function updateRecursively(doc, updateFn){
        updateFn(doc);
        if (doc.isFolder){
            for (let sub of getDocumentsInsideFolder(doc)){
                updateFn(sub);
            }
        }
        documents = documents;
    }
    function check(doc, invest) {
        updateRecursively(doc, (doc) => {            
            doc.access = "protectedAccess";
            if (!doc.authorized.includes(invest.id)) {
                doc.authorized = [...doc.authorized, invest.id];
            }
        })
    }
    function uncheck(doc, invest) {
        updateRecursively(doc, (doc) => {  
            if (doc.access == "publicAccess"){
                doc.authorized = investors.map((invest) => invest.id);
            }          
            doc.access = "protectedAccess";
            if (doc.authorized.includes(invest.id)) {
                doc.authorized = doc.authorized.filter((id) => id != invest.id);
            }
        })
    }
    function checkAllForDocument(doc) {
        updateRecursively(doc, (doc) => {            
            doc.access = "publicAccess";
            doc.authorized = investors.map((invest) => invest.id);
            documents = documents;
        })
    }
    function uncheckAllForDocument(doc) {
        updateRecursively(doc, (doc) => {            
            doc.access = "privateAccess";
            doc.authorized = [];
            documents = documents;
        })
    }
    function checkAllForInvestor(invest) {
        for (let doc of documents) {
            doc.access = "protectedAccess";
            if (!doc.authorized.includes(invest.id)) {
                doc.authorized.push(invest.id);
            }
        }
        documents = documents;
    }
    function uncheckAllForInvestor(invest) {
        for (let doc of documents) {
            doc.access = "protectedAccess";
            if (doc.authorized.includes(invest.id)) {
                doc.authorized = doc.authorized.filter((id) => id != invest.id);
            }
        }
        documents = documents;
    }
    function getNbParentFoldersOf(fileName){
        return fileName.split("/").length - 1 - (fileName.endsWith("/")? 1: 0);
    }
    function checkAll() {
        for (let doc of documents) {
            updateRecursively(doc, (doc) => {            
                doc.access = "publicAccess";
                doc.authorized = investors.map((invest) => invest.id);
                documents = documents;
            })
        }
    }
    function uncheckAll() {
        for (let doc of documents) {
            updateRecursively(doc, (doc) => {            
                doc.access = "privateAccess";
                doc.authorized = [];
                documents = documents;
            })
        }
    }

    export function getDocumentsAccessSettings(){
        return documents.filter((doc) => !doc.fileName.endsWith("/"));
    }

    function allSubDocsAuthorizedFor(doc, investId){
        if (! doc.isFolder) return false;
        for (let d of getDocumentsInsideFolder({ fileName: doc.fileName })){
            if (d.access == "publicAccess") continue;
            if (d.access == "privateAccess") return false;
            if (d.access == "protectedAccess" && !d.authorized.includes(investId)) return false;
        }
        return true;
    }
</script>

<div class="matrix">
    <table>
        <thead>
            <tr>
                <th align="left">
                    <AppButton
                        width="120px"
                        size="small"
                        color="light"
                        on:click={checkAll}
                        >
                        {$i18n("Tout cocher", "Check all")}                        
                    </AppButton>

                    <span style="margin-left: 10px"/>

                    <AppButton
                        width="120px"
                        size="small"
                        color="light"
                        on:click={uncheckAll}
                        >
                        {$i18n("Tout décocher", "Uncheck all")}                        
                    </AppButton>

                    <span style="margin-left: 10px"/>

                    <AppButton
                        width="120px"
                        size="small"
                        color="light"
                        on:click={discardChanges}
                        >
                        {$i18n("Réinitialiser", "Reset")}                        
                    </AppButton>
                    <!-- <AppText weight="900" color="blue" size="medium">
                    </AppText> -->
                </th>
                {#each investors as invest}
                <th>
                    {#if invest.lastName}
                        {invest.firstName} {invest.lastName}
                    {:else if invest.email}
                        {invest.email}
                    {/if}

                    {#if invest.company}
                        &nbsp; ({invest.company})
                    {/if}
                    
                    {#if documents.every((doc) => doc.access == "publicAccess" || (doc.access == "protectedAccess" && doc.authorized.includes(invest.id)))}
                        <Checkbox
                            checked
                            on:click={() => {
                                uncheckAllForInvestor(invest);
                            }}
                            style="display: block; margin: auto; margin-top: 5px; margin-bottom: 5px"
                        />
                    {:else}
                        <Checkbox
                            on:click={() => {
                                checkAllForInvestor(invest);
                            }}
                            style="display: block; margin: auto; margin-top: 5px; margin-bottom: 5px"
                        />
                    {/if}
                </th>
                {/each}
            </tr>
        </thead>
        <tbody>
            {#each documents as doc}
            <tr>
                <th>
                    <div
                        style="display: grid; grid-template-columns: 40px max-content; padding-left: {getNbParentFoldersOf(doc.fileName) * 20}px"
                    >
                        {#if doc.access == "publicAccess" || (doc.access == "protectedAccess" && investors.every( (invest) => doc.authorized.includes(invest.id) ))}
                            <Checkbox
                                checked
                                on:click={() => uncheckAllForDocument(doc)}
                                style=""
                            />
                        {:else}
                            <Checkbox
                                on:click={() => checkAllForDocument(doc)}
                                style=""
                            />
                        {/if}
                        <div style="margin: auto">
                            {#if doc.fileName.length > 60}
                                <AppText weight="900" color="blue" size="medium"  title={doc.fileName}>
                                    {doc.fileName.substring(0, 60) + "..."}
                                </AppText>
                            {:else}
                                <AppText weight="900" color="blue" size="medium">
                                    {doc.fileName}
                                </AppText>
                            {/if}
                        </div>
                    </div>

                    <!-- {doc.fileName} -->
                </th>
                {#each investors as invest}
                    <td>
                        {#if doc.access == "publicAccess" || (doc.access == "protectedAccess" && doc.authorized.includes(invest.id)) || allSubDocsAuthorizedFor(doc, invest.id)}
                            <Checkbox
                                checked
                                on:click={() => uncheck(doc, invest)}
                            />
                        {:else}
                            <Checkbox
                                type="checkbox"
                                on:click={() => check(doc, invest)}
                            />
                        {/if}
                    </td>
                {/each}
            </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .matrix {
        margin: auto;
        box-shadow: 2px 2px 10px #f4f4f4;
        overflow: auto;
        max-height: 70vh;
        width: 100%;
        /* width: fit-content; */
        /* max-width: 80%; */
        font-family: "Lato";
    }
    .matrix table {
        table-layout: fixed;
    }
    .matrix thead th {
        position: sticky;
        top: 0;
        z-index: 1;
        background: white;
    }
    .matrix thead th:first-child {
        left: 0;
        z-index: 2;
    }
    .matrix tbody th {
        position: sticky;
        left: 0;
        z-index: 1;
        background: white;
    }
    .matrix td {
        text-align: center;
    }
    .matrix td,
    .matrix th {
        padding: 5px;
        min-width: 100px;
        /* border-bottom: 1px solid #e2e2e2; */
    }
</style>
