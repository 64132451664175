<script>
  import AppContent from "../../components/Layout/AppContent.svelte";
  import AppText from "../../components/Text/AppText.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
  import Input from "../../components/Form/Input.svelte";
  import AppButton from "../../components/Button/AppButton.svelte";
  import FormField from "@smui/form-field";
  import { navigate } from "svelte-routing";
  import Radio from "@smui/radio";
  import { ADVISOR_THANKYOU_PATH } from "../../constants";
  import { id as bondId, clubdeal } from "../../stores/clubdeal";
  import { i18n } from "../../stores/i18n";
  import API from "../../utils/api";
  import { onMount } from "svelte";
  import {
    PERIODICITIES_LIST,
    INSTRUMENTS_LISTS,
    REGION_LIST,
    COUNTRY_LIST,
  } from "../../constants";
  export let goTo;
  export let decrement_step;
  export let id;

  let loading = true;

  let options = [
    {
      name: "SA",
      disabled: false,
    },
    {
      name: "SAS",
      disabled: false,
    },
    {
      name: "SCA",
      disabled: false,
    },
    {
      name: "SARL",
      disabled: false,
    },
  ];
  let selected = "SARL";

  let documents = [];
  // onMount(async () => {
  //   try {
  //     const data = await API.get("document", { bondId: $bondId });
  //     if (data) {
  //       documents = data.documents || [];
  //     }
  //   } finally {
  //     loading = false;
  //   }
  // });
  loading = false;

  let headquartersLocation = "";
  try {
    if ($clubdeal.company.headquartersRegion) {
      headquartersLocation = REGION_LIST.find(
        (e) => e.value == $clubdeal.company.headquartersRegion
      ).label;
    } else if ($clubdeal.company.headquartersCountry) {
      headquartersLocation = $i18n(
        ...COUNTRY_LIST.find(
          (e) => e.value == $clubdeal.company.headquartersCountry
        ).label
      );
    }
  } catch (error) {
    console.log(error);
  }

  let disableValidation = false;
  // if ($clubdeal.clubDealInvestors.length < 1 || $clubdeal.clubDealInvestors.length > 149){
  //   disableValidation = true;
  // }

  const validateClubDeal = async () => {
    if (! disableValidation){ 
      try {
        loading = true;
        await API.post("clubdealAdvisorValidation", { id: $bondId });
      } catch(e){
        // TODO: inform user
        console.log(e)
      } finally {
        loading = false;
      }
      navigate(ADVISOR_THANKYOU_PATH)
    }
  }

  const cell_style = `
  text-align:center;
  background: rgb(226, 226, 226);
  color: rgb(180, 180, 180);
  font-weight: 500;
  font-family: "Lato";
  font-weight: 700;
  `;
</script>

<style lang="scss">
  section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    main {
      flex: 1 1 auto;
      margin-left: 8px;
      margin-top: 3px;
    }
  }
  div {
    /* background: rgb(235, 235, 235);
    border-radius: 4px; */
    padding: 24px;
    margin: 12px 0;
  }
  aside {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
</style>

<AppContent type="advisor" title={$i18n('Validation', 'Validation')} {loading}>
  <section>
    <AppText weight="900" size="medium">
      {$i18n('Informations: Société', 'Information: Company')}
    </AppText>
    <Spacing y="8" />
    <main>
      <Spacing divider block />
    </main>
    <Spacing x="12" />
    <AppButton
      icon
      color="light"
      on:click={goTo('/manager/dossier/informations/society')}>
      west
      <span slot="text">{$i18n('Modifier', 'Modify')}</span>
    </AppButton>
  </section>
  <div>
    <!-- <aside> -->
      <Input
        label={$i18n('Raison sociale', 'Company name')}
        value={$clubdeal.company.name}
        crop
        disabled />
      <!-- <Input
        label={$i18n('Siren', 'Siren')}
        value={$clubdeal.company.siret}
        crop
        disabled /> -->
    <!-- </aside> -->
    <!-- <Spacing y="12" />
    <AppText weight="700">{$i18n('Forme juridique:', 'Legal form:')}</AppText>
    <Spacing y="4" />
    {#each options as option}
      <FormField>
        <Radio
          disabled
          color="primary"
          bind:group={$clubdeal.company.legalForm}
          value={option.name} />
        <span
          style="margin-right:4px;"
          slot="label">{option.name}{option.disabled ? ' (disabled)' : ''}</span>
      </FormField>
    {/each} -->
    <Spacing y="12" />
    <Input
      label={$i18n('Lieu du siège social', 'Head office location')}
      value={headquartersLocation}
      crop
      disabled />
  </div>
  <section>
    <AppText weight="900" size="medium">
      {$i18n("Informations: Caractéristiques de l'opération", 'Information: Characteristics of the operation')}
    </AppText>
    <Spacing y="8" />
    <main>
      <Spacing divider block />
    </main>
    <Spacing x="12" />
    <AppButton
      icon
      color="light"
      on:click={goTo('/manager/dossier/informations/caracteristiques')}>
      west
      <span slot="text">{$i18n('Modifier', 'Modify')}</span>
    </AppButton>
  </section>
  <div>
    <aside>
      <Input
        label={$i18n('Montant', 'Amount')}
        value={$clubdeal.amount ? $i18n($clubdeal.amount.toLocaleString('fr') + ' €', '€' + $clubdeal.amount.toLocaleString('en')) : $i18n('Non précisé', 'Unspecified')}
        crop
        disabled />
      <Input
        label={$i18n('Taux', 'Rate')}
        value={$clubdeal.rate ? $clubdeal.rate.toLocaleString($i18n('fr', 'en')) + ' %' : $i18n('Non précisé', 'Unspecified')}
        crop
        disabled />
    </aside>
    <Spacing y="12" />
    <Input
      label={$i18n('Durée', 'Duration')}
      value={$clubdeal.maturity ? $clubdeal.maturity + $i18n(' mois', ' months') : $i18n('Non précisée', 'Unspecified')}
      crop
      disabled />
    <!-- <Spacing y="12" />
    <AppText weight="700">
      {$i18n('Typologie du financement:', 'Typology of funding:')}
    </AppText>
    <Spacing y="4" />
    {#each INSTRUMENTS_LISTS as instrument}
      <FormField>
        <Radio
          color="primary"
          bind:group={$clubdeal.instrument}
          value={instrument.value}
          disabled />
        <span
          style="margin-right:4px;"
          slot="label">{$i18n(...instrument.label)}</span>
      </FormField>
    {/each}
    <Spacing y="12" />
    <AppText weight="700">
      {$i18n('Fréquence des coupons (si applicable):', 'Coupon frequency (if applicable):')}
    </AppText>
    <Spacing y="4" />
    {#each PERIODICITIES_LIST as periodicity}
      <FormField>
        <Radio
          disabled
          color="primary"
          bind:group={$clubdeal.periodicity}
          value={periodicity.value} />
        <span
          style="margin-right:4px;"
          slot="label">{$i18n(...periodicity.name)}</span>
      </FormField>
    {/each} -->
  </div>
  <!-- <section>
    <AppText weight="700" size="medium">{$i18n('Informations: Participants', 'Information: Participants')}</AppText>
    <Spacing y="8" />
    <main>
      <Spacing divider block />
    </main>
    <Spacing x="12" />
    <AppButton
      icon
      color="light"
      on:click={goTo('/manager/dossier/informations/participants')}>
      west
      <span slot="text">{$i18n('Modifier', 'Modify')}</span>
    </AppButton>
  </section>
  <Spacing y="12" />
  <DataTable class="table" table$aria-label="People list">
    <Head>
      <Row>
        <Cell>
          <AppText weight="700">{$i18n('Prénom', 'First name')}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n('Nom', 'Last name')}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n('Email', 'Email')}</AppText>
        </Cell>
      </Row>
    </Head>

    <Body>
      {#if $clubdeal.clubDealInvestors.length}
        {#each $clubdeal.clubDealInvestors as investor, id}
          <Row>
            <Cell>{investor.firstName}</Cell>
            <Cell>{investor.lastName}</Cell>
            <Cell>{investor.email}</Cell>
          </Row>
        {/each}
      {:else}
        <Row>
          <Cell style={cell_style} colspan="4">
            <p>{$i18n('Aucun participant ajouté', 'No participant added')}</p>
          </Cell>
        </Row>
      {/if}
    </Body>
  </DataTable>
  <Spacing y="12" /> -->

  <!-- <section>
    <AppText weight="700" size="medium">{$i18n('Documents', 'Documents')}</AppText>
    <Spacing y="8" />
    <main>
      <Spacing divider block />
    </main>
    <Spacing x="12" />
    <AppButton
      icon
      color="light"
      on:click={goTo('/manager/dossier/documents')}>
      west
      <span slot="text">{$i18n('Modifier', 'Modify')}</span>
    </AppButton>
  </section>
  <Spacing y="12" />
  <DataTable class="table">
    <Head>
      <Row>
        <Cell>
          <AppText weight="700">{$i18n('Nom du fichier', 'File name')}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n('Format', 'Format')}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n('Télécharger', 'Download')}</AppText>
        </Cell>
      </Row>
    </Head>
    <Body>
      {#if documents.length == 0}
        <Row>
          <Cell style={cell_style} colspan="4">
            <p>{$i18n('Aucun document ajouté', 'No document added')}</p>
          </Cell>
        </Row>
      {:else}
        {#each documents as doc}
          <Row>
            <Cell>
              <AppText weight="700">{doc.fileName}</AppText>
            </Cell>
            <Cell>
              <AppText weight="700">{doc.contentType}</AppText>
            </Cell>
            <Cell />
          </Row>
        {/each}
      {/if}
    </Body>
  </DataTable> -->
  <section style="display:flex; justify-content:space-between;" slot="footer">
    <AppButton on:click={decrement_step}>{$i18n('Précédent', 'Previous')}</AppButton>
    <AppButton on:click={validateClubDeal} disabled={disableValidation}>
      {$i18n('Valider', 'Validate')}
    </AppButton>
  </section>
</AppContent>
