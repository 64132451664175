<script>
  import { createEventDispatcher } from "svelte";
  import Dialog, { Content } from "@smui/dialog";
  import AppText from "../Text/AppText.svelte";
  import Spacing from "../Spacing/Spacing.svelte";
  import AppButton from "../Button/AppButton.svelte";
  import API from "../../utils/api";
  import { i18n } from "../../stores/i18n";
  import AppImg from "../Img/AppImg.svelte";
  export let dialog;
  export let two_fa = false;
  let qrCode = null;
  let cancelled_loading = false;
  let validated_loading = false;
  let error = false;

  //   async function onActivate() {
  //       try
  //     await API.post("mfa");
  //   }

  const dispatch = createEventDispatcher();
  //   onMount(() => {
  //     if (dialog) {
  //       dialog.open();
  //     }
  //   });
  //   async function onCancelled() {
  //     if (cancelled_loading) return;
  //     cancelled_loading = true;
  //     if (error) {
  //       error = false;
  //     }
  //     try {
  //       await dispatch("cancell");
  //     } catch (e) {
  //       console.error(e);
  //       cancelled_loading = false;
  //       error = true;
  //     }
  //   }
  async function removeMfa() {
    if (validated_loading || !qrCode) return;
    try {
      validated_loading = true;
      await API.del("mfa");
      qrCode = false;
      dispatch("remove");
    } catch (e) {
      console.error(e);
    } finally {
      validated_loading = false;
    }
  }
  async function activeQrCode() {
    if (validated_loading) return;
    validated_loading = true;
    try {
      const res = await API.post("mfa");
      qrCode = `data:image/png;base64,${res.qrCode}`;
      refs[0].focus();
    } catch (e) {
      error = true;
      console.error(e);
    } finally {
      validated_loading = false;
    }
  }

  function onBackspace(e, index) {
    key = e.code;
    if (e.code === "Backspace" && index > 0 && values[index] === "") {
      e.preventDefault();
      refs[index - 1].select();
      key = null;
    }
  }
  async function input(e, index) {
    if (key === "Backspace" || validated_loading) {
      return;
    }

    const val = e.target.value;
    if (!/^\d*$/.test(val)) {
      values[index] = "";
      return;
    }
    if (index < values.length - 1) {
      refs[index + 1].select();
      return;
    }
    if (index === values.length - 1) {
      validated_loading = true;
      if (error) {
        error = false;
      }
      try {
        await API.post("mfa", { code: values.join("") });
        dispatch("validate");
        dialog.close()
      } catch (e) {
        dispatch("catch", { error: e });
        error = true;
      } finally {
        dispatch("finally");
        validated_loading = false;
      }
    }
  }
  let values = Array(6).fill("");
  let _refs = [];
  let key = null;
  $: refs = _refs && _refs.filter(Boolean);
</script>

<Dialog
  escapeKeyAction=""
  scrimClickAction=""
  class="two_fa"
  style="z-index:99999;"
  bind:this={dialog}
>
  <Content>
    <AppText color="blue" weight="bold" size="large">
      {$i18n("Double facteur d'authentification", "Double authentificator")}
    </AppText>
    <Spacing y="12" divider divider_props={{ top: 2, bottom: 2 }} />
    <Spacing y="12"/>
      <div style="display:flex; justify-content: center">
        <AppImg
          bind:src={qrCode}
          loading={validated_loading}
          size="triple_xl"
        />
      </div>
      <Spacing y="12" />
      <div class="flex">
        {#each values as _, index}
          {#if index === 3}
            <Spacing x="12" />
          {/if}
          <input
            type="tel"
            maxlength="1"
            disabled={validated_loading || !qrCode}
            bind:this={refs[index]}
            bind:value={values[index]}
            on:keydown={(e) => onBackspace(e, index)}
            on:input={(e) => input(e, index)}
          />
        {/each}
      </div>
    <Spacing y="6"/>
    <div class="container">
      {#if !qrCode}
        <div style="width: 348px;">
          <AppText>
            Êtes-vous sûr de vouloir activer le double facteur d'authentification ?
          </AppText>
          <Spacing y="8" />
          <div class="flex">
            <AppButton
              block
              loading={validated_loading}
              on:click={activeQrCode}
            >
              Oui
            </AppButton>
            <Spacing x="20" />
            <AppButton
              block
              disabled={validated_loading}
              color="alert"
              on:click={() => dialog.close()}
            >
              Non
            </AppButton>
          </div>
        </div>
      {:else}
        <div style="width:100%;">
          <div class="error">
            {#if error}
              <AppText color="danger">
                {$i18n(
                  "Une erreur est survenue, veuillez recommencer",
                  "An error has occurred, please try again"
                )}
              </AppText>
            {/if}
          </div>
          <AppButton color="alert" bind:disabled={validated_loading} block on:click={() => dialog.close()}>
            Annuler
          </AppButton>
        </div>
      {/if}
      <!-- <button on:click={removeMfa}>supprimer mfa</button> -->
    </div>
  </Content>
</Dialog>

<style lang="scss">
  .flex {
    display: flex;
    justify-content: space-between;
    width: 348px;
    input {
      border-radius: 3px;
      width: 44px;
      height: 48px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: $blue;
      border: 1px solid $blue;
      background: white;
      outline: none;
      transition: all 200ms ease;
      &:disabled {
        background: $gray;
        border: 1px solid $gray;
        color: gray;
      }
    }
  }
  .error {
    display: flex;
    height: 32px;
    align-items: flex-start;
  }
  .container {
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  :global(.mdc-dialog.twofa) {
    transition: all 200ms ease !important;
    :global(.mdc-dialog__container),
    :global(.mdc-dialog__surface),
    :global(.mdc-dialog__content) {
      transition: all 200ms ease !important;
    }
  }
</style>
