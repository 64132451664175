<script>
  import { onMount, onDestroy } from "svelte";
  import AppContent from "../../components/Layout/AppContent.svelte";
  import Dialog, { Content, Title } from "@smui/dialog";
  import Input from "../../components/Form/Input.svelte";
  import AppButton from "../../components/Button/AppButton.svelte";
  import AppText from "../../components/Text/AppText.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
  import { i18n } from "../../stores/i18n";
  import { clubdeal } from "../../stores/clubdeal";
  import { autoUpdate } from "../../utils/functions/autoUpdate";
  import { EMAIL_RULE } from "../../constants";
  import AppSnackbar from "../../components/Snackbar/AppSnackbar.svelte";

  export let increment_step;
  export let decrement_step;
  export let id;

  let snackSaved;
  autoUpdate(clubdeal, onMount, onDestroy, (state) => {
    snackSaved.forceOpen();
  });

  let sliderDialog;
  let lastName = "";
  let firstName = "";
  let company = "";
  let email = "";
  $: disabled = ((firstName === "" || lastName === "") && company === "") || !EMAIL_RULE.test(email);
  // $: disabled = !EMAIL_RULE.test(email);

  const addInvestor = () => {
    $clubdeal.clubDealInvestors = [
      ...$clubdeal.clubDealInvestors,
      { firstName, lastName, company, email }
    ];
    // email = "";
    lastName = firstName = company= email = "";
    sliderDialog.close();
  };
  const onDelete = (email) => {
    $clubdeal.clubDealInvestors = $clubdeal.clubDealInvestors.filter(
      (investor) => investor.email !== email
    );
  };

  const cell_style = `
  text-align:center;
  background: rgb(226, 226, 226);
  color: rgb(180, 180, 180);
  font-weight: 500;
  font-family: "Lato";
  font-weight: 700;
  `;
</script>

<style lang="scss">
  section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  div {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  :global(.mdc-data-table.table) {
    width: 100%;
  }
</style>

<AppContent
  type="advisor"
  title={$i18n('Informations: Participants', 'Information: Participants')}>
  <Dialog style="z-index:999;" bind:this={sliderDialog}>
    <Content>
      <AppText color="blue" size="large" weight="900">
        {$i18n('Ajouter un nouveau participant', 'Add a new participant')}
      </AppText>
      <Spacing y="12" />
      <Input label={$i18n('Prénom', 'First name')} bind:value={firstName} />
      <Input label={$i18n('Nom', 'Last name')} bind:value={lastName} />
      <Input label={$i18n('Société', 'Company')} bind:value={company} />
      <Input label={$i18n('Email', 'Email')} bind:value={email} />
      <AppButton {disabled} block on:click={addInvestor}>
        {$i18n('Ajouter', 'Add')}
      </AppButton>
    </Content>
  </Dialog>
  <DataTable class="table" table$aria-label="People list">
    <Head>
      <Row>
        <Cell>
          <AppText weight="700">{$i18n('Prénom', 'First name')}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n('Nom', 'Last name')}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n('Société', 'Company')}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n('Email', 'Email')}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n('Retirer', 'Remove')}</AppText>
        </Cell>
      </Row>
    </Head>

    <Body>
      {#if $clubdeal.clubDealInvestors.length}
        {#each $clubdeal.clubDealInvestors as investor}
          <Row>
            <Cell>{investor.firstName}</Cell>
            <Cell>{investor.lastName}</Cell>
            <Cell>{investor.company}</Cell>
            <Cell>{investor.email}</Cell>
            <Cell>
              <AppButton
                icon
                color="alert"
                on:click={() => onDelete(investor.email)}>
                close
              </AppButton>
            </Cell>
          </Row>
        {/each}
      {:else}
        <Row>
          <Cell style={cell_style} colspan="5">
            <p>{$i18n('Aucun participant ajouté', 'No participant added')}</p>
          </Cell>
        </Row>
      {/if}
    </Body>
  </DataTable>
  <Spacing y="12" />
  <AppButton icon size="big" block on:click={() => sliderDialog.open()}>
    person_add
    <span slot="text"><AppText color="white">
        {$i18n('Ajouter un nouveau participant', 'Add a new participant')}
      </AppText></span>
  </AppButton>
  <Spacing y="12" />
  <section slot="footer">
    <AppButton on:click={() => decrement_step()}>
      {$i18n('Précédent', 'Previous')}
    </AppButton>
    <AppButton on:click={() => increment_step()}>
      {$i18n('Suivant', 'Next')}
    </AppButton>
  </section>
  <AppSnackbar
    bind:value={snackSaved}
    labelText={$i18n('Vos informations ont bien été enregistrées', 'Your information has been saved')} />
</AppContent>
