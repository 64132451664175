export const delay = (ms, callback) =>
    new Promise((resolve) => {
        if (callback) {
            const init_timer = Math.round(ms / 1000) - 1;
            let time_left = init_timer;
            const one_second = 1000;
            const interval = setInterval(() => {
                time_left -= 1;
                callback(time_left);
                if (time_left < 0) {
                    clearInterval(interval);
                    callback(init_timer);
                }
            }, one_second);
        }
        setTimeout(resolve, ms);
    });