<Link {to}>
    <img alt="logo firmfunding" src="/assets/logo/logo-fr.svg"/>
</Link>
<script>
    import { Link } from "svelte-routing";
    export let to;
</script>

<style lang="scss">
img {
    height: 36px;
}
</style>