<script>
  import { createEventDispatcher } from "svelte";
  import AppText from "../Text/AppText.svelte";
  import Spacing from "../Spacing/Spacing.svelte";

  export let title = "";
  export let padding_right = "16";
  export let padding_left = "16";
  export let padding_top = "16";
  export let padding_bottom = "16";
  export let display = "block";
  export let background = "white";
  export let hideCursor = false;
  const dispatch = createEventDispatcher();
  const onClick = () => dispatch("click");

  const title_is_not_empty = title !== "" ? true : false;

  const component_style = `
    padding-right: ${padding_right}px;
    padding-left: ${padding_left}px;
    padding-top: ${padding_top}px;
    padding-bottom: ${padding_bottom}px;
    background: ${background};
    cursor: ${hideCursor ? "initial" : "pointer"};
  `;
</script>

<article style={component_style} class={display} on:click={onClick}>
  {#if title_is_not_empty}
    <div>
      <AppText size="medium" weight="900" color="blue">{title}</AppText>
      <Spacing y="12" />
    </div>
  {:else}
    <slot name="header" />
  {/if}
  <main>
    <slot />
  </main>
</article>

<style lang="scss">
  .inline-block{
    @include respond-to('sm'){
      display: inline-block;
    }
  }
  article {
    box-shadow: $bg-shadow;
    border-radius: $border-radius_normal;
  }
</style>
