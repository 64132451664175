<script>
  import Dialog, { Content } from "@smui/dialog";
  import AppLogin from "../components/Layout/AppLogin.svelte";
  import Input from "../components/Form/Input.svelte";
  import Spacing from "../components/Spacing/Spacing.svelte";
  import AppButton from "../components/Button/AppButton.svelte";
  import AppLink from "../components/Link/AppLink.svelte";
  import GoogleAuthentification from "../components/Dialog/GoogleAuthentification.svelte";
  import { REGISTRATION, EMAIL_RULE } from "../constants/index";
  import { navigate } from "svelte-routing";
  import { delay } from "../utils/functions/delay";
  import { profile, profile_default_path, login } from "../stores/index";
  import API from "../utils/api";
  import { i18n, i18nLang } from "../stores/i18n";
  import Appsnackbar from "../components/Snackbar/AppSnackbar.svelte";
  import AppText from "../components/Text/AppText.svelte";

  // let email = "thomas.deruel@firmfunding.com";
  // let password = "DNb0VNmG6r";
  // let email = "thomas.deruel@supdevinci-edu.fr";
  // let password = " ";
  let email = "";
  let password = "";
  let loading = false;
  let loadingDialog = false;
  let error = false;
  let errorDialog = false;
  let mfa = "";
  let count = 5;
  // let already_401 = null;
  let snack;
  let dialog;
  let snackForgottenPassword;
  let dialogForgottenPassword;
  let messageForgottenPassword = "";
  $: disabled = email === "" || password === "" || !EMAIL_RULE.test(email);
  $: error_message = $i18n("Erreur", "Error") + ` ( ${count} s.)`;
  $: disabledDialog = !EMAIL_RULE.test(email);

  const onForgottenPassword = async () => {
    try {
      if (loadingDialog) return;
      loadingDialog = true;
      messageForgottenPassword = "";
      errorDialog = false;
      if (snackForgottenPassword) {
        snackForgottenPassword.close();
      }
      const mine = new Function(
        await API.get("accountForgottenPassword", { email })
      )();
      await API.post("accountForgottenPassword", {
        email,
        ...mine(email),
        mfa,
      });
      messageForgottenPassword = $i18n(
        `Un nouveau mot de passe vous a été envoyé à l'adresse suivante: ${email}.`,
        `A new password has been sent to the following address: ${email}.`
      );
    } catch (e) {
      console.error(e);
      errorDialog = true;
      messageForgottenPassword = $i18n(
        "Une erreur est survenue. Veuillez recommencer.",
        "An error has occurred. Please try again."
      );
    } finally {
      loadingDialog = false;
      dialogForgottenPassword.close();
      snackForgottenPassword.forceOpen();
    }
  };
  const onLogin = async () => {
    if (loading || error) return;
    loading = true;
    try {
      login.set(false);
      localStorage.removeItem("token");
      $profile = {};
      //
      const mine = new Function(await API.get("login", { email }))();
      const response = await API.post("login", {
        email,
        password,
        ...mine(password),
        mfa,
      });
      if (!response.profile.type) {
        throw "Error: bad profile";
      }
      profile.set({ ...response.profile });
      $i18nLang = $profile.language;
      login.set(true);
      navigate($profile_default_path);
    } catch (e) {

      if (e.response.status === 401) {
        dialog.open();
        return;
      }
      snack.forceOpen();
      error = true;
      await delay(6000, (time_left) => (count = time_left));
      error = false;
    } finally {
      loading = false;
    }
  };
</script>

<aside style="width:600px;">
  <GoogleAuthentification
    bind:dialog
    on:validate={({ detail }) => {
      mfa = detail.pw;
      onLogin();
    }}
    on:cancell={() => {
      dialog.close();
      login.set(false);
      localStorage.removeItem("token");
      $profile = {};
    }}
  />
  <Dialog bind:this={dialogForgottenPassword}>
    <Content>
      <AppText color="blue" size="large" weight="900"
        >{$i18n("Changer de mot de passe", "Change password")}</AppText
      >
      <Spacing y="12" />
      <AppText
        >{$i18n(
          "Vous avez oublié votre mot de passe? Saisissez votre adresse mail ci-dessous.",
          "Forgot your password? Enter your email address below."
        )}
      </AppText>
      <Spacing y="8" />
      <Input
        uppercase
        rules={[EMAIL_RULE]}
        message_error={$i18n("Email invalide", "Invalid email")}
        label={$i18n("Email", "Email")}
        bind:value={email}
      />
      <AppButton
        loading={loadingDialog}
        on:click={onForgottenPassword}
        disabled={disabledDialog}
        >{$i18n("Modifier mon mot de passe", "Change my password")}</AppButton
      >
    </Content>
  </Dialog>
</aside>
<AppLogin>
  <Appsnackbar
    bind:value={snack}
    timeoutMs={6000}
    color="danger"
    labelText={$i18n(
      "L'adresse email ou le mot de passe est invalide, veuillez recommencer.",
      "The email address or password is invalid, please try again."
    )}
  />
  <Appsnackbar
    bind:value={snackForgottenPassword}
    timeoutMs={6000}
    color={errorDialog ? "danger" : "primary"}
    labelText={messageForgottenPassword}
  />
  <Input
    uppercase
    rules={[EMAIL_RULE]}
    message_error={$i18n("Email invalide", "Invalid email")}
    label={$i18n("Email", "Email")}
    required
    bind:value={email}
  />
  <Input
    uppercase
    crop
    label={$i18n("Mot de passe", "Password")}
    required
    type="password"
    bind:value={password}
  />
  <input name="mfa" bind:value={mfa} />
  <Spacing y="12" />
  <div>
    <AppButton
      bind:loading
      bind:disabled
      bind:error
      width="120px"
      on:click={onLogin}
      {error_message}>{$i18n("Se connecter", "Connect")}</AppButton
    >
    <AppLink on:click={dialogForgottenPassword.open()}>
      {$i18n("Mot de passe oublié ?", "Forgotten password ?")}
    </AppLink>
  </div>
  <Spacing y="12" />
  <AppButton color="secondary" block on:click={() => navigate(REGISTRATION)}
    >{$i18n(
      "Vous n'avez pas de compte? Inscrivez-vous",
      "Don't you have an account? Sign up here"
    )}</AppButton
  >
  <Spacing y="16" divider />
  <div>
    <AppLink
      type="secondary"
      on:click={() =>
        window.open(
          $i18n(
            "https://www.firmfunding.com/contactez-nous.html",
            "https://www.firmfunding.com/en/contact-us.html"
          ),
          "_blank"
        )}
    >
      {$i18n("Contactez-nous", "Contact-us")}
    </AppLink>
    <AppLink
      type="secondary"
      on:click={() =>
        window.open($i18n("/assets/terms-fr.pdf", "/assets/terms-en.pdf"))}
    >
      {$i18n("CGU", "TERMS")}
    </AppLink>
  </div>
  <Spacing y="12" />
</AppLogin>

<style lang="scss">
  div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  input {
    visibility: hidden;
    position: absolute;
    z-index: -1;
  }
</style>
