<script>
  import AppContent from "../components/Layout/AppContent.svelte";
  import AppImg from "../components/Img/AppImg.svelte";
  import AppText from "../components/Text/AppText.svelte";
  import Spacing from "../components/Spacing/Spacing.svelte";
  import AppDocument from "../components/Layout/AppDocument.svelte";
  import AppFlex from "../components/Layout/AppFlex.svelte";
  import Input from "../components/Form/Input.svelte";
  import AppButton from "../components/Button/AppButton.svelte";
  import AppCard from "../components/Card/AppCard.svelte";
  import AppRows from "../components/DataGrid/AppRows.svelte";
  import Dialog from "@smui/dialog";
  import Information from "../routes/RouteSubscriberCBTab/Information.svelte";
  import Documents from "../routes/RouteSubscriberCBTab/Documents.svelte";
  import Payments from "../routes/RouteSubscriberCBTab/Payments.svelte";
  import Log from "./RouteSubscriberCBTab/Log.svelte";
  // import AppBadge from "../components/Badge/AppBadge.svelte";
  import { INSTRUMENTS_LISTS, PERIODICITIES_LIST } from "../constants/index";
  import { i18n, formatNumber, parseNumber } from "../stores/i18n";
  import { onMount } from "svelte";
  import API from "../utils/api";
  import Tab, { Label } from "@smui/tab";
  import TabBar from "@smui/tab-bar";

  export let id;

  let loading = true;
  let amountLoading = false;
  let clubDeal = {};
  let documents = [];
  let subscriptionAmount = 0;
  let dialog;
  const defaultLogo = "/assets/default-logo.png";
  let src = defaultLogo;
  let tabs = [
    {
      value: "information",
      component: Information,
      label: ["Informations", "Informations"],
    },
    {
      value: "document",
      component: Documents,
      label: ["documents", "documents"],
    },
    // {
    //   value: "payment",
    //   component: Payments,
    //   label: ["paiements", "payments"],
    // },
    {
      component: Log,
      label: ["Journal", "Log"],
    },
  ];
  let activeTab = tabs[0];
  let activeDocumentPath = null;
  const gotoTab = (i, detail) => {
    if (i == 1){ // documents
      activeDocumentPath = detail;
    }
    activeTab = tabs[i];
  }
  let name;
  onMount(async () => {
    try {
      src = API.baseURL + "/logo?id=" + id;
      const clubDealPromise = API.get("clubdeal", { id });
      const documentPromise = API.get("document", {
        bondId: id,
      });
      clubDeal = await clubDealPromise;
      subscriptionAmount = clubDeal.subscriptionAmount;
      documents = (await documentPromise).documents;
      loading = false;
    } catch (e) {
      console.error(e);
    }
  });
  const onSubscribe = async () => {
    if (amountLoading) return;
    amountLoading = true;
    try {
      await API.put("clubdeal", {
        id,
        subscriptionAmount: subscriptionAmount,
      });
      clubDeal.subscriptionAmount = subscriptionAmount;
      dialog.close();
    } catch (e) {
      console.error(e);
    } finally {
      amountLoading = false;
    }
  };

  // const onDownload = async (doc) => {
  //   try {
  //     let freshDoc = await API.get("document", {
  //       bondId: clubDeal.id,
  //       id: doc.id,
  //     });
  //     let response = await fetch(freshDoc.url, { method: "GET" });
  //     // download file
  //     var newBlob = new Blob([await response.blob()], {
  //       type: freshDoc.contentType,
  //     });
  //     const mydata = window.URL.createObjectURL(newBlob);
  //     var link = document.createElement("a");
  //     link.href = mydata;
  //     link.download = freshDoc.fileName;
  //     link.click();
  //     setTimeout(() => {
  //       window.URL.revokeObjectURL(mydata);
  //     }, 100);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  let stateProps = { text: ["", ""], color: "" };
  $: {
    stateProps = {
      subscription: {
        text: ["En cours de souscription", "Under subscription"],
        color: "primary",
      },
      signature: {
        text: ["En cours de signature", "In the process of being signed"],
        color: "progress",
      },
      running: {
        text: [
          "En cours de remboursement",
          "In the process of being reimbursed",
        ],
        color: "progress",
      },
    }[clubDeal.state] || { text: ["Terminé", "Completed"], color: "disabled" };
  }

  $: disabled =
    isNaN($parseNumber(subscriptionAmount)) ||
    subscriptionAmount <= 0 ||
    subscriptionAmount == $parseNumber(clubDeal.subscriptionAmount) ||
    subscriptionAmount > clubDeal.amount;
  $: instrument = INSTRUMENTS_LISTS.find(
    (el) => el.value === clubDeal.instrument
  );
  $: periodicity = PERIODICITIES_LIST.find(
    (el) => el.value == clubDeal.periodicity
  );

  $: sa_gt_0 = clubDeal.subscriptionAmount || clubDeal.subscriptionAmount > 0;
  // const cell_style = `
  //   text-align: center;
  //   background: rgb(226, 226, 226);
  //   color: rgb(180, 180, 180);
  //   font-weight: 500;
  //   font-family: "Lato";
  //   font-weight: 700;
  // `;
</script>

<AppContent bind:loading>
  <Dialog bind:this={dialog} style="z-index:99999" class="cd-customdialog">
    <AppCard title={$i18n("Investir", "Invest")}>
      {#if clubDeal.subscriptionAmount}
        <p
          style="padding: 16px;display:flex;justify-content:space-between;color: #675AFF;background:#E6E3FF;border-radius:4px; font-family:Lato"
        >
          <span>{$formatNumber(clubDeal.subscriptionAmount)}</span>
          <span>€</span>
        </p>
        <Spacing y="16" divider />
      {/if}

      {#if clubDeal.state == "subscription"}
        <AppText color="blue"
          >{clubDeal.subscriptionAmount
            ? $i18n(
                "Modifier le montant de votre investissement",
                "Change the amount of your investment"
              )
            : $i18n(
                "Indiquez ici le montant de votre participation:",
                "Indicate here the amount of your participation:"
              )}</AppText
        >
        <Spacing y="16" />
        <Input
          type="number"
          crop
          label={$i18n(
            `Nouveau montant (max: ${$formatNumber(clubDeal.amount / 1e6)} M€)`,
            `New amount (max: ${$formatNumber(clubDeal.amount / 1e6)} M€`
          )}
          bind:value={subscriptionAmount}
          max={clubDeal.amount}
          min={0}
          suffix="€"
        />
        <Spacing y="16" />
        <AppButton
          bind:loading={amountLoading}
          on:click={onSubscribe}
          {disabled}
        >
          {$i18n("Confirmer", "Confirm")}
        </AppButton>
      {/if}
    </AppCard>
  </Dialog>
  <section class="header">
    <div>
      <AppImg
        {src}
        size="medium"
        onerror="this.onerror=null; this.src='{defaultLogo}'"
        style="min-width: 0%; max-width: 100%; height:auto"
      />
      <aside>
        <AppText size="xl" weight="bold" color="primary"
          >{clubDeal.name !== "" ? clubDeal.name : clubDeal.company.name}
        </AppText>
        <!-- <AppText color={sa_gt_0 ? "blue" : "gray"}>
          {$i18n("Mon investissement", "My investment")}:
          <b
            >{sa_gt_0
              ? $formatNumber(clubDeal.subscriptionAmount) + "€"
              : "Aucun"}
          </b>
        </AppText>
        <section style="position:absolute;bottom:0;right:0;">
          <AppButton color="primary" size="big" on:click={() => dialog.open()}>
            <AppText color="white" weight="600" uppercase>
              {$i18n("Investir", "Investing")}
            </AppText>
          </AppButton>
        </section> -->
      </aside>
    </div>
    <Spacing divider />
    <TabBar {tabs} let:tab bind:active={activeTab}>
      <Tab style="border-color:red;" {tab}>
        <Label>{$i18n(...tab.label)}</Label>
      </Tab>
    </TabBar>
    <Spacing divider />
  </section>
  <main>
    <svelte:component this={activeTab.component} {clubDeal} {documents} {gotoTab} {activeDocumentPath}/>
    <!-- <AppCard title={$i18n("Contacter le gestionnaire", "Contact the owner")}>
      <AppRows label={$i18n("Nom", "Name")}
        >{`${clubDeal.advisor.lastName} ${clubDeal.advisor.firstName}`}</AppRows
      >
      <AppRows label={$i18n("Société", "Company")}
        >{clubDeal.company.name}</AppRows
      >
      <AppRows label={$i18n("Email", "Email")}>{clubDeal.advisor.email}</AppRows
      >
      <AppRows label={$i18n("Téléphone", "Phone")} no_diviser
        >{clubDeal.advisor.phone}</AppRows
      >
    </AppCard>
    <Spacing y="12" />

    <AppCard
      title={$i18n("Caractéristiques de la dataroom", "Features of the dataroom")}
    >
      <AppRows label={$i18n("Montant total", "Total a mount")}
        >{$formatNumber(clubDeal.amount / 1e6)} M€</AppRows
      >
      {#if instrument && instrument.value && instrument.value != "equity"}
        <AppRows label={$i18n("Taux", "Rate")}
          >{$formatNumber(clubDeal.rate)}%</AppRows
        >
      {/if}
      <AppRows label={$i18n("Typologie de financement", "Typology of funding")}
        >{instrument?.label[0] || ""}</AppRows
      >
      {#if clubDeal.instrument == "bondEquityMix"}
        <AppRows label={$i18n("Montant equity", "Equity amount")}
          >{$formatNumber(clubDeal.equityAmount)}</AppRows
        >
      {/if}
      <AppRows label={$i18n("Durée", "Duration")}>{clubDeal.maturity}</AppRows>
      {#if instrument && instrument.value && instrument.value != "equity"}
        <AppRows
          label={$i18n("Fréquence des coupons", "Coupon frequency")}
          no_diviser>{periodicity?.name[0]}</AppRows
        >
      {/if}
    </AppCard>
    <Spacing y="12" />
    {#if documents.length}
      <AppCard
        title={$i18n(
          "Documents mis à disposition par le gestionnaire",
          "Documents made available by the manager"
        )}
        padding_bottom="0"
      >
        <AppFlex width_child="148px" margin_right="8">
          {#each documents as doc}
            <AppDocument
              title={doc.fileName}
              on:click={() => onDownload(doc)}
            />
          {/each}
        </AppFlex>
      </AppCard>
    {/if}
    <Spacing y="12" /> -->
    <!-- {#if clubDeal.state == "subscription" || clubDeal.subscriptionAmount}
      <AppCard title={$i18n("Mon investissement", "My investment")}>
        {#if clubDeal.subscriptionAmount}
          <p
            style="padding: 16px;display:flex;justify-content:space-between;color: #675AFF;background:#E6E3FF;border-radius:4px; font-family:Lato"
          >
            <span>{$formatNumber(clubDeal.subscriptionAmount)}</span>
            <span>€</span>
          </p>
          <Spacing y="16" divider />
        {/if}

        {#if clubDeal.state == "subscription"}
          <AppText color="blue"
            >{clubDeal.subscriptionAmount
              ? $i18n(
                  "Modifier le montant de votre investissement",
                  "Change the amount of your investment"
                )
              : $i18n(
                  "Indiquez ici le montant de votre participation:",
                  "Indicate here the amount of your participation:"
                )}</AppText
          >
          <Spacing y="16" />
          <Input
            type="number"
            crop
            label={$i18n(
              `Nouveau montant (max: ${$formatNumber(
                clubDeal.amount / 1e6
              )} M€)`,
              `New amount (max: ${$formatNumber(clubDeal.amount / 1e6)} M€`
            )}
            bind:value={subscriptionAmount}
            max={clubDeal.amount}
            min={0}
            suffix="€"
          />
          <Spacing y="16" />
          <AppButton
            bind:loading={amountLoading}
            on:click={onSubscribe}
            {disabled}
          >
            {$i18n("Confirmer", "Confirm")}
          </AppButton>
        {/if}
      </AppCard>
    {/if}
    <Spacing y="12" /> -->
  </main>
</AppContent>

<style lang="scss">
  // .favcolor {
  //     color:$warning;
  // }
  :global(.mdc-dialog.cd-customdialog) {
    :global(.mdc-dialog__surface) {
      width: 90%;
      /* margin: 0; */
      max-width: 480px;
    }
    :global(.mdc-dialog__container) {
      width: 100%;
    }
  }
  .header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background: white;
    width: 70%;
    padding: 88px 15% 0 15%;
    z-index: 2;
    div {
      position: relative;
      margin-bottom: 16px;
    }
  }
  main {
    margin: 0 24px;
    padding-top: 164px;
    @include respond-to("sm") {
      margin: 0 auto;
      width: 94%;
    }
    @include respond-to("sm") {
      width: 85%;
    }
    @include respond-to("md") {
      width: 70%;
    }
    @include respond-to("xl") {
      width: 50%;
    }

    section {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      div {
        display: flex;
        height: auto;
        aside {
          margin-left: $spacing_large;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          height: auto;
        }
      }
    }
  }
</style>
