<script>
  import { fade } from "svelte/transition";
  export let loading = false;
</script>

<div>
  <main style="opacity: {loading? 0.5: 1.0}">
    <header>
      <slot name="left" />
    </header>
    <section>
      <slot />
    </section>
  </main>
  {#if loading}
    <figure transition:fade|local>
      <img src="/assets/tail-spin.svg" />
    </figure>
  {/if}
</div>

<style lang="scss">
  figure {
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 72px;
    }
  }
  div {
    margin-top: 64px;
    min-height: calc(100vh - 80px);
    display: block;
    width: 100%;
    main {
      display: flex;
      width: 100%;
      min-height: 100%;
      header {
        width: 272px;
        min-height: 100%;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
        @include respond-to("md") {
          display: flex;
          position: fixed;
          z-index:99;
          align-items: center;
          justify-content: center;
        }
      }
      section {
        padding-top: 16px;
        display: flex;
        width: calc(100% - 32px);
        margin: 16px;
        @include respond-to("md") {
          width: calc(100% - 272px);
          margin-left: 272px;
          /* overflow: auto; */
        }
        height: calc(100vh - 80px);
        min-height: 300px;
      }
    }
  }
</style>
