<script>
  import AppText from "../../components/Text/AppText.svelte";
  import AppAlert from "../../components/Alert/AppAlert.svelte";
  import AppTooltip from "../../components/Text/AppTooltip.svelte";
  import AppSnackbar from "../../components/Snackbar/AppSnackbar.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
  import AppButton from "../../components/Button/AppButton.svelte";
  import { i18n } from "../../stores/i18n";
  import Dialog, { Content } from "@smui/dialog";
  import Select from "svelte-select";
  import Input from "../../components/Form/Input.svelte";
  import API from "../../utils/api";
  export let disabled;
  export let data;
  
  let sliderDialog;
  let snackbar;
  let snack_msg = "";
  let sliderDialogConfirmation;
  let loading = false;
  let firstName = "";
  let lastName = "";
  let company = "";
  let email = "";
  const LANGUAGE_LIST = [
    {
        value: "fr",
        label: ["Français", "French"]
    },
    {
        value: "en",
        label: ["Anglais", "English"]
    }
  ];

  const itemFilter = (label, textFilter) => {
    // NFD décomposes any characteres and accents into unicode: if i had an accent "é"
    // it will decompose into 2 unicodes : U+0065 -> e and U+0300 ->  ́
    // and we remove any accents from u0300 to u036f (all accents,see:https://fr.wikipedia.org/wiki/Table_des_caract%C3%A8res_Unicode/U0300)
    return label
      .normalize("NFD")
      .toLowerCase()
      .replace(/[\u0300-\u036f]/g, "")
      .includes(textFilter.trim().toLowerCase());
  };

  let language = LANGUAGE_LIST[0];
  $: selectedLanguage = language ? { value: language.value, label: $i18n(...language.label) } : null;
  function onSelectLanguage(event) {
    language = LANGUAGE_LIST.find(e => e.value == event.detail.value)
  }

  let snack_color = "primary";
  const email_rule = /[\w\._-]+@[\w-]+[.][a-z]+/;
  $: clubDealInvestors = data.clubDealInvestors.map(el=>{return{...el,loading:false}})
  const clubDealInvestorsContains = (email) => {
    return data.clubDealInvestors
      ? data.clubDealInvestors.map((i) => i.email).includes(email)
      : false;
  };
  const format = (date, lang = "fr-FR") => {
    return new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };
  const hasSubscribed = (subscriber) => {
    return data.subscriptions
      ? data.subscriptions.find(
          (sub) =>
            sub.subscriberFirstName == subscriber.firstName &&
            sub.subscriberLastName == subscriber.lastName
        )
      : false;
  };
  $: isdisabled = !email_rule.test(email) || clubDealInvestorsContains(email);

  const addInvestor = async () => {
    if (!disabled || loading) return;
    sliderDialog.close();
    sliderDialogConfirmation.close();
    try {
      loading = true;
      const response = await API.put("clubdeal", {
        id: data.id,
        clubDealInvestors: [
          ...data.clubDealInvestors,
          { firstName, lastName, company, email, language: language.value },
        ],
      });
      data.clubDealInvestors = [...response.clubDealInvestors];
      firstName = lastName = company = email = "";
      language = LANGUAGE_LIST[0];
    } catch (e) {
      console.error(e);
    } finally {
      loading = false;
    }
  };
  const onClubdealAdvisorCallBack = async (index,subscriberId) => {
    if (clubDealInvestors[index].loading) return;
    clubDealInvestors[index].loading = true;
    try {
      await API.post("clubdealAdvisorCallBack", {
        bondId: data.id,
        subscriberId
      });
      const res = clubDealInvestors[index].firstName +" "+ clubDealInvestors[index].lastName
      snack_color = "primary"
      snack_msg = $i18n(`Relance effectuée pour le participant ${res}`,`Dunning done for the participant ${res}`)
    } catch (e) {
      console.error(e);
      snack_color = "danger"
      snack_msg = $i18n(`Une erreur est survenue`,`An error has occurred`)
    } finally {
      snackbar.forceOpen();
      clubDealInvestors[index].loading = false;
    }
  };
  const removeInvestor = async (investorEmail) => {
    if (!disabled || loading) return;
    try {
      loading = true;
      let investors = data.clubDealInvestors.filter(
        (i) => i.email != investorEmail
      );
      await API.put("clubdeal", {
        id: data.id,
        clubDealInvestors: [...investors],
      });
      data.clubDealInvestors = [...investors];
    } catch (e) {
      console.error(e);
    } finally {
      loading = false;
    }
  };

  const cell_style = `
  text-align:center;
  background: rgb(226, 226, 226);
  color: rgb(180, 180, 180);
  font-weight: 500;
  font-family: "Lato";
  font-weight: 700;
  `;
</script>

<AppSnackbar bind:color={snack_color} bind:value={snackbar} labelText={snack_msg}/>

<Spacing y="8" />
<AppAlert material_icon="error_outline" color="progress">
  {$i18n(
    "Dans cette section, vous pouvez renseigner tous les « participants » : ceux qui doivent avoir accès à un ou plusieurs document(s).",
    "In this section, you can fill in all « participants »: those who need access to one or more documents."
  )}
</AppAlert>
<Spacing y="24">
  <Dialog
    style="z-index:1999; width: 100%"
    bind:this={sliderDialogConfirmation}
  >
    <Content>
      <AppText color="blue" weight="900" size="large"
        >{$i18n("Confirmation", "Confirmation")}</AppText
      >
      <Spacing y="8" />
      <AppText
        >{$i18n(
          "Êtes-vous sûr de vouloir ajouter ce participant ?",
          "Are you sure you want to add this participant?"
        )}</AppText
      >
      <Spacing y="8" />
      <AppText>{$i18n("Nom", "Name")}: {firstName} {lastName}</AppText>
      <Spacing y="8" />
      <AppText>{$i18n("Société", "Company")}: {company}</AppText>
      <Spacing y="8" />
      <AppText>{$i18n("Email", "Email")}: {email}</AppText>
      <Spacing y="8" />
      <AppText>{$i18n("Langue préférée", "Prefered language")}: {selectedLanguage.label}</AppText>
      <Spacing y="8" />
      <AppButton block on:click={addInvestor}
        >{$i18n("Ajouter", "Add")}</AppButton
      >
    </Content>
  </Dialog>

  <Dialog style="z-index:999;width: 100%" bind:this={sliderDialog}>
    <Content>
      <AppText color="blue" size="large" weight="900">
        {$i18n("Ajouter un nouveau participant", "Add a new participant")}
      </AppText>
      <Spacing y="12" />
      <div>
        <Input
          label={$i18n("Prénom", "First name")}
          bind:value={firstName}
          crop
        />
        <Input label={$i18n("Nom", "Last name")} bind:value={lastName} crop />
      </div>
      <Spacing y="16" />
      <Input label={$i18n("Société", "Company")} bind:value={company} />
      <Input label="Email" bind:value={email} />
      <!-- <Input label={$i18n("Langue préférée", "Prefered language")} bind:value={language} /> -->

      <AppText weight="700">{$i18n("Langue préférée:", "Prefered language:")}</AppText>
      <Spacing y="4" />
      <Select
        items={LANGUAGE_LIST.map(l => {return {value: l.value, label: $i18n(...l.label)}})}
        on:select={onSelectLanguage}
        {itemFilter}
        isMulti={false}
        noOptionsMessage={$i18n("Langue préférée", "Prefered language")}
        selectedValue={selectedLanguage}
        />
        <!-- selectedValue={language == "en"? $i18n("Anglais", "English"): $i18n("Français", "French")} -->
      <Spacing y="16" />

      <AppButton
        disabled={isdisabled}
        block
        on:click={() => sliderDialogConfirmation.open()}
        >{$i18n("Ajouter", "Add")}</AppButton
      >
    </Content>
  </Dialog>
  <DataTable class="table" style="width: 100%">
    <Head>
      <Row>
        <Cell>
          <AppText weight="700">{$i18n("Prénom", "First name")}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n("Nom", "Last name")}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n("Société", "Company")}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n("Email", "Email")}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700"
            >{$i18n("Date d'inscription", "Date of registration")}</AppText
          >
        </Cell>
        {#if data.state == "notSubmitted" || data.state == "subscription"}
          <Cell>
            <AppText weight="700" center="true"
              >{$i18n("Suppression", "Deletion")}</AppText
            >
          </Cell>
        {/if}
      </Row>
    </Head>

    <Body>
      {#if clubDealInvestors.length}
        {#each clubDealInvestors as investor,index}
          <Row>
            <Cell>{investor.firstName ? investor.firstName : ""}</Cell>
            <Cell>{investor.lastName ? investor.lastName : ""}</Cell>
            <Cell>{investor.company ? investor.company : ""}</Cell>
            <Cell>{investor.email}</Cell>
            <!-- TODO -->
            {#if investor.termsSigned}
              <Cell
                >{$i18n(
                  format(investor.termsSigned),
                  format(investor.termsSigned, "EN-en")
                )}</Cell
              >
            {:else}
              <Cell>
                <AppButton
                  on:click={()=>onClubdealAdvisorCallBack(index,investor.id)}
                  color="light"
                  size="small"
                  bind:loading={investor.loading}
                >
                  Relancer le participant
                </AppButton>
              </Cell>
            {/if}
            {#if data.state == "notSubmitted" || data.state == "subscription"}
              <Cell style="text-align: center">
                {#if hasSubscribed(investor)}
                  <button
                    class="material-icons"
                    style="border: 0; background-color: white; color: #f02f22; "
                    disabled="true">delete</button
                  >
                {:else}
                  <button
                    class="material-icons"
                    style="border: 0; background-color: white; color: #f02f22; cursor: pointer;"
                    on:click={() => {
                      removeInvestor(investor.email);
                    }}>delete</button
                  >
                {/if}
              </Cell>
            {/if}
          </Row>
        {/each}
      {:else}
        <Row>
          <Cell style={cell_style} colspan="5">
            <p>{$i18n("Aucun participant ajouté", "No participant added")}</p>
          </Cell>
        </Row>
      {/if}
    </Body>
  </DataTable>
  <Spacing y="12" />
  {#if data.state == "notSubmitted" || data.state == "subscription"}
    <AppButton
      {loading}
      icon
      size="big"
      block
      on:click={() => sliderDialog.open()}
    >
      person_add
      <span slot="text"
        ><AppText color="white">
          {$i18n("Ajouter un nouveau participant", "Add a new participant")}
        </AppText></span
      >
    </AppButton>
  {/if}
</Spacing>

<style lang="scss">
  div {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
</style>
