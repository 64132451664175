<script>
  import Spacing from "../components/Spacing/Spacing.svelte";
  import AppText from "../components/Text/AppText.svelte";
  import AppButton from "../components/Button/AppButton.svelte";
  import ValidationLayout from "../components/Layout/ValidationLayout.svelte";
  import API from "../utils/api";
  import { navigate } from "svelte-routing";
  import { fade, fly } from "svelte/transition";
  import { LOGIN_PATH } from "../constants";
  import { i18n } from "../stores/i18n";

  export let id;

  let loading = false;
  let error = false;
  let done = false;

  const ok = async () => {
    let errTime;
    if (loading) return;
    loading = true;
    if (error) {
      clearTimeout(errTime);
      error = false;
    }
    try {
      await API.post("accountUnsubscription", { data: id });
      done = true;
    } catch (e) {
      error = true;
      errTime = setTimeout(function () {
        if (error) error = false;
      }, 4000);
    } finally {
      loading = false;
    }
  };
</script>

<ValidationLayout title={$i18n("Désinscription", "Unsubscription")}>
  {#if !done}
    <section transition:fly={{ y: 50, duration: 500 }}>
      <AppText center>
        {$i18n(
          "Êtes-vous sûr(e) de ne plus souhaiter recevoir d'emails de la plateforme FirmClosing ?",
          "Are you sure you do not want to receive any more emails from the FirmClosing platform?"
        )}
      </AppText>
      <Spacing y="24" />

      <div>
        <AppButton width="120px" bind:loading on:click={ok}
          >{$i18n("Oui", "Yes")}</AppButton
        >
        <AppButton
          color="light"
          width="120px"
          on:click={() => navigate(LOGIN_PATH, { replace: true })}
          >{$i18n("Non", "No")}</AppButton
        >
      </div>
      {#if error}
        <section transition:fly={{ y: 30, duration: 500 }}>
          <Spacing y="24" />
          <AppText center weight="700" color="danger"
            >{$i18n(
              "Une erreur est survenue, veuillez recommencer.",
              "An error has occurred, please try again."
            )}</AppText
          >
        </section>
      {/if}
    </section>
  {:else}
    <section transition:fly={{ y: 50, duration: 300, delay: 500 }}>
      <AppText center
        ><b
          >{$i18n(
            "Votre désinscription a été pris en compte",
            "Your unsubscription has been taken into account"
          )}</b
        >,
        {$i18n(
          "vous pouvez à présent retouner à la page de connexion.",
          "you can now go back to the login page."
        )}
      </AppText>
      <img src="/assets/tick.svg" alt="ok" />
    </section>
  {/if}
  {#if done}
    <main transition:fade={{ y: 50, duration: 300, delay: 700 }}>
      <AppButton
        block
        icon
        size="big"
        on:click={() => navigate(LOGIN_PATH, { replace: true })}
      >
        arrow_back
        <span slot="text">
          <AppText color="white"
            >{$i18n(
              "Retourner à la page de connexion",
              "Back to the login page"
            )}</AppText
          >
        </span>
      </AppButton>
    </main>
  {/if}
</ValidationLayout>

<style lang="scss">
  div {
    display: flex;
    justify-content: space-between;
  }
  main {
    width: 100%;
    position: relative;
    transition: all 200ms ease;
    margin: 0 auto;
    @include respond-to("sm") {
      width: calc(720px - 64px);
      position: absolute;
      bottom: 32px;
      left: 0;
      right: 0;
    }
    @include respond-to("md") {
      bottom: 24px;
    }
  }
  img {
    padding-top: 16px;
    margin: 0 auto;
    text-align: center;
    display: block;
    width: 48px;
    transition: all 200ms ease;
    padding-bottom: 24px;
    @include respond-to("sm") {
      width: 64px;
      padding-top: 24px;
    }
    @include respond-to("md") {
      width: 72px;
    }
  }
</style>
