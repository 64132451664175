import { writable } from "svelte/store"
// add session or cookie here
function createEmail(){
    const { subscribe, set } = writable("")
    return {
        subscribe,
        set: (email) => {set(email)}
    }
}

export const storeEmail = createEmail()