<script>
  import { onMount } from "svelte";
  import AppCard from "./AppCard.svelte";
  import AppBadge from "../Badge/AppBadge.svelte";
  import AppImg from "../Img/AppImg.svelte";
  import AppText from "../Text/AppText.svelte";
  import { createEventDispatcher } from "svelte";
  import { INSTRUMENTS_LISTS } from "../../constants";
  import { i18n, formatNumber } from "../../stores/i18n";
  import API from "../../utils/api";

  export let id;
  export let advisor;
  export let rate = 0;
  export let amount = 0;
  export let subscriptionAmount;
  export let nbSubscribers;
  export let duration = 0;
  export let hideCursor = false;
  export let companyName = "";
  export let instrument;
  export let state;

  const dispatch = createEventDispatcher();
  const onClick = () => dispatch("click");

  const stateProps = {
    subscription: {
      text: ["En cours de souscription", "Under subscription"],
      color: "primary",
    },
    signature: {
      text: ["En cours de signature", "In the process of being signed"],
      color: "progress",
    },
    running: {
      text: ["En cours de remboursement", "In the process of being reimbursed"],
      color: "progress",
    },
  }[state] || { text: ["Terminé", "Completed"], color: "disabled" };

  const style = {
    padding_right: "10",
    padding_left: "10",
    padding_top: "9",
    padding_bottom: "9",
  };

  const defaultLogo = "/assets/default-logo.png";
  let src = defaultLogo;
  onMount(() => {
    src = API.baseURL + "logo?id=" + id;
  });
</script>

<AppCard on:click={onClick} {hideCursor}>
  <!-- <header>
    <AppBadge color={stateProps.color}>{$i18n(...stateProps.text)}</AppBadge>
  </header> -->

  <section class="border">
    <AppImg
      size="small"
      {src}
      onerror="this.onerror=null; this.src='{defaultLogo}'"
    />
    <article>
      <AppText size="large" weight="700" style="min-height: 100%"
        >{companyName}</AppText
      >
      {#if instrument}
        <AppText size="small" weight="700" color="gray">
          {$i18n(
            ...(
              INSTRUMENTS_LISTS.find((el) => el.value === instrument) || {
                label: ["inconnu", "unknown"],
              }
            ).label
          )}
        </AppText>
      {/if}
    </article>
  </section>
  <!-- <div class="mb_8 grid"> -->
  <div style="margin-bottom:4px;">
    <AppText size="small" weight="700">
      {$i18n("Proposé par:", "Proposed by:")}
    </AppText>

    <AppText weight="900">
      <p>{advisor}</p>
    </AppText>
  </div>
  <div class="grid">
    {#if amount}
      <AppBadge color="gradient">
        <div>
          <AppText color="white" weight="700">
            {$i18n("Montant", "Amount")}
          </AppText>
          <AppText color="white" weight="700">
            <p>
              {$formatNumber(amount / 1e6)} M€
            </p>
          </AppText>
        </div>
      </AppBadge>
    {/if}
    <!-- {#if subscriptionAmount !== 0}
      <AppBadge>
        <div>
          <AppText size="small" weight="700" color="blue">
            {$i18n("Souscription", "Subscription")}
          </AppText>

          <AppText weight="900" color="blue">
            <p>
              {$formatNumber(subscriptionAmount / 1e6)} M€
            </p>
          </AppText>
        </div>
      </AppBadge>
    {/if} -->
  </div>
  {#if rate}
    <AppCard {...style} display="inline-block">
      <div class="badge-1">
        <AppText size="small" weight="700">{$i18n("Taux", "Rate")}</AppText>
        <AppText weight="700" color="danger">{$formatNumber(rate)}%</AppText>
      </div>
    </AppCard>
    <b />
  {/if}
  {#if duration}
    <AppCard {...style} display="inline-block">
      <div class="badge-2">
        <AppText size="small" weight="700">{$i18n("Durée", "Duration")}</AppText
        >
        <AppText weight="700" color="progress"
          >{duration} {$i18n("mois", "months")}</AppText
        >
      </div>
    </AppCard>
    <b />
  {/if}
  {#if nbSubscribers}
    <AppCard {...style} display="inline-block">
      <div class="badge-2">
        <AppText size="small" weight="700"
          >{$i18n("Participants", "Participants")}</AppText
        >
        <AppText weight="700" color="progress">{nbSubscribers}</AppText>
      </div>
    </AppCard>
    <b />
  {/if}
  <!-- {#if subscriptionAmount !== 0}
    <div>
      <AppText size="small" weight="700" color="blue">
        {$i18n("Mon investissement:", "My investment:")}
      </AppText>

      <AppText weight="900" color="blue">
        <p>
          {subscriptionAmount == 0
            ? $i18n("aucun", "none")
            : $formatNumber(subscriptionAmount / 1e6) + "M€"}
        </p>
      </AppText>
    </div>
  {/if} -->
</AppCard>

<style lang="scss">
  header {
    margin-bottom: 12px;
  }
  section {
    &.border {
      border-bottom: 1px solid $gray;
      padding-bottom: 8px;
    }
    display: flex;
    /* height: 112px; */
    margin-bottom: 12px;
    article {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 40px;
      /* div.compagnyheader {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        } */
      /* span {
          display: block;
          div {
            display: flex;
            justify-content: space-between;
            p {
              display: flex;
              align-items: center;
              i {
                font-size: 14px;
                line-height: 1.5;
              }
            }
          }
        } */
    }
  }
  b {
    margin-left: 4px;
  }
  div {
    &.mb_8 {
      margin-bottom: 12px;
    }
    &.grid {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: repeat(1, 1fr);
      @include respond-to("sm") {
        grid-gap: 16px;
        grid-template-columns: repeat(2, 1fr);
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      display: flex;
      align-items: center;
      i {
        font-size: 14px;
      }
    }
    &.badge {
      &-1 {
        width: 100%;
        @include respond-to("sm") {
          width: 64px;
        }
      }
      &-2 {
        width: 100%;
        @include respond-to("sm") {
          width: 110px;
        }
      }
      &-3 {
        width: 100%;
        @include respond-to("sm") {
          width: 114px;
        }
        span {
          margin-top: 1.5px;
        }
      }
    }
  }
</style>
