<script>
  import { storeEmail } from "../stores/email";
  import Spacing from "../components/Spacing/Spacing.svelte";
  import AppText from "../components/Text/AppText.svelte";
  import ValidationLayout from "../components/Layout/ValidationLayout.svelte";
  import { i18n } from "../stores/i18n";
</script>

<ValidationLayout
  src="/assets/mail-send.svg"
  title={$i18n("Merci.", "Thank you.")}
>
  <AppText center>
    <b>{$i18n("Suite à votre inscription", "Following your registration")}</b>,
    {$i18n(
      "un mail de confirmation vous a été envoyé à l'adresse suivante:",
      "a confirmation email has been sent to the following address:"
    )}
  </AppText>
  <Spacing y="8" />
  <AppText center size="large" weight="900">{$storeEmail}</AppText>
</ValidationLayout>
