<script>
  export let title = "";
  export let icon;
  import List from "@smui/list";
  import Button from "@smui/button";
  import Menu from "@smui/menu";
  let menu;
</script>

<div>
  <Button on:click={() => menu.setOpen(true)}>
    {title}
    {#if icon}
      <i class="material-icons"> {icon} </i>
    {/if}
    <i class="material-icons"> arrow_drop_down </i>
  </Button>

  <Menu bind:this={menu}>
    <List class="test">
      <slot />
    </List>
  </Menu>
</div>
