<script>
  import { i18n } from "../../stores/i18n";

  export let editable = false;
  export let steppers, flat_steppers, stepper, stepper_index;

  function onClick() {
    if (editable) {
      step.setter(Number(index));
    }
  }

  let index = flat_steppers.findIndex(
    (s) =>
      s.value == (stepper.children ? stepper.children[0].value : stepper.value)
  );
  let display_index = steppers.findIndex((s) => s.value == stepper.value) + 1;

  let children_indexes = stepper.children
    ? stepper.children.map((c) =>
        flat_steppers.findIndex((s) => s.value == c.value)
      )
    : [];
</script>

<style lang="scss">
  main {
    height: auto;
    font-family: "Lato";
    padding-bottom: 16px;
    transition: all 100ms ease;
    &.activechildren {
      padding-bottom: 4px;
      article {
        max-height: 100px;
        transition: all 500ms ease-in-out;
      }
    }
    &:last-child {
      padding-bottom: 0;
    }
    article {
      max-height: 0;
      display: block;
      max-width: calc(100% - 36px);
      margin-left: 36px;
      overflow-y: hidden;
      background: $white;
      font-size: 12px;
      margin-top: 0;
      transition: all 300ms ease-in;
      font-weight: 500;
      overflow: hidden;
      p {
        padding: 6px;
        // height: 7px;
        transition: all 200ms ease;
        color: $gray;
        &.normal {
          color: $color_primary;
        }
        &.active {
          color: $blue;
        }
      }
    }

    div {
      display: flex;
      padding: 16px;
      border-radius: 3px;
      padding: 6px;
      width: 140px;
      justify-content: space-between;
      align-items: center;
      background-color: $white;

      cursor: default;
      &.editable {
        cursor: pointer;
      }
      section {
        transition: all 200ms ease;
        background: $blue;
        color: white;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        display: flex;
        font-size: 11px;
        align-items: center;
        justify-content: center;
        font-variant-numeric: proportional-nums;
        font-weight: 900;
        &::selection {
          background: none;
        }
      }
      &.disabled {
        section {
          background: $gray;
        }
      }
      p {
        flex-grow: 1;
        margin-left: 8px;
        font-size: 12px;
        font-weight: 700;
        color: $color_secondary;
        &::selection {
          background: none;
        }
      }
      &.disabled {
        p {
          color: $gray;
        }
      }
    }
  }
</style>

<main class:activechildren={children_indexes.includes(stepper_index)}>
  <div class:disabled={stepper_index < index} class:editable on:click={onClick}>
    <section>{display_index}</section>
    <p>{$i18n(... stepper.text)}</p>
  </div>
  {#if stepper.children}
    <article>
      {#each stepper.children as child, i}
        <!-- 
        on:click={/*()=>children_click(i,click)*/}
       -->
        <p
          class:normal={stepper_index > flat_steppers.findIndex((s) => s.value == child.value)}
          class:active={stepper_index == flat_steppers.findIndex((s) => s.value == child.value)}>
          {i + 1}.
          {$i18n(... child.text)} 
        </p>
      {/each}
    </article>
  {/if}
</main>
