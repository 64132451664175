<script>
  import AppContent from "../../components/Layout/AppContent.svelte";
  import AppText from "../../components/Text/AppText.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
  import { onMount, onDestroy } from "svelte";
  import API from "../../utils/api";
  import { i18n } from "../../stores/i18n";
  import Input from "../../components/Form/Input.svelte";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import AppButton from "../../components/Button/AppButton.svelte";
  import { clubdeal } from "../../stores/clubdeal";
  import { autoUpdate } from "../../utils/functions/autoUpdate";
  import AppSnackbar from "../../components/Snackbar/AppSnackbar.svelte";
  import {
    REGION_LIST,
    COUNTRY_LIST,
    INSTRUMENTS_LISTS,
    PERIODICITIES_LIST,
    TYPOLOGIES_LISTS
  } from "../../constants";

  export let disabled;
  export let data;

  let snackSaved;
  autoUpdate(clubdeal, onMount, onDestroy, (state) => {
    snackSaved.forceOpen();
  });
  let options = [
    {
      name: "SA",
    },
    {
      name: "SAS",
    },
    {
      name: "SCA",
    },
    {
      name: "SARL",
    },
  ];

  let selected3 = data.periodicity || "monthly";

  let companyHeadquarters =
    REGION_LIST.find((el) => el.value == data.company.headquartersRegion) ||
    COUNTRY_LIST.find((el) => el.value == data.company.headquartersCountry);
  companyHeadquarters = companyHeadquarters ? companyHeadquarters.label : [];

  let instrumentValue = INSTRUMENTS_LISTS.find(
    (el) => el.value == data.instrument
  );
  instrumentValue = instrumentValue ? instrumentValue.value : "";

  let typologyValue = TYPOLOGIES_LISTS.find(
    (el) => el.value == data.typology
  );
  typologyValue = typologyValue ? typologyValue.value : "";

  const cell_style = `
  text-align:center;
  background: rgb(226, 226, 226);
  color: rgb(180, 180, 180);
  font-weight: 500;
  font-family: "Lato";
  font-weight: 700;
  `;
</script>

<Spacing y="16" />
<AppContent type="advisor">
  <section>
    <AppText color="blue" weight="900" size="large"
      >{$i18n("Société", "Company")}</AppText
    >
    <Spacing y="8" />
    <main>
      <Spacing divider block />
    </main>
  </section>
  <div>
    <!-- <aside> -->
      <Input
        label={$i18n("Raison sociale", "Company name")}
        bind:value={$clubdeal.company.name}
        crop
        disabled
      />
      <!-- <Input
        label={$i18n("Siren", "Siren")}
        bind:value={$clubdeal.company.siret}
        crop
        {disabled}
      /> -->
    <!-- </aside> -->
    <Spacing y="12" />
    <AppText weight="700">{$i18n("Forme juridique", "Legal form")}:</AppText>
    <Spacing y="4" />
    {#each options as option}
      <FormField>
        <Radio
          color="primary"
          bind:group={$clubdeal.company.legalForm}
          value={option.name}
          disabled
          />
        <span style="margin-right:4px;" slot="label">{option.name}</span>
      </FormField>
    {/each}
    <Spacing y="12" />
    <Input
      label={$i18n("Lieu du siège social", "Location of the head office")}
      value={$i18n(companyHeadquarters)}
      crop
      {disabled}
    />
  </div>
  <section>
    <AppText weight="900" color="blue" size="large">
      {$i18n(
        "Caractéristiques de l'opération",
        "Characteristics of the operation"
      )}
    </AppText>
    <Spacing y="8" />
    <main>
      <Spacing divider block />
    </main>
  </section>
  <div>
    <aside>
      <Input
        label={$i18n("Montant", "Amount")}
        value={data.amount / 1e6}
        crop
        suffix="M€"
        on:change={(customEvent) => {
          $clubdeal.amount = customEvent.detail.target.value * 1e6
        }}
      />
      <Input
        label={$i18n("Taux", "Rate")}
        value={data.rate}
        crop
        suffix="%"
        on:change={(customEvent) => {
          $clubdeal.rate = customEvent.detail.target.value
        }}
      />
    </aside>
    <Spacing y="12" />
    <Input
      label={$i18n("Durée", "Duration")}
      bind:value={$clubdeal.maturity}
      crop
      suffix={$i18n("mois", "months")}
    />
    <Spacing y="12" />
    <AppText weight="700"
      >{$i18n("Typologie de l'opération", "Typology of the operation")} :</AppText
    >
    <Spacing y="4" />
    {#each TYPOLOGIES_LISTS as typology}
      <FormField>
        <Radio
          bind:group={$clubdeal.typology}
          value={typology.value}
        />
        <span slot="label">{$i18n(...typology.label)}</span>
      </FormField>
    {/each}

    <!-- <Spacing y="12" />
    <AppText weight="700"
      >{$i18n("Typologie de financement", "Type of financing")} :</AppText
    >
    <Spacing y="4" />
    {#each INSTRUMENTS_LISTS as instrument}
      <FormField>
        <Radio
          color="primary"
          bind:group={instrumentValue}
          value={instrument.value}
          {disabled}
        />
        <span style="margin-right:4px;" slot="label"
          >{$i18n(...instrument.label)}</span
        >
      </FormField>
    {/each}
    <Spacing y="12" />
    <AppText weight="700"
      >{$i18n(
        "Fréquence des coupons (si applicable)",
        "Coupon frequency (if applicable)"
      )}:</AppText
    >
    <Spacing y="4" />
    {#each PERIODICITIES_LIST as frequency}
      <FormField>
        <Radio
          {disabled}
          color="primary"
          bind:group={selected3}
          value={frequency.value}
        />
        <span style="margin-right:4px;" slot="label"
          >{$i18n(...frequency.name)}</span
        >
      </FormField>
    {/each} -->
  </div>
  <AppSnackbar
    bind:value={snackSaved}
    labelText={$i18n(
      "Vos informations ont bien été enregistrées",
      "Your information has been saved"
    )}
  />
</AppContent>

<style lang="scss">
  section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    main {
      flex: 1 1 auto;
      margin-left: 8px;
      margin-top: 3px;
    }
  }
  div {
    padding: 24px;
    margin: 12px 0;
  }
  aside {
    display: grid;
    gap: 16px;
    /* grid-template-columns: repeat(1, 1fr); */
    @include respond-to("sm") {
      grid-template-columns: repeat(2, 1fr);
    }
  }
</style>
