<script>
  import AppContent from "../components/Layout/AppContent.svelte";
  import Spacing from "../components/Spacing/Spacing.svelte";
  import AdvsisorColumn from "../components/AdvisorColumn/AdvisorColumn.svelte";
  import AdvisorCard from "../components/AdvisorColumn/AdvisorCard.svelte";
  import AppButton from "../components/Button/AppButton.svelte";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import { id } from "../stores/clubdeal";
  import API from "../utils/api";
  import { i18n } from "../stores/i18n";
  import { profile } from "../stores/profile";
  let loading = true;
  let clubdeals = [];
  onMount(async () => {
    try {
      const res = await API.get("clubdeal");
      clubdeals = res.clubDeals;

      if ($profile.type === "advisor" && !$profile.validated){
        navigate("/confirmation", { replace: true });
      }
    } catch (e) {
      console.error(e);
    } finally {
      loading = false;
    }
  });
  $: clubdeals_preparation = clubdeals
    ? clubdeals.filter((el) => el.state == "notSubmitted" || el.state == "toBeLaunched")
    : [];
  $: clubdeals_subscription = clubdeals
    ? clubdeals.filter((el) => el.state == "subscription" || el.state == "running" || el.state == "signature")
    : [];
  // $: clubdeals_running = clubdeals
  //   ? clubdeals.filter((el) => el.state == "running" || el.state == "signature")
  //   : [];
  $: clubdeals_mature = clubdeals
    ? clubdeals.filter((el) => el.state == "mature")
    : [];
  let deleteClubDeal = async (cd) => {
    try {
      loading = true;
      const res = await API.del("clubdeal", { id: cd.id });
      clubdeals = clubdeals.filter(el => el.id != cd.id);
    } catch (e) {
      console.error(e);
    } finally {
      loading = false;
    }
  }
  const NavigateToPrestation = (myId = null, _) => {
    id.set(myId);
    navigate("/manager/dossier/prestation");
  };
</script>

<AppContent {loading} title={$i18n("Mes datarooms", "My datarooms")}>
  <Spacing x="32">
    <main>
      <AdvsisorColumn title={$i18n("En préparation", "In preparation")}>
        <div class="header" slot="header">
          <AppButton
            block
            color="light"
            size="big"
            on:click={() => NavigateToPrestation()}
          >
            <aside>
              <i class="material-icons">add_box</i>
              <span> {$i18n("Créer une dataroom", "Create a dataroom")} </span>
            </aside>
          </AppButton>
        </div>
        {#each clubdeals_preparation as cd}
          <AdvisorCard on:click={() => NavigateToPrestation(cd.id)}>
            {cd.company.name}
            <div slot="button">
              <AppButton
                color="alert"
                icon
                on:click={() => {
                  window.event.cancelBubble = true;
                  deleteClubDeal(cd);
                }}
                {loading}
              >
                clear
              </AppButton>
            </div>
          </AdvisorCard>
        {/each}
      </AdvsisorColumn>

      <AdvsisorColumn title={$i18n("En cours", "Running")}>
        {#each clubdeals_subscription as cd_s}
          <AdvisorCard
            on:click={() => navigate(`/manager/${cd_s.id}`, { replace: true })}
          >
            {cd_s.company.name}
          </AdvisorCard>
        {/each}
      </AdvsisorColumn>

      <!-- <AdvsisorColumn title={$i18n("En cours", "Running")}>
        {#each clubdeals_running as cd_r}
          <AdvisorCard
            on:click={() => navigate(`/manager/${cd_r.id}`, { replace: true })}
          >
            {cd_r.company.name}
          </AdvisorCard>
        {/each}
      </AdvsisorColumn> -->

      <AdvsisorColumn title={$i18n("Clôturé", "Closed")}>
        {#each clubdeals_mature as cd_m}
          <AdvisorCard disabled>
            {cd_m.company.name}
          </AdvisorCard>
        {/each}
      </AdvsisorColumn>
    </main>
  </Spacing>
</AppContent>

<style lang="scss">
  main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    @include respond-to('lg'){
      width: 1040px;
    }
    
    margin: 0 auto;
    .header {
      margin-bottom: 12px;
    }

    div {
      width: calc(100% - 24px);

      aside {
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
          flex-grow: 0;
          width: 24px;
        }
        span {
          flex-grow: 1;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
</style>
