<script>
  import AppContent from "../components/Layout/AppContent.svelte";
  import AppText from "../components/Text/AppText.svelte";
  import Spacing from "../components/Spacing/Spacing.svelte";
  import Input from "../components/Form/Input.svelte";
  import AppButton from "../components/Button/AppButton.svelte";
  import TextField from "../components/Text/TextField.svelte";
  import AppSnackbar from "../components/Snackbar/AppSnackbar.svelte";
  import { profile } from "../stores/profile";
  // import Checkbox from "@smui/checkbox";
  // import FormField from "@smui/form-field";
  import DialogValidate2FA from "../components/Dialog/DialogValidate2FA.svelte";
  // import GoogleAuthentification from "../components/Dialog/GoogleAuthentification.svelte";
  // import AppLink from "../components/Link/AppLink.svelte";
  import { i18n } from "../stores/i18n";
  import API from "../utils/api";
  let new_profile = { ...$profile };
  let validate_2fa_dialog;
  let google_authentification;
  const cleanupProfile = (profile) => {
    if (!profile.company) {
      profile.company = "";
    }
    if (!profile.firstName) {
      profile.firstName = "";
    }
    if (!profile.lastName) {
      profile.lastName = "";
    }
    if (!profile.phone) {
      profile.phone = "";
    }
  };
  cleanupProfile(new_profile);
  cleanupProfile($profile);

  $: check_if_same_data =
    JSON.stringify({
      company: new_profile.company,
      lastName: new_profile.lastName,
      firstName: new_profile.firstName,
      phone: new_profile.phone,
    }) ===
    JSON.stringify({
      company: $profile.company,
      lastName: $profile.lastName,
      firstName: $profile.firstName,
      phone: $profile.phone,
    });
  let new_pw = "";
  let new_pw_validate = "";
  let loading_pw = false;
  let loading_profile = false;
  let kyc_disabled = 0;
  let mfa_loading = false;
  //   let address = "";
  //   let postal = "";
  //   let city = "";
  //   let country = "";
  let mysnack;
  let snackmessage = "test";
  let color_snack = "primary";
  $: wrong_new_pw = !!new_pw && !!new_pw_validate && new_pw !== new_pw_validate;
  $: validate_btn = !!new_pw && !!new_pw_validate && new_pw === new_pw_validate;

  const pwUpdate = async () => {
    if (loading_pw) return;
    loading_pw = true;
    snackmessage = "";
    mysnack.close();
    try {
      await API.put("profile", {
        password: new_pw_validate,
      });
      new_pw_validate = new_pw = "";
      snackmessage = $i18n(
        "Un e-mail vient de vous être envoyé pour vous permettre de changer de mot de passe.",
        "An e-mail has just been sent to you to allow you to change your password."
      );
      color_snack = "primary";
    } catch (e) {
      console.log(e);
      snackmessage = $i18n(
        "Une erreur est survenue. Veuillez recommencer.",
        "An error has occurred. Please try again."
      );
      color_snack = "danger";
    } finally {
      loading_pw = false;
      mysnack.forceOpen();
    }
  };
  const activateKyc = () => {};
  const onApiUpdate = async () => {
    if (loading_profile) return;
    loading_profile = true;
    snackmessage = "";
    mysnack.close();
    try {
      await API.put("profile", {
        company: new_profile.company,
        lastName: new_profile.lastName,
        firstName: new_profile.firstName,
        phone: new_profile.phone,
      });
      profile.set({ ...new_profile });
      color_snack = "primary";
      snackmessage = $i18n(
        "Les modification ont été effectuées.",
        "Changes have been made."
      );
    } catch (e) {
      console.error(e);
      snackmessage = $i18n(
        "Une erreur est survenue. Veuillez recommencer.",
        "An error has occurred. Please try again."
      );
      color_snack = "danger";
    } finally {
      loading_profile = false;
      mysnack.forceOpen();
    }
  };

  const input_style = {
    crop: true,
    light: true,
    outlined: true,
  };
  const secure_style = {
    light: true,
    outlined: true,
  };
</script>

{#if !new_profile.mfa}
  <DialogValidate2FA
    bind:dialog={validate_2fa_dialog}
    mfa={new_profile.mfa}
    on:catch={({ detail }) => console.error(detail.error)}
    on:remove={(new_profile.mfa = false)}
    on:validate={()=>new_profile.mfa = true}
  />
  <!-- {:else}
  <GoogleAuthentification
    on:validate={async () => {

    }}
    bind:dialog={google_authentification}
  /> -->
{/if}
<AppContent title={$i18n("Mon compte", "My account")}>
  <AppSnackbar
    bind:value={mysnack}
    color={color_snack}
    labelText={snackmessage}
  />
  <Spacing x="32">
    <div class="rounteaccount">
      {#if new_profile.kycState !== null}
        <TextField id="KYC">KYC</TextField>
        <Spacing y="12" />
        <div
          class:kyc_disabled={new_profile.kycState === null}
          style="justify-content:start"
        >
          <AppText weight="bold">{$i18n("Etat", "State")}</AppText>:
          <Spacing x="4" />
          {#if new_profile.kycState === "inProgress"}
            <AppText>
              {$i18n(
                "Pour réaliser votre KYC, cliquez",
                "To perform your KYC, click"
              )}
              <a href={new_profile.kycUrl} target="_blank">
                {$i18n("ici", "here")}
              </a>
            </AppText>
          {:else if new_profile.kycState === "missingDocuments" || new_profile.kycState === "allDocumentsReceived"}
            <AppText
              >{$i18n(
                "Votre KYC est en cours d’étude",
                "Your KYC is under review"
              )}</AppText
            >
          {:else if new_profile.kycState === "validated"}
            <AppText color="blue" weight="bold"
              >{$i18n(
                "Votre KYC a été réalisé avec succès",
                "Your KYC has been successfully completed"
              )}</AppText
            >
          {/if}
        </div>
        <Spacing y="12" />
      {/if}
      <section>
        <AppText size="medium" weight="700"
          >{$i18n("Compte", "Account")}</AppText
        >
        <Spacing y="8" />
        <main>
          <Spacing divider block />
        </main>
      </section>
      <Spacing y="12" />
      <Input
        {...input_style}
        label={$i18n("Nom de société", "Company name")}
        crop
        bind:value={new_profile.company}
      />
      <Spacing y="12" />
      <div>
        <aside>
          <Input
            {...input_style}
            label={$i18n("Nom", "Lastname")}
            bind:value={new_profile.lastName}
            crop
          />
          <Spacing y="12" />
        </aside>

        <aside>
          <Input
            {...input_style}
            label={$i18n("Prénom", "Firstname")}
            bind:value={new_profile.firstName}
            crop
          />
          <Spacing y="12" />
        </aside>
      </div>
      <div>
        <aside>
          <Input
            {...input_style}
            label={$i18n("Téléphone", "Phone")}
            bind:value={new_profile.phone}
          />
        </aside>
      </div>
      <Spacing y="12" />
      <AppButton
        disabled={check_if_same_data}
        block
        bind:loading={loading_profile}
        size="big"
        on:click={onApiUpdate}
        >{$i18n("Valider les modifications", "Validate changes")}</AppButton
      >
      <Spacing y="12" />
      <section>
        <AppText size="medium" weight="700"
          >{$i18n("Mot de passe", "Password")}</AppText
        >
        <Spacing y="8" />
        <main>
          <Spacing divider block />
        </main>
      </section>
      <Spacing y="12" />
      <Input
        {...input_style}
        type="password"
        label={$i18n("Nouveau mot de passe", "New password")}
        bind:value={new_pw}
      />
      <Spacing y="12" />
      <Input
        {...input_style}
        type="password"
        label={$i18n(
          "Nouveau mot de passe (confirmation)",
          "New password (confirmation)"
        )}
        bind:value={new_pw_validate}
      />
      <span class:error={wrong_new_pw}>
        <b
          >{$i18n(
            "Le nouveau mot de passe et sa confirmation doivent correspondre",
            "The new password and its confirmation must match."
          )}
        </b>
      </span>
      <AppButton
        block
        bind:loading={loading_pw}
        disabled={!validate_btn}
        on:click={pwUpdate}
        >{$i18n("Modifier le mot de passe", "Change password")}</AppButton
      >
      <Spacing y="12" />
      <TextField id="KYC">Double facteur d'authentification</TextField>
      <Spacing y="12" />

      {#if new_profile.mfa}
        <section
          style="display:flex;align-items: center; justify-content: flex-start;"
        >
          <i class="material-icons" style="color:greenyellow;">check</i>
          <Spacing x="4">
            <span style="margin-top:2px;">
              <AppText>Facteur d'authentification activé</AppText>
            </span>
          </Spacing>
        </section>
        <Spacing y="12" />
        <AppButton
          bind:loading={mfa_loading}
          block
          on:click={async () => {
            if (mfa_loading) return;
            try {
              mfa_loading = true;
              await API.del("mfa");
              // snackbar.color_snack = "primary";
              // labelText = "second facteur d'authentification désactivé";
              new_profile.mfa = false;
            } catch (e) {
              console.error(e);
              // color_snack = "danger";
              // labelText = "Une erreur est survenue veuillez recommencer";
            } finally {
              // mysnack.forceOpen();
              mfa_loading = false;
            }
          }}
        >
          Désactiver mon second facteur d'authentification
        </AppButton>
      {:else}
        <section
          style="display:flex;align-items: center; justify-content: flex-start;"
        >
          <i class="material-icons" style="color:#fc382a;">close</i>
          <Spacing x="4">
            <span style="margin-top:2px;">
              <AppText>Facteur d'authentification désactivé</AppText>
            </span>
          </Spacing>
        </section>
        <Spacing y="12" />
        <AppButton
          bind:loading={mfa_loading}
          block
          on:click={() => validate_2fa_dialog.open()}
        >
          Activer mon second facteur d'authentification
        </AppButton>
      {/if}

      <!-- <FormField>
        <Checkbox
          bind:checked={new_profile.doubleAuthentification}
          input$required
        />
        <div slot="label">
          <AppText>Activer la double authentification Google</AppText>
        </div>
      </FormField> -->

      <Spacing y="12" />
      <TextField id="KYC">{$i18n("CGU / Contrat", "Terms of use")}</TextField>
      <Spacing y="12" />
      <AppText>
        {$i18n(
          "Ici sont disponibles les CGU signées et le contrat d’ouverture de compte",
          "        Here you can find the signed GTC and the account opening contract"
        )}:
      </AppText><Spacing y="4" />
      -
      <a
        href={$i18n("/assets/terms-fr.pdf", "/assets/terms-en.pdf")}
        target="_blank">{$i18n("CGU", "Terms")}</a
      >
      <Spacing y="12" />
    </div>
  </Spacing>
</AppContent>

<style lang="scss">
  .kyc_disabled {
    opacity: 0.3;
    a.c-applink--primary {
      cursor: default;
    }
  }
  .rounteaccount {
    a {
      font-family: "Lato";
      font-size: 13px;
      color: $blue;
    }
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    div {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      aside {
        width: 100%;
        @include respond-to("sm") {
          width: calc(50% - 12px);
        }
      }
    }
    span {
      font-size: 11px;
      display: flex;
      height: 32px;
      overflow: hidden;
      align-items: center;
      font-family: "Lato";
      color: $danger;
      position: relative;
      left: 0;
      * {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all 300ms ease;
      }
      &.error {
        * {
          opacity: 1;
          transition: all 200ms ease;
          font-weight: normal;
          top: 30%;
          transform: translateY(-30%);
        }
      }
    }
    section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      main {
        flex: 1 1 auto;
        margin-left: 8px;
        margin-top: 3px;
      }
    }
  }
</style>
