<script>
  import ButtonMenu from "./ButtonMenu.svelte";
  import ItemFlag from "../Item/ItemFlag.svelte";
  import { createEventDispatcher } from "svelte";
  import { i18nLang, i18n } from "../../stores/i18n";
  import { profile } from "../../stores/index";
  import API from "../../utils/api";
  
  /**
   * Title of my button
   * @var {String} title - return a title
   */
  export let title;

  /**
   * dispatch set-language function
   * @param {string} newlanguge - set my new language
   * @return {void} Nothing
   */
  const dispatch = createEventDispatcher();
  const setLanguage = (newLanguage) => {
    dispatch("set-language", newLanguage);
    i18nLang.set(newLanguage);
    // update profile
    $profile.language = newLanguage
    API.put("profile", $profile)
  };

  let languages_list = [
    {
      src: "/assets/flags/flag_fr.svg",
      name: ["français", "français"],
      onClick: () => setLanguage("fr"),
    },
    {
      src: "/assets/flags/flag_uk.svg",
      name: ["english", "english"],
      onClick: () => setLanguage("en"),
    },
  ];
</script>

<ButtonMenu {title}>
  {#each languages_list as { src, name, onClick }}
    <ItemFlag {src} name={$i18n(name[0], name[1])} {onClick} />
  {/each}
</ButtonMenu>
