<script>
  export let material_icon;

  export let title = "";
  export let size = "normal";
  export let no_padding = false;
  export let round = false;
  export let flat = false;
  export let button = false;
  export let loading = false;
  export let color = "primary";
  const COLORS_LIST = [
    "primary",
    "transparent",
    "secondary",
    "normal",
    "blue",
    "progress",
    "danger",
  ];
  if (!COLORS_LIST.includes(color)) {
    throw new Error(
      `invalide props 'color' value in <AppIcon/>: props is equal to ${color}, possible values are ${COLORS_LIST.join(
        ", "
      )}.`
    );
  }
</script>

<main class:button>
  <div
    class="c-appicon--{size} c-appicon--{color}"
    class:nopadding={no_padding}
    class:round
    class:flat
    {title}
  >
    <i class="material-icons" class:loading>
      {material_icon}
    </i>
  </div>
  <slot />
</main>

<style lang="scss">
  main {
    display: flex;
    align-items: center;
    /* width: 100%; */
    text-overflow: ellipsis;
    white-space: nowrap;
    &.button {
      cursor: pointer;
    }

    div {
      border-radius: 3px;
      margin-right: 4px;
      box-shadow: $bg-shadow;
      display: flex;
      background: $white;
      margin-right: 4;
      width: auto;
      height: auto;
      &.c-appicon {
        &--normal {
          padding: 5px;
          i {
            font-size: 14px;
          }
          &.nopadding {
            padding: 0 5px;
          }
        }
        &--medium {
          padding: 6px;
          i {
            font-size: 16px;
          }
          &.nopadding {
            padding: 0 6px;
          }
        }
        &--large {
          padding: 7px;
          i {
            font-size: 18px;
          }
          &.nopadding {
            padding: 0 7px;
          }
        }
        &--primary {
          color: $blue;
          background: $white;
        }
        &--transparent {
          background: rgba(0, 0, 0, 0);
        }
        &--secondary {
          background: $gray_dark;
          opacity: 0.5;
          i {
            color: $black;
          }
        }
        &--blue {
          background: rgb(216, 216, 255);
          opacity: 0.8;
          i {
            color: $blue;
          }
        }
        &--danger {
          background: #ffd2d2;
          opacity: 0.8;
          i {
            color: $danger;
          }
        }
        &--progress {
          background: rgb(255, 221, 215);
          opacity: 0.8;
          i {
            color: tomato;
          }
        }
      }
      &.round {
        border-radius: 50%;
      }
      &.flat {
        box-shadow: none;
      }
    }
  }
</style>
