<script>
  export let position;

  $: icon =
    position === "down"
      ? "arrow_drop_down"
      : position === "up"
      ? "arrow_drop_up"
      : "";
</script>

<span class="material-icons" style="font-size:22px;opacity:0.33;width:20px;">{icon}</span>
