import axios from "axios";
import { navigate } from "svelte-routing";
import { LOGIN_PATH } from "../../constants";
import { profile, login } from "../../stores/index";

let baseURL = "/api"

// if (window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1")){
//     baseURL = "https://ackmguwcbckwdzkankoktf4c5u0qdzeq.lambda-url.eu-west-3.on.aws/clubdeal/"; 
//     console.log("using API: ", baseURL)
// }

const axiosAPI = axios.create({
    baseURL,
    headers: {'X-White-Label': 'firmclosing'}
})

export const getToken = () => {
    return localStorage.getItem("token")
}
const apiRequest = async (method, url, request, params) => {
    try {
        const response = await axiosAPI({
            method,
            url,
            data: request,
            params
        })
        if (response.data.token) {
            localStorage.setItem("token", response.data.token)
        }
        return Promise.resolve(response.data)
    } catch (err) {
        if (err.response && err.response.status == 401) {
            console.log("disconnected", err.response)
            login.set(false);
            localStorage.removeItem("token");
            profile.set({});
            navigate(LOGIN_PATH, { replace: true });
            // window.location.reload();
        }
        return Promise.reject(err)
    }
}

const get = (url, request) => apiRequest("GET", url, {}, { ...request, token: getToken() })
const post = (url, request) => apiRequest("POST", url, { ...request, token: getToken() })
const put = (url, request) => apiRequest("PUT", url, { ...request, token: getToken() })
const del = (url, request) => apiRequest("DELETE", url, {}, { ...request, token: getToken() })

const API = {
    baseURL,
    get,
    post,
    put,
    del
}

export default API;