<script>
  import AppContent from "../components/Layout/AppContent.svelte";
  import Spacing from "../components/Spacing/Spacing.svelte";
  import Input from "../components/Form/Input.svelte";
  import Text from "../components/Text/AppText.svelte";
  import AppText from "../components/Text/AppText.svelte";
  import AppButton from "../components/Button/AppButton.svelte";
  import ValidationLayout from "../components/Layout/ValidationLayout.svelte";
  import API from "../utils/api";
  import { LOGO_PATH } from "../constants";
  import { navigate } from "svelte-routing";
  import { fade, fly } from "svelte/transition";
  import { LOGIN_PATH } from "../constants";
  import { i18n } from "../stores/i18n";
  export let id;

  let loading = false;
  let error = false;
  let done = false;
  let password = "";

  const changePassword = async () => {
    let errTime;
    if (loading) return;
    loading = true;
    if (error) {
      clearTimeout(errTime);
      error = false;
    }
    try {
      await API.post("accountNewPassword", { data: id, password });
      done = true;
    } catch (e) {
      error = true;
      errTime = setTimeout(function () {
        if (error) error = false;
      }, 4000);
    } finally {
      loading = false;
    }
  };
</script>

<AppContent>
  <div>
    <main>
      <figure>
        <img src={LOGO_PATH} alt="logo" />
      </figure>
      <Spacing y="12" />
      <span>
        <Text size="xl" weight="900"
          >{$i18n("Changement de mot de passe", "Password change")}</Text
        >
      </span>
      {#if !done}
        <section transition:fly={{ y: 50, duration: 500 }}>
          <Input
            uppercase
            crop
            label={$i18n("Nouveau mot de passe", "New password")}
            required
            type="password"
            bind:value={password}
          />
          <Spacing y="12" />
          <AppButton width="120px" bind:loading on:click={changePassword}
            >{$i18n(
              "Modifier mon mot de passe",
              "Change my password"
            )}</AppButton
          >
        </section>
        {#if error}
        <section transition:fly={{ y: 30, duration: 500 }}>
          <Spacing y="24" />
          <AppText center weight="700" color="danger"
            >{$i18n("Une erreur est survenue, veuillez recommencer.", "An error has occurred, please try again.")}</AppText
          >
        </section>
      {/if}
      {:else}
        <section transition:fly={{ y: 50, duration: 300, delay: 500 }}>
          <AppText center
            ><b
              >{$i18n(
                "Votre modification de mot de passe a bien été prise en compte",
                "Your password change has been taken into account"
              )}</b
            >,
            {$i18n(
              "vous pouvez à présent retouner à la page de connexion.",
              "you can now go back to the login page."
            )}</AppText
          >
          <img id="tick" src="/assets/tick.svg" alt="ok" />
          <AppButton
            block
            icon
            size="small"
            on:click={() => navigate(LOGIN_PATH, { replace: true })}
          >
            arrow_back
            <span slot="text">
              <AppText color="white"
                >{$i18n(
                  "Retourner à la page de connexion",
                  "Back to the login page"
                )}</AppText
              >
            </span>
          </AppButton>
        </section>
      {/if}
    </main>
  </div>
</AppContent>

<style lang="scss">
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    main {
      width: 440px;
      background-color: $white;
      box-shadow: $blue-shadow;
      padding: $spacing_xl;
      border-radius: $border-radius_normal;
      figure {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          height: 42px;
        }
      }
      span {
        display: block;
        margin: $spacingY_large;
      }
    }
  }
  img#tick {
    padding-top: 16px;
    margin: 0 auto;
    text-align: center;
    display: block;
    width: 48px;
    transition: all 200ms ease;
    padding-bottom: 24px;
    @include respond-to("sm") {
      width: 64px;
      padding-top: 24px;
    }
    @include respond-to("md") {
      width: 72px;
    }
  }
</style>
