<Item on:SMUI:action={onClick}>
    <div>
        <i class="material-icons">{icon}</i>
        <AppText><slot/></AppText>
    </div>
</Item>

<script >
    import { Item } from '@smui/list'
    import AppText from "../Text/AppText.svelte"
    import { createEventDispatcher } from "svelte"

    const dispatch = createEventDispatcher();
    const onClick = () => {
        dispatch("click")
    }

    export let icon
</script>

<style lang="scss">
    div {
        display: flex;
        align-items: center;
        min-width: 110px;
        white-space: nowrap;
        i {
            margin-right: 8px;
            color:#000000DE;
            opacity:0.4;
        }
    }
</style>