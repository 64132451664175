<script>
  import AppText from "../components/Text/AppText.svelte";
  import ValidationLayout from "../components/Layout/ValidationLayout.svelte";
  import API from "../utils/api";
  import { i18n } from "../stores/i18n";

  export let id;

  API.post(`accountConfirmation`, { data: id })
    .then((_) => {
    })
    .catch((err) => {
      console.log("error:", err);
    });
</script>

<ValidationLayout
  title={$i18n(
    "Vos informations sont en cours de validation.",
    "Your information is being validated."
  )}
  src="/assets/magnifying-glass.svg"
>
  <AppText center size="medium">
    {$i18n(
      "Vous recevrez un email dès que votre compte sera validé.",
      "You will receive an email as soon as your account is validated."
    )}
  </AppText>
</ValidationLayout>
