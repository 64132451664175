<script>
    import AppContent from "../components/Layout/AppContent.svelte";
    import PdfDocument from "../components/Pdf/PdfDocument.svelte";
    import Matrix from "../components/Matrix.svelte";

    // coming from the API
    let investors = [
        {
            id: "bobId",
            firstName: "Bob",
            lastName: "Jones",
            company: "Bob Ltd",
        },
        {
            id: "aliceId",
            firstName: "Alice",
            lastName: "Jones",
            company: "Alice Ltd",
        },
        {
            id: "floId",
            firstName: "Flo",
            lastName: "Vasilescu",
            company: "Flo Ltd",
        },
        { id: "tomId", firstName: "Tom", lastName: "Gil", company: "Tom Ltd" },
        { id: "aId", firstName: "A", lastName: "A", company: "A Ltd" },
        { id: "bId", firstName: "B", lastName: "B", company: "B Ltd" },
        { id: "cId", firstName: "C", lastName: "C", company: "C Ltd" },
        { id: "dId", firstName: "D", lastName: "D", company: "D Ltd" },
        { id: "eId", firstName: "E", lastName: "E", company: "E Ltd" },
        { id: "fId", firstName: "F", lastName: "F", company: "F Ltd" },
        { id: "gId", firstName: "G", lastName: "G", company: "G Ltd" },
        { id: "hId", firstName: "H", lastName: "H", company: "H Ltd" },
        { id: "iId", firstName: "I", lastName: "I", company: "I Ltd" },
    ];
    let documents = [
        // { access: "protectedAccess", fileName: "A/", authorized: ["bobId"], isFolder: true },
        { access: "protectedAccess", fileName: "A/a", authorized: ["bobId"] },
        {
            access: "protectedAccess",
            fileName: "A/b",
            authorized: ["bobId", "aliceId"],
        },
        { access: "protectedAccess", fileName: "A/c", authorized: ["bobId"] },
        // { access: "protectedAccess", fileName: "B/", authorized: ["bobId"], isFolder: true },
        {
            access: "protectedAccess",
            fileName: "B/d",
            authorized: ["bobId", "aliceId", "floId"],
        },
        {
            access: "protectedAccess",
            fileName: "B/e",
            authorized: ["bobId", "floId", "tomId"],
        },
        {
            access: "protectedAccess",
            fileName: "f",
            authorized: ["bobId", "aliceId"],
        },
        {
            access: "publicAccess",
            fileName: "g",
            authorized: ["bobId", "aliceId"],
        },
        {
            access: "publicAccess",
            fileName: "document A",
            authorized: ["bobId", "aliceId"],
        },
        {
            access: "publicAccess",
            fileName: "document B",
            authorized: ["bobId", "aliceId"],
        },
        {
            access: "publicAccess",
            fileName: "document C",
            authorized: ["bobId", "aliceId"],
        },
        {
            access: "publicAccess",
            fileName: "document D",
            authorized: ["bobId", "aliceId"],
        },
        {
            access: "publicAccess",
            fileName: "document E",
            authorized: ["bobId", "aliceId"],
        },
        {
            access: "publicAccess",
            fileName: "document F",
            authorized: ["bobId", "aliceId"],
        },
        {
            access: "publicAccess",
            fileName: "document G",
            authorized: ["bobId", "aliceId"],
        },
        {
            access: "publicAccess",
            fileName: "document H",
            authorized: ["bobId", "aliceId"],
        },
    ];
</script>

<AppContent>
    <Matrix {investors} {documents} /> 
    <!-- <PdfDocument></PdfDocument> -->
</AppContent>

<style>
</style>
