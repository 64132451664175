<script>
  import { onMount, onDestroy } from "svelte";
  import AppContent from "../../components/Layout/AppContent.svelte";
  import Input from "../../components/Form/Input.svelte";
  import AppText from "../../components/Text/AppText.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import Select from "svelte-select";
  import Radio from "@smui/radio";
  import FormField from "@smui/form-field";
  import AppButton from "../../components/Button/AppButton.svelte";
  import { clubdeal } from "../../stores/clubdeal";
  import { REGION_LIST, COUNTRY_LIST } from "../../constants/index";
  import { i18n } from "../../stores/i18n";
  import { autoUpdate } from "../../utils/functions/autoUpdate";
  import AppSnackbar from "../../components/Snackbar/AppSnackbar.svelte";
  import Menu from "@smui/menu";
  import { Anchor } from "@smui/menu-surface";
  import List, { Item, Text } from "@smui/list";

  export let increment_step;
  export let decrement_step;

  let snackSaved;
  autoUpdate(clubdeal, onMount, onDestroy, (state) => {
    snackSaved.forceOpen();
  });
  const itemFilter = (label, textFilter) => {
    // NFD décomposes any characteres and accents into unicode: if i had an accent "é"
    // it will decompose into 2 unicodes : U+0065 -> e and U+0300 ->  ́
    // and we remove any accents from u0300 to u036f (all accents,see:https://fr.wikipedia.org/wiki/Table_des_caract%C3%A8res_Unicode/U0300)
    return label
      .normalize("NFD")
      .toLowerCase()
      .replace(/[\u0300-\u036f]/g, "")
      .includes(textFilter.trim().toLowerCase());
  };
  $: selectedRegion = REGION_LIST.find(
    (e) => e.value == $clubdeal.company.headquartersRegion
  );
  function onSelectRegion(event) {
    $clubdeal.company.headquartersRegion = event.detail.value;
    $clubdeal.company.headquartersCountry = null;
  }

  $: c = COUNTRY_LIST.find(
    (e) => e.value == $clubdeal.company.headquartersCountry
  );
  $: selectedCountry = c ? { value: c.value, label: $i18n(...c.label) } : null;
  function onSelectCountry(event) {
    $clubdeal.company.headquartersRegion = null;
    $clubdeal.company.headquartersCountry = event.detail.value;
  }

  let options = ["SA", "SAS", "SCA", "SARL"].map((e) => {
    return { name: e };
  });

  // Dynamic menu for siren codes
  let anchor;
  let anchorClasses = [];
  let menuCompanyName;
  let possibleCompanies = [];

  let onCompanyNameChangedTimeout;
  let onCompanyNameChanged = (e) => {
    let companyName = e.target.value;
    if (companyName.length > 2) {
      clearTimeout(onCompanyNameChangedTimeout);
      onCompanyNameChangedTimeout = setTimeout(() => {
        possibleCompanies = [{}];
        menuCompanyName.setOpen(true);
        fetch("https://data.opendatasoft.com/api/explore/v2.1/catalog/datasets/economicref-france-sirene-v3@public/records?select=ifnull(denominationunitelegale%2C%20nomunitelegale)%20as%20nom%2C%20siren&where=startswith(denominationunitelegale,nomunitelegale,%22" + encodeURIComponent(companyName.toUpperCase()) + "%22)&limit=10")
          .then((res) => res.json())
          .then((res) => {
            const { results } = res;
            possibleCompanies = results
              .map((record) =>
                Object.assign({}, record, {
                  name: record.nom,
                  siret: record.siren,
                })
              )
              .filter((o) =>
                o.name.toLowerCase().includes(companyName.toLowerCase())
              );
            if (possibleCompanies.length > 10) {
              possibleCompanies.length = 10;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    }
  };
</script>

<AppContent
  type="advisor"
  title={$i18n("Informations: Société", "Information: Company")}
>
  <!-- <aside> -->
    <div
      style="min-width: 100px;"
      class={anchorClasses.join(" ")}
      use:Anchor={{ classForward: (classes) => (anchorClasses = classes) }}
      bind:this={anchor}
    >
      <Input
        block
        label={$i18n("Raison sociale", "Company Name")}
        bind:value={$clubdeal.company.name}
        onInput={onCompanyNameChanged}
        onClick={onCompanyNameChanged}
      />
      <Menu
        bind:this={menuCompanyName}
        anchor={false}
        bind:anchorElement={anchor}
        anchorCorner="BOTTOM_LEFT"
        style="margin-top: -25px; margin-left: -5px;"
      >
        <List>
          {#each possibleCompanies as possibleCompany}
            {#if possibleCompany.siret}
              <Item
                on:SMUI:action={() => {
                  $clubdeal.company.name = possibleCompany.name;
                  $clubdeal.company.siret = possibleCompany.siret;
                }}
                ><Text>{possibleCompany.name} ({possibleCompany.siret})</Text
                ></Item
              >
            {:else}
              <Item
                ><Text style="justify-content: flex-end">
                  <i class="material-icons">cloud_queue</i>
                  {$i18n("Chargement en cours...", "Loading...")}
                </Text></Item
              >
            {/if}
          {/each}
        </List>
      </Menu>
    </div>

    <!-- <Input
      block
      label={$i18n("Siren", "Siren")}
      bind:value={$clubdeal.company.siret}
    /> -->
  <!-- </aside> -->

  <!-- <AppText weight="700">{$i18n("Forme juridique:", "Legal form:")}</AppText>
  <Spacing y="4" />
  {#each options as option}
    <FormField>
      <Radio
        color="primary"
        bind:group={$clubdeal.company.legalForm}
        value={option.name}
      />
      <span style="margin-right:4px;" slot="label"
        >{option.name}{option.disabled ? " (disabled)" : ""}</span
      >
    </FormField>
  {/each}
  <Spacing y="12" /> -->
  <section>
    <AppText weight="700">
      {$i18n("Lieu du siège social", "Head office location")}
    </AppText>
    <Spacing y="8" />
    <main>
      <Spacing divider block />
    </main>
  </section>

  <Spacing y="12" />
  <aside>
    <div>
      <AppText weight="700">{$i18n("En France:", "In France:")}</AppText>
      <Spacing y="4" />
      <div class="themed">
        <Select
          items={REGION_LIST.sort((l, r) => l.label.localeCompare(r.label))}
          on:select={onSelectRegion}
          {itemFilter}
          isMulti={false}
          noOptionsMessage={$i18n("Plus d'options", "More options")}
          selectedValue={selectedRegion}
        />
      </div>
    </div>
    <div>
      <AppText weight="700">{$i18n("Hors France:", "Outside France:")}</AppText>
      <Spacing y="4" />
      <div class="themed">
        <Select
          items={COUNTRY_LIST.map((c) => {
            return { value: c.value, label: $i18n(...c.label) };
          }).sort((l, r) => l.label.localeCompare(r.label))}
          isMulti={false}
          {itemFilter}
          on:select={onSelectCountry}
          noOptionsMessage={$i18n("Plus d'options", "More options")}
          selectedValue={selectedCountry}
        />
      </div>
    </div>
  </aside>
  <div class="footer" slot="footer">
    <AppButton on:click={() => decrement_step()}>
      {$i18n("Précédent", "Previous")}
    </AppButton>
    <AppButton on:click={() => increment_step()}>
      {$i18n("Suivant", "Next")}
    </AppButton>
  </div>
  <AppSnackbar
    bind:value={snackSaved}
    labelText={$i18n(
      "Vos informations ont bien été enregistrées",
      "Your information has been saved"
    )}
  />
</AppContent>

<style lang="scss">
  section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    main {
      flex: 1 1 auto;
      margin-left: 8px;
      margin-top: 3px;
    }
  }
  aside {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  div.footer {
    display: flex;
    justify-content: space-between;
  }
</style>
