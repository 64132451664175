import API from "../api";

export const autoUpdate = (store, onMount, onDestroy, onUpdate, onStateFirstReceived) => {
    let init = false;
    let notified = false;
    onMount(() => { // svelte runs this function asynchronously once during subscription
        if (! init) {
            init = true;
            let unsubscriber =
                store.subscribe((state) => {
                    if (!notified && state.id && state.id.length){
                        notified = true;
                        if (onStateFirstReceived){
                            onStateFirstReceived(state);
                        }
                    }
                    if (store.unsubscriber) {
                        if (store.timer) {
                            clearTimeout(store.timer);
                        }
                        store.timer = setTimeout(() => {
                            if (state.id && state.id.length){
                                API.put(store.entrypoint, state); // automatic API update of the store
                                if (onUpdate) {
                                    onUpdate(state);
                                }
                            }
                        }, 1000);
                    }
                });
            setTimeout(() => { // to handle the asynchronousness 
                if (store.unsubscriber) {
                    store.unsubscriber();
                }
                store.unsubscriber = unsubscriber;
            }, 1000);

        }
    });
    onDestroy(() => {
        if (store.unsubscriber) {
            store.unsubscriber();
            store.unsubscriber = null;
        }
    });
}