<script>
  import CheckButton from "../Button/CheckButton.svelte";
  import AppSnackbar from "../Snackbar/AppSnackbar.svelte";
  import { i18n, i18nLang } from "../../stores/i18n";
  import Input from "../Form/Input.svelte";
  import AppButton from "../Button/AppButton.svelte";
  import Dialog from "@smui/dialog";
  import IconButton, { Icon } from "@smui/icon-button";
  import API from "../../utils/api";
  import { createEventDispatcher } from "svelte";
  import Loading from "../Loading/Loading.svelte";
  import { delay } from "../../utils/functions/delay";
  import AppText from "../Text/AppText.svelte";
  import Checkbox from "../Form/Checkbox.svelte";
  export let dialog;
  export let isPrivate = false;
  export let creationDate = new Date();
  export let document;
  export let invests = [];
  export let bondId;
  export let index;

  let test = false;
  let snackbar, snackbar_error;
  let delete_loading = false;
  let rename_loading = false;
  let global_btn_loading = false;
  let loading = false;
  let loading_notification = false;
  let invests_filter = invests;
  let rename = "";
  let rename_content_active = false;
  invests = invests.length
    ? invests.map((el) => {
        return { ...el, loading: false };
      })
    : [];
  const dispatch = createEventDispatcher();
  const format_date = creationDate.toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  const creationDate_format = (lang = "fr-FR") => {
    return creationDate.toLocaleDateString(lang, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };
  const format = (date, lang = "fr-FR") => {
    return new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };
  const onSearch = (e) => {
    const str_format = (str) => {
      return str
        .toLowerCase()
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    };
    const value = str_format(e.target.value);
    invests_filter = invests.filter((el) => {
      return (
        str_format(el.firstName + " " + el.lastName).includes(value) ||
        str_format(el.email).includes(value)
      );
    });
  };
  const OnAdd = async (i) => {
    if (invests_filter[i].loading === true) return;
    invests_filter[i].loading = true;

    if (document.authorized.length === invests.length - 1) {
      await onPublic(null, true);
      invests_filter[i].loading = false;
      return;
    }

    const authorized = [...document.authorized, invests_filter[i].id];
    const updateDocument = () => (document.authorized = [...authorized]);
    const access =
      document.access !== "protectedAccess"
        ? (document.access = "protectedAccess")
        : document.access;
    await APItoggle({ ...document, authorized, access }, updateDocument, null);
    invests_filter[i].loading = false;

    await delay(200);
    dispatch("change", { authorized, index });
  };

  const OnRemove = async (i) => {
    if (invests_filter[i].loading === true) return;
    invests_filter[i].loading = true;

    if (document.authorized.length == 1) {
      await onPrivate(null, true);
      invests_filter[i].loading = false;
      return;
    }

    const authorized = document.authorized.filter(
      (l) => l != invests_filter[i].id
    );
    const updateDocument = () => (document.authorized = [...authorized]);
    const access =
      document.access !== "protectedAccess"
        ? (document.access = "protectedAccess")
        : document.access;
    await APItoggle({ ...document, authorized, access }, updateDocument, null);
    invests_filter[i].loading = false;
    if (document.access !== "protectedAccess") {
      document.access = "protectedAccess";
    }
    await delay(200);
    dispatch("change", { authorized, index });
  };
  async function APItoggle(input, callbak, error_callback = null) {
    try {
      let request = {};
      for (const key in input) {
        if ((key !== "lastNotification" && key !== "lastNotifications")) {
          request[key] = input[key];
        }
      }
      await API.put("document", request);
      callbak();
    } catch (e) {
      console.error(e);
      if (error_callback) {
        error_callback(e);
      }
    }
  }
  function onNotification(e) {
    return (async function (subscribersIds = null) {
      if (loading_notification) return;
      loading_notification = true;
      try {
        await API.post("documentNotification", {
          docId: document.id,
          bondId: document.bondId,
          subscribersIds: null,
        });
        document.lastNotification = new Date();
        document.lastNotifications = document.lastNotifications ? document.lastNotifications : {}
        for (const id of document.authorized){
          document.lastNotifications[id] = new Date(document.lastNotification).getTime()
        }
        snackbar.forceOpen();
      } catch (e) {
        console.error(e);
        snackbar_error.forceOpen();
      } finally {
        loading_notification = false;
      }
    })();
  }

  async function onPrivate(event, buttonloading = false) {
    if (global_btn_loading === true) return;
    if (!buttonloading) {
      global_btn_loading = true;
    }
    const authorized = [];
    const updateDocument = () => (document.authorized = authorized);
    await APItoggle(
      { ...document, authorized, access: "privateAccess" },
      updateDocument,
      null
    );
    document.access = "privateAccess";
    global_btn_loading = false;
    await delay(200);
    dispatch("change", { authorized, index });
  }
  async function onPublic(event, buttonloading = false) {
    if (global_btn_loading === true) return;
    if (!buttonloading) {
      global_btn_loading = true;
    }

    const authorized = [...invests_filter.map((el) => el.id)];

    const updateDocument = () => (document.authorized = authorized);

    await APItoggle(
      { ...document, authorized, access: "publicAccess" },
      updateDocument,
      null
    );
    document.access = "publicAccess";
    global_btn_loading = false;
    await delay(200);
    dispatch("change", { authorized, index });
  }

  const onClose = async () => {
    dialog.close();
    // if (isPrivate && document.authorized.length === 0) {
    //   isPrivate = false;
    // }
    // await delay(1000);
    rename_content_active = false;
  };
  const onRenameValidate = async () => {
    if (rename_loading || rename === "") retrename_loadingurn;
    rename_loading = true;
    try {
      const format = document.fileName.slice(
        document.fileName.lastIndexOf(".")
      );
      const prefix =
        document.fileName.indexOf("/") === -1
          ? ""
          : document.fileName.slice(0, document.fileName.lastIndexOf("/") + 1);
      const fileName = prefix + rename + format;
      await dispatch("rename", { index, fileName });
      document.fileName = fileName;
      rename = "";
    } finally {
      rename_loading = false;
    }
  };
  const handlerEnter = async (e) => {
    if (e.key === "Escape") {
      rename_content_active = false;
      return;
    }

    if (e.key === "Enter") {
      onRenameValidate();
    }
  };

  const onDelete = async () => {
    delete_loading = true;
    try {
      dialog.close();
      await dispatch("delete", { index });
    } finally {
      delete_loading = false;
    }
  };
  $: if (
    document.access == "protectedAccess" ||
    document.access == "privateAccess"
  ) {
    isPrivate = true;
  } else {
    isPrivate = false;
  }
</script>

<Dialog
  escapeKeyAction=""
  scrimClickAction=""
  style="z-index:99999;"
  class="customdocdialog"
  bind:this={dialog}
>
  <AppSnackbar style="margin-bottom: 56px;" bind:value={snackbar}>
    {$i18n("Notification envoyée", "Notification sent")}
  </AppSnackbar>
  <AppSnackbar style="margin-bottom: 56px;" bind:value={snackbar_error}>
    {$i18n("Une erreur est survenue", "An error has occurred")}
  </AppSnackbar>
  <main class="documentdialog">
    <aside>
      <h1>{document.fileName}</h1>
      <h2>
        {#if isPrivate}
          <span class="material-icons">locks</span>{$i18n("Privé", "Private")}
        {:else}
          <span class="material-icons">lock_open</span>{$i18n(
            "Publique",
            "Public"
          )}
        {/if}
      </h2>
      <b
        >{$i18n(
          `Créé le ${creationDate_format()}`,
          `Created in ${creationDate_format("en-EN")}`
        )}</b
      >
      <b>
        Dernière notification:
        <span>
          {#if document.lastNotification !== null}
            <span>{format(document.lastNotification)}</span>
          {:else}
            <span>Aucune</span>
          {/if}
        </span>
      </b>

      {#if isPrivate}
        <button class="public" on:click={onPublic}>
          <span class="material-icons">lock_open</span>
          <p>{$i18n("Donner accès à tous", "Giving access to all")}</p>
          {#if global_btn_loading}
            <Loading
              size="16"
              radius="8"
              color=" #e8e5ff"
              background="#2f80ed"
            />
          {/if}
        </button>
      {:else}
        <button class="private" on:click={onPrivate}>
          <span class="material-icons">lock</span>
          <p>{$i18n("Retirer l'accès à tous", "Remove access to all")}</p>
          {#if global_btn_loading}
            <Loading
              size="16"
              radius="8"
              color="#ffe5e5"
              background="#eb5757"
            />
          {/if}
        </button>
        <!-- {:else}
        <button class="unknown" class:loading>
          {#if loading}
            <div><span><b /></span></div>
          {/if}
        </button> -->
      {/if}
      <div class="documentdialog-sharelink">
        <!-- <p>oui</p>
        <p>oui</p>
        <p>oui</p> -->
      </div>

      <div class="documentdialog-actions">
        <div class="documentdialog-actions_flex">
          <button
            class="actions_button blue"
            on:click={() => (rename_content_active = true)}
            ><span class="material-icons">edit</span></button
          >
          <p class="blue">{$i18n("Renommer", "Rename")}</p>
        </div>
        <div class="documentdialog-actions_flex">
          <button on:click={onDelete} class="actions_button red">
            {#if !delete_loading}
              <span class="material-icons">delete_outline</span>
            {:else}
              <Loading size="16" />
            {/if}
          </button>
          <p class="red">{$i18n("Supprimer", "Delete")}</p>
        </div>
        <div
          class="documentdialog-actions_rename"
          class:on={rename_content_active}
        >
          <div
            style="display: flex; justify-content:space-between;align-items:center"
          >
            <AppText color="white" weight="bold">Renommer le document</AppText>
            <span
              on:click={() => (rename_content_active = false)}
              class="material-icons"
              style="opacity:0.4;cursor: pointer; padding: 2px; color: white;"
              >close
            </span>
          </div>
          <div class="documentdialog-actions_rename_input">
            <input
              placeholder="Nom du document"
              bind:value={rename}
              on:keyup={handlerEnter}
            />
            <button
              class="rename"
              class:active={rename !== "" && !rename_loading}
              on:click={onRenameValidate}
            >
              {#if !rename_loading}
                <span class="material-icons">check</span>
              {:else}
                <Loading size="16" />
              {/if}
            </button>
          </div>
        </div>
      </div>
    </aside>
    <section>
      <div class="documentdialog-header">
        <h1>
          {$i18n("Sélection des participants", "Selection of participants")}
        </h1>
        <IconButton class="material-icons" on:click={onClose}>
          cancel
        </IconButton>
      </div>
      {#if !invests.length}
        <div class="documentdialog-none">
          <p>
            {$i18n(
              "Aucun participant présent dans cette dataroom",
              "No participant present in this dataroom"
            )}
          </p>
        </div>
      {:else}
        <div class="documentdialog-content">
          {#if !loading}
            <div
              style="display:flex; justify-content: space-between;margin: 16px 24px 0 24px;"
            >
              <div class="find">
                <input
                  placeholder={$i18n(
                    "Trouver des participants",
                    "Find participants"
                  )}
                  on:input={onSearch}
                />
                <span class="material-icons">search</span>
              </div>
              <AppButton
                icon
                color="light"
                loading={loading_notification}
                on:click={onNotification}
              >
                notifications
                <span slot="text"
                  >{$i18n(
                    "Notifier les participants",
                    "Notify participants"
                  )}</span
                >
              </AppButton>
            </div>
            <table>
              <thead>
                <tr>
                  <th>{$i18n("Accès", "Access")}</th>
                  <th>{$i18n("Nom participant", "Participant name")}</th>
                  <th>Email</th>
                  <th>Date de notification</th>
                  <!-- <th>Notification</th> -->
                </tr>
              </thead>
              <tbody>
                {#each invests_filter as invest, i}
                  <tr>
                    <td>
                      <CheckButton
                        loading={invest.loading}
                        active={document.authorized &&
                          document.authorized.find((el) => el == invest.id)}
                        on:add={() => OnAdd(i)}
                        on:remove={() => OnRemove(i)}
                      />
                    </td>
                    <td>{invest.firstName} {invest.lastName}</td>
                    <td>{invest.email}</td>
                    {#if document.lastNotifications && document.lastNotifications[invest.id]}
                      <td>
                        {format(document.lastNotifications[invest.id])}
                      </td>
                      <!-- {:else if lastNotification && new Date(document.lastNotification).getTime() === new Date(lastNotification).getTime() && !document.lastNotifications[invest.id] && document.authorized.find((el) => el == invest.id)}
                      <td>{format(document.lastNotification)}</td> -->
                    {:else}
                      <td>-</td>
                    {/if}
                    <!-- <td
                      ><Checkbox
                        bind:value={test}
                        on:click={onNotification}
                      /></td
                    > -->
                  </tr>
                {/each}
              </tbody>
            </table>
          {:else}
            <div class:loading class="gray">
              <div><span><b /></span></div>
            </div>
          {/if}
        </div>
      {/if}
    </section>
  </main>
</Dialog>

<style lang="scss">
  .loading {
    p,
    .material-icons {
      color: transparent;
    }
    &.private {
      div {
        background: #eb5757;
      }
    }
    &.public {
      div {
        background: #2f80ed;
      }
    }
    &.gray {
      b {
        border: 2px solid #fff;
        border-color: rgb(4, 0, 255) transparent transparent transparent;
      }
    }
    div {
      display: block;
      width: 100%;
      height: 100%;
      /* background: rgba(0, 0, 0, 0.1); */
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      border-radius: 8px;
      span {
        display: inline-block;
        position: relative;
        width: 24px;
        height: 24px;
        b {
          box-sizing: border-box;
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          border: 2px solid #fff;
          border-radius: 50%;
          animation: rotate 800ms cubic-bezier(0.5, 0, 0.2, 0.8) infinite;
          border-color: #fff transparent transparent transparent;
        }
      }
    }
  }
  :global(.mdc-dialog.customdocdialog) {
    :global(.mdc-dialog__surface) {
      width: 90%;
      /* margin: 0; */
      max-width: 1204px;
    }
    :global(.mdc-dialog__container) {
      width: 100%;
    }
  }
  .documentdialog {
    height: 100%;
    border-radius: 8px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.11);
    font-family: "Lato";
    display: flex;
    .find {
      position: relative;
      input {
        height: 32px;
        border-radius: 4px;
        border: 1px solid #bdbdbd;
        padding-left: 12px;
        padding-right: 30px;
        background: #f2f2f2;
        outline: none;
        &:focus {
          border-color: #bdbdbd;
        }
      }
      .material-icons {
        position: relative;
        top: 8px;
        left: -33px;
        color: #e0e0e0;
      }
    }
    aside {
      width: 216px;
      padding: 24px;
      border-right: 1px solid #e0e0e0;
      h1 {
        color: #333333;
        font-size: 18px;
        font-weight: 900;
      }
      h2 {
        margin: 4px 0 14px 0;
        color: #4f4f4f;
        font-weight: 700;
        font-size: 12px;
        display: flex;
        /* align-items: center; */
        span {
          font-size: 13px;
          width: 14px;
          line-height: 10px;
        }
      }
      b {
        color: #bdbdbd;
        font-size: 12px;
        display: block;
        padding-bottom: 20px;
      }
      button {
        border-radius: 8px;
        border: 0;
        padding: 16px 14px;
        cursor: pointer;
        font-size: 13px;
        font-weight: 700;
        display: flex;
        align-items: center;
        position: relative;
        &.actions_button {
          /* padding: 12px; */
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          .material-icons {
            font-size: 18px;
            margin-right: 0;
            /* font-weight: bold; */
          }
          &.blue {
            background: #4a2fed;
            color: white;
          }
          &.red {
            background: #eb5757;
            color: white;
          }
        }
        &:hover {
          &::before {
            background: rgba(120, 120, 120, 0.07);
          }
        }
        &::before {
          content: "";
          transition: all 200ms ease;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: transparent;
          border-radius: 8px;
        }
        &.private {
          background-color: #ffe5e5;
          color: #eb5757;
        }
        &.public {
          color: #2f80ed;
          background: #e8e5ff;
        }
        &.unknown {
          width: 200px;
          height: 48px;
        }
        .material-icons {
          font-size: 20px;
          margin-right: 8px;
          /* font-weight: bold; */
        }
        p {
          position: relative;
          top: 1px;
        }
      }
    }
    &-sharelink {
      margin-top: 16px;
      background: white;
      position: relative;
      z-index: 9;
      margin-left: -24px;
      margin-right: -24px;
      min-width: 100%;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      height: 200px;
      overflow: auto;
    }
    &-actions {
      display: flex;
      margin-top: 16px;
      justify-content: space-between;
      position: relative;
      &_flex {
        display: flex;
        flex-direction: column;
        width: 88px;
        align-items: center;
        justify-content: center;
      }
      p {
        margin-top: 8px;
        font-size: 11px;
        font-weight: 600;
        &.red {
          color: #eb5757;
        }
        &.blue {
          color: #4a2fed;
        }
      }
      &_rename {
        position: absolute;
        padding: 12px 16px 24px 24px;
        box-sizing: border-box;
        z-index: 2;
        top: 0;
        bottom: 0;
        transition: left 300ms ease;
        left: calc(-100% - 50px);
        right: 0;
        background: $blue;
        height: calc(100% + 40px);
        width: calc(100% + 48px);
        transform: translate(-24px, -16px);
        &_input {
          display: flex;
          align-items: center;
          margin-top: 6px;
          input {
            caret-color: $blue;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            outline: none;
            padding: 10px;
            border: 0;
            /* border: #f2f2f2; */
            /* border: 2px solid #bdbdbd; */
          }
          button.rename {
            &::before {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
            opacity: 0.3;
            position: relative;
            transition: opacity 200ms ease;
            padding: 0;
            background: transparent;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border: thin solid;
            border-color: white;
            border-left: 0;
            &.active {
              opacity: 0.8;
            }
            span {
              margin-left: 8px;
              font-size: 16px;
              color: white;
            }
          }
        }
        &.on {
          left: 0;
        }
      }
    }
    section {
      width: calc(100% - 265px);
    }
    &-header {
      height: 63px;
      align-items: center;
      width: calc(100% - 48px);
      justify-content: space-between;
      padding: 0 24px;
      display: flex;
      border-bottom: 1px solid #e0e0e0;
      color: #828282;
      h1 {
        font-weight: 900;
      }
    }
    &-content {
      width: 100%;
      position: relative;
      height: calc(100% - 84px);
    }
    &-none {
      width: 100%;
      color: #bdbdbd;
      height: calc(100% - 64px);
      background: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: bold;
    }
    table {
      margin: 13px 24px 24px 24px;
      width: calc(100% - 48px);
      table-layout: fixed;
      thead {
        text-align: left;
        color: #828282;
      }
      thead {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 12px;
      }
      tbody {
        height: 268px;
        overflow: auto;
      }
      tbody,
      thead {
        font-size: 13px;
        overflow: auto;
        width: 100%;
        display: block;
        tr {
          display: flex;
          width: 100%;
          align-items: center;
        }
        th,
        td {
          min-width: 100px;
          text-overflow: ellipsis;
          display: block;
          padding: 8px 0;
          white-space: nowrap;
          overflow: hidden;
        }
        th:nth-child(1),
        td:nth-child(1) {
          min-width: 80px;
        }
        th:nth-child(2),
        td:nth-child(2) {
          width: calc(35% - 80px);
          min-width: 100px;
        }
        th:nth-child(3),
        td:nth-child(3) {
          width: calc(40% - 80px);
          min-width: 100px;
        }
        th:nth-child(4),
        td:nth-child(4) {
          width: calc(25% - 80px);
        }
      }
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
