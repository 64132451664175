<script>
  export let material_icon = undefined;
  export let style = {};
  export let color = "primary";
  const OPTIONS_LIST = [
    "primary",
    "secondary",
    "white",
    "danger",
    "warning",
    "progress",
    "disabled",
    "blue",
    "gradient",
  ];
  if (!OPTIONS_LIST.includes(color)) {
    throw new Error(
      `value of 'color' ('${color}') in <AppBadge/> is not valable`
    );
  }
</script>

<span class="c-appbadge--{color}" {style}>
  {#if typeof material_icon == "string"}
    <i class="material-icons">{material_icon}</i>
  {/if}
  <slot />
</span>

<style lang="scss">
  span {
    padding: 8px 12px;
    border-radius: 2px;
    font-family: "Lato";
    font-weight: 700;
    font-size: 12px;
    display: inline-block;
    i {
      font-size: 13px;
      margin-right: 4px;
      height: 100%;
      vertical-align: middle;
      margin-bottom: 2px;
      line-height: 15px;
    }
    &.c-appbadge {
      &--primary {
        color: #675aff;
        background: #e6e3ff;
      }
      &--progress {
        color: $progress;
        background: #ffe9d4;
      }
      &--white {
        background: $white;
        box-shadow: $bg-shadow;
      }
      &--danger {
        color: $danger;
        background: rgb(255, 193, 193);
      }
      &--gradient {
        color: $white;
        background: linear-gradient(
          142.64deg,
          #002ad0 -19.57%,
          #1100ff 31.34%,
          #8f01ff 110.35%
        );
      }
      &--disabled {
        color: #828282;
        background: #e0e0e0;
        padding: 8px 14px;
      }
    }
  }
</style>
