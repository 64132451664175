<script>
  import { onMount, onDestroy } from "svelte";
  import AppContent from "../../components/Layout/AppContent.svelte";
  import AppButton from "../../components/Button/AppButton.svelte";
  import AppText from "../../components/Text/AppText.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import Radio from "@smui/radio";
  import FormField from "@smui/form-field";
  import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
  import Input from "../../components/Form/Input.svelte";
  import AppSnackbar from "../../components/Snackbar/AppSnackbar.svelte";
  import { i18n } from "../../stores/i18n";
  import { id as bondId, clubdeal } from "../../stores/clubdeal";
  import API from "../../utils/api";

  export let decrement_step;
  export let increment_step;
  export let id;

  let other_file_dom;
  let documents = [];
  let snack;
  let snackSaved;
  let snackAlreadyExists;
  let loading = true;
  let btnAddLoading = false;

  onMount(async () => {
    try {
      loading = true;
      const data = await API.get("document", { bondId: $bondId });
      if (data) {
        documents = data.documents || [];
      }
    } finally {
      loading = false;
    }
  });

  const onInput = async (e) => {
    try {
      const file = e.target.files[0];
      btnAddLoading = true;
      snack.close();
      if (documents.find((d) => d.fileName == file.name)) {
        // prevent doubles
        setTimeout(() => {
          snackAlreadyExists.forceOpen();
        }, 100);
        return;
      }
      let newDoc = await API.post("document", {
        fileName: file.name,
        contentType: file.type,
        bondId: $bondId,
        access: "privateAccess",
      });
      try {
        await fetch(newDoc.url, { method: "PUT", body: file });
        documents.push(newDoc);
        documents = documents; // to trigger svelte reactivity
        // svelte bug: without timeout, snack is null
        setTimeout(() => {
          snackSaved.forceOpen();
        }, 100);
      } catch (err) {
        await API.del("document", { id: newDoc.id, bondId: $bondId });
        console.log(err);
        // svelte bug: without timeout, snack is null
        setTimeout(() => {
          snack.forceOpen();
        }, 100);
      }
    } catch (e) {
      console.log(e);
      // svelte bug: without timeout, snack is null
      setTimeout(() => {
        snack.forceOpen();
      }, 100);
    } finally {
      btnAddLoading = false;
    }
  };

  const openFile = () => {
    other_file_dom.click();
  };

  let options = [
    {
      name: ["oui", "yes"],
      value: true,
    },
    {
      name: ["non", "no"],
      value: false,
    },
  ];
  async function onDownload(doc) {
    try {
      let freshDoc = await API.get("document", {
        bondId: $bondId,
        id: doc.id,
      });
      let response = await fetch(freshDoc.url, { method: "GET" });
      // download file
      var newBlob = new Blob([await response.blob()], {
        type: freshDoc.contentType,
      });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = freshDoc.fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
      }, 100);
    } catch (e) {
      console.log(e);
    }
  }
  const onClose = async (index) => {
    let [doc] = documents.splice(index, 1);
    documents = documents; // to trigger svelte reactivity
    await API.del("document", { id: doc.id, bondId: $bondId });
    snackSaved.forceOpen();
  };
  const getFileBaseName = (doc) => {
    let name = doc.fileName;
    return name.substring(0, name.lastIndexOf(".")) || doc.fileName;
  };
  const getFileExtension = (doc) => {
    let name = doc.fileName;
    return name.substring(name.lastIndexOf(".")) || "";
  };
  const cell_style = `
	  text-align:center;
	  background: rgb(226, 226, 226);
	  color: rgb(180, 180, 180);
	  font-weight: 500;
	  font-family: "Lato";
	  font-weight: 700;`;
</script>

<AppContent type="advisor" title={$i18n("Documents", "Documents")} {loading}>
  <AppSnackbar
    timeoutMs={10000}
    color="danger"
    bind:value={snack}
    labelText={$i18n("Une erreur est survenue", "An error has occurred")}
  />
  <AppSnackbar
    timeoutMs={10000}
    color="danger"
    bind:value={snackAlreadyExists}
    labelText={$i18n(
      "Un document portant le même nom existe déjà",
      "A document with the same name already exists"
    )}
  />
  <AppText weight="700">
    {$i18n(
      "Téléchargez les documents que vous souhaitez mettre à disposition de vos participants:",
      "Download the documents you wish to make available to your participants:"
    )}
  </AppText>
  <Spacing y="12" />
  <DataTable class="table">
    <Head>
      <Row>
        <Cell>
          <AppText weight="700">
            {$i18n("Nom du fichier (modifiable)", "File name (editable)")}
          </AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n("Format", "Format")}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n("Retirer", "Remove")}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n("Télécharger", "Download")}</AppText>
        </Cell>
      </Row>
    </Head>
    <Body>
      {#if documents.length == 0}
        <Row>
          <Cell style={cell_style} colspan="4">
            <p>{$i18n("Aucun document ajouté", "No document added")}</p>
          </Cell>
        </Row>
      {:else}
        {#each documents as doc, index}
        <Row>
            <Cell>
              <Input
                value={getFileBaseName(doc)}
                onInput={(e) => {
                  doc.fileName = e.target.value + getFileExtension(doc);
                  API.put("document", doc);
                  return false;
                }}
                crop
              />
              <Spacing y="8" />
            </Cell>
            <Cell>
              <AppText weight="700">{doc.contentType}</AppText>
            </Cell>
            <Cell>
              <AppButton color="alert" icon on:click={() => onClose(index)}>
                close
              </AppButton>
            </Cell>
            <Cell>
              <AppButton icon color="light" on:click={() => onDownload(doc)}>
                file_download
              </AppButton>
            </Cell>
          </Row>
        {/each}
      {/if}
    </Body>
  </DataTable>
  <Spacing y="12" />
  <AppButton
    block
    on:click={openFile}
    loading={btnAddLoading}
    disabled={btnAddLoading}
  >
    {$i18n("Ajouter un autre document", "Add another document")}
  </AppButton>
  <input
    style="display:none;"
    type="file"
    on:input={onInput}
    bind:this={other_file_dom}
  />

  <Spacing y="12" />

  <div style="display:flex;justify-content:space-between;" slot="footer">
    <AppButton on:click={() => decrement_step()}>
      {$i18n("Précédent", "Previous")}
    </AppButton>
    <AppButton on:click={() => increment_step()}>
      {$i18n("Suivant", "Next")}
    </AppButton>
  </div>
  <AppSnackbar
    bind:value={snackSaved}
    labelText={$i18n(
      "Vos informations ont bien été enregistrées",
      "Your information has been saved"
    )}
  />
</AppContent>

<style lang="scss">
  .table {
    width: 100%;
  }
</style>
