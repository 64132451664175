<script>
  import { onMount, onDestroy } from "svelte";
  import { navigate } from "svelte-routing";
  import { i18n } from "../../stores/i18n";
  import { profile } from "../../stores/profile";
  import { LOGIN_PATH } from "../../constants";
  import { login } from "../../stores/index";
  import API from "../../utils/api";
  import Spacing from "../Spacing/Spacing.svelte";
  import Dialog, { Content } from "@smui/dialog";
  import AppText from "../Text/AppText.svelte";
  import FormField from "@smui/form-field";
  import Checkbox from "@smui/checkbox";
  import AppButton from "../Button/AppButton.svelte";
  export let dialog;
  let validation = false;
  let loading = false;
  let disconnect_loading = false;
  onMount(() => {
    if (dialog) {
      dialog.open();
    }
  });
  async function onCguValidate() {
    if (loading) return;
    loading = true;
    try {
      await API.put("profile", { termsSigned: new Date().getTime() });
    } catch (e) {
      console.error(e);
    } finally {
      loading = false;
      dialog.close();
    }
  }
  async function disconnect_user() {
    if (disconnect_loading) return;
    disconnect_loading = true;
    login.set(false);
    localStorage.removeItem("token");
    $profile = {};
    navigate(LOGIN_PATH, { replace: true });
    // window.location.reload();
  }
  onDestroy(() => {
    dialog.close();
  });
</script>

<Dialog
  escapeKeyAction=""
  scrimClickAction=""
  id="test"
  style="z-index:99999;"
  bind:this={dialog}
>
  <Content>
    <AppText color="blue" weight="bold" size="large">
      {$i18n(
        "Valider les Conditions Générales d'Utilisation",
        "Validate the Terms and Conditions of Use"
      )}
    </AppText>
    <Spacing y="12" divider divider_props={{ top: 2, bottom: 2 }} />
    <FormField>
      <Checkbox bind:checked={validation} input$required />
      <span slot="label">
        <b>
          {$i18n("Merci de valider les ", "Thanks to validate the ")}<a
            target="_blank"
            href={$i18n("/assets/terms-fr.pdf", "/assets/terms-en.pdf")}
          >
            {$i18n(
              "Conditions Générales d'Utilisation",
              "Terms and Conditions of Use"
            )}
          </a>
          {$i18n(
            "pour pouvoir accéder à votre espace",
            "in order to access your space"
          )}
        </b>
      </span>
    </FormField>
    <section style="margin-top: 12px;">
      <AppButton
        disabled={!validation || disconnect_loading}
        {loading}
        width="120px"
        on:click={onCguValidate}
      >
        Valider
      </AppButton>

      <AppButton
        width="120px"
        color="alert"
        disabled={loading}
        loading={disconnect_loading}
        on:click={disconnect_user}
      >
        Non
      </AppButton>
    </section>
  </Content>
</Dialog>

<style lang="scss">
  a {
    color: $blue;
    font-weight: bold;
  }
  section {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
</style>
