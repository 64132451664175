import { writable } from "svelte/store"

const default_id = null
const default_clubdeal = {
    id: "",
    state: "",
    company: {
        name: "",
        siret: "",
        legalForm: "",
        headquartersCountry: "",
        headquartersRegion: "",
    },
    amount: 1,
    rate: 0,
    time: 12,
    instruments: [],
    typology: "other",
    equityAmount: 0,
    frequency: "",
    souscriptions: [],
    nbSubscribers: 0,
    clubDealInvestors: [],
    signatureByFirmFunding: false,
    token: null,
}
function createClubdeal() {
    const { subscribe, set } = writable(default_clubdeal);
    return {
        entrypoint: "clubdeal",
        subscribe,
        set,
        reset: () => set(default_clubdeal),
    }
}
function createId() {
    let initial = localStorage.getItem("id") || default_id
    const { subscribe, set } = writable(initial);
    return {
        subscribe,
        set: (id) => {
            set(id)
            localStorage.setItem("id", id)
        }
    }
}

export const clubdeal = createClubdeal()
export const id = createId()
export const sync = writable({ running: false, timer: 0 })
