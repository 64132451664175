export const SortTostring = (array, property, reverse = false) => {
    const collator = new Intl.Collator('fr');
    return array.sort(function (x, y) {
        if (reverse) {
            return collator.compare(y[property], x[property]);
        }
        return collator.compare(x[property], y[property]);
    })
}

export const SortTotimestamp = (array, timestampProperty, reverse = false) => {
    return array.sort(function (x, y) {
        if (reverse) {
            return y[timestampProperty] - x[timestampProperty];
        }
        return x[timestampProperty] - y[timestampProperty];
    })
}

let current_cell = "";
export const SortStringCell = (documents, cellName, c) => {
    if (current_cell !== cellName) {
        const response = SortTostring(documents, cellName)
        current_cell = cellName;
        c(response, current_cell)
        return
    } else {
        const response = SortTostring(documents, current_cell, true)
        current_cell = current_cell + "reverse"
        c(response,current_cell)
        return
    }
}

export const SortTotimestampCell = (documents, cellName, c) => {
    if (current_cell !== cellName) {
        const response = SortTotimestamp(documents, cellName)
        current_cell = cellName;
        c(response, current_cell)
        return
    } else {
        const response = SortTotimestamp(documents, current_cell, true)
        current_cell = current_cell + "reverse"
        c(response,current_cell)
        return
    }
}