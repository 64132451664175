<script>
    import AppContent from "../components/Layout/AppContent.svelte";
    import AppText from "../components/Text/AppText.svelte";
    import Spacing from "../components/Spacing/Spacing.svelte";
    import AppButton from "../components/Button/AppButton.svelte";
    import { navigate } from "svelte-routing";
    import { i18n } from "../stores/i18n";
</script>

<AppContent>
    <div>
        <section>
            <AppText center size="xxl" color="blue" weight="900">
                {$i18n("Merci !", "Thank you !")}
            </AppText>
            <Spacing y="16" />
            <AppText weight="500">
                {$i18n(
                    `Merci d’avoir confirmé votre participation à la dataroom. Nous
                    l’avons bien prise en compte et nous en informons le Gestionnaire de
                    l’opération. Lorsque tous les participants invités à la dataroom
                    auront fait de même, vous serez contacté de façon à signer la
                    documentation et à procéder aux formalités de KYC. Vous pouvez à
                    tout moment vous connecter à votre espace personnel pour
                    retrouver les informations relatives à la dataroom. Nous restons
                    à votre disposition.`,
                    `Thank you for confirming your participation in the dataroom. We
                    have taken it into account and we inform the Board of Directors of our decision.
                    the operation. When all the participants invited to the dataroom
                    will have done the same, you will be contacted to sign the
                    documentation and to proceed with the formalities of KYC. You can at
                    at any time you can log in to your personal space to
                    find information about the dataroom. We stay
                    at your disposal.`
                )}
            </AppText>
            <Spacing y="8" />
            <img src={"/assets/mail-send.svg"} alt="icon" />
        </section>
        <AppButton icon size="big" block on:click={() => navigate("/dossiers")}>
            arrow_back
            <span slot="text">
                <AppText color="white"
                    >{$i18n(
                        "Retourner à mes datarooms",
                        "Back to my datarooms"
                    )}</AppText
                >
            </span>
        </AppButton>
    </div>
</AppContent>

<style lang="scss">
    div {
        width: 90%;
        padding: 40px 0;
        margin: 0 auto;
        height: calc(100vh - 160px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include respond-to("md") {
            width: 720px;
        }
        section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            img {
                width: 124px;
                margin: 0 auto;
            }
        }
    }
</style>
