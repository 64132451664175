<script>
  import { onMount } from "svelte";
  import { crossfade } from "svelte/transition";
  import SkeletonDirectory from "../../components/Skeleton/SkeletonDirectory.svelte";
  import { expoOut } from "svelte/easing";
  import { SortStringCell } from "../../utils/functions/Sort";
  import AppSnackbar from "../../components/Snackbar/AppSnackbar.svelte";
  import Arrow from "../../components/DataGrid/Arrow.svelte";
  import Loading from "../../components/Loading/Loading.svelte";
  import API from "../../utils/api";
  import Dialog, { Content } from "@smui/dialog";
  import DocumentDialog from "../../components/Dialog/DocumentDialog.svelte";
  import Input from "../../components/Form/Input.svelte";
  import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
  import AppText from "../../components/Text/AppText.svelte";
  import AppAlert from "../../components/Alert/AppAlert.svelte";
  import AppButton from "../../components/Button/AppButton.svelte";
  import { i18n } from "../../stores/i18n";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import IconButton from "@smui/icon-button";
  import Menu from "@smui/menu";
  import List from "@smui/list";
  import Checkbox from "@smui/checkbox";
  import FormField from '@smui/form-field';
  import ItemUser from "../../components/Item/ItemUser.svelte";
  import { Item } from "@smui/list";
  import SvelteTooltip from "svelte-tooltip";
  import ExpansionsPanels from "../../components/ExpansionsPanels/ExpansionsPanels.svelte";
  import FormPanelHeader from "../../components/ExpansionsPanels/FormPanelHeader.svelte";
  import FormPanelContent from "../../components/ExpansionsPanels/FormPanelContent.svelte";
  import Matrix from "../../components/Matrix.svelte";
  import menu from "@smui/menu";
  import { prevent_default } from "svelte/internal";
  import JSZip from "jszip";

  export let data;

  const [receive] = crossfade({
    duration: (d) => Math.sqrt(d * 200),

    fallback(node) {
      const style = getComputedStyle(node);
      const transform = style.transform === "none" ? "" : style.transform;
      return {
        duration: 600,
        easing: expoOut,
        css: (t) => `
  				transform: ${transform} scale(${t});
  				opacity: ${t}
  			`,
      };
    },
  });
  const options = {
    menu: undefined,
    loading: false,
  };

  function initFolders(apiFolders){
    let folders = apiFolders
      .filter((el) => el !== "/")
      .sort((a, b) => a.localeCompare(b))
      .map((el) => {
        return {
          name: el,
        };
      })

    let foldersMap = new Map();
    foldersMap.set("/", {
      name: "/",
      id: uid++,
      loading: false,
      files: [],
      children: [],
    })
    for(let f of folders){ // create the full hierarchy
      let names = f.name.split("/");
      let fullName = ""
      for(let name of names){
        if (!name) continue;
        fullName += name + "/"
        if (!foldersMap.get(fullName)){
          foldersMap.set(fullName, {
            name: fullName,
            id: uid++,
            loading: false,
            files: [],
            children: []
          })
        }
      }
    }
    for(let [name, folder] of foldersMap){
      if (name == "/") continue;
      let index = name.substring(0, name.length-1).lastIndexOf("/");
      let parentName = index == -1? "": name.substring(0, index);
      parentName += "/";
      let parent = foldersMap.get(parentName);
      parent.children.push(folder);
    }
    folders = foldersMap.get("/").children;
    return folders
  }

  async function reloadData(){
    try {
      const [res, res_2] = await Promise.all([
        API.get("document", { bondId: data.id }),
        API.get("folder", { bondId: data.id }),
      ]);

      documents = res.documents;
      documents = documents.map((el) => {
        return { ...el, ...options };
      });
      matrixDocuments = [...documents.sort(sortByFileName)];
      matrixInvestors = [...data.clubDealInvestors];
      folders = initFolders(res_2.folders)

      if (matrixUpdate) matrixUpdate(matrixDocuments, matrixInvestors);

      loadingPage = false;
    } catch (e) {
      console.error(e);
    }
  }

  onMount(reloadData);

  let file_dom;
  let folder_file_dom;
  let dialog = false;
  let dialog_folder = false;
  let btnAddLoading = false;
  let loadingPage = true;
  let loading_add_folder = false;
  let loading_public_dataroom = false;
  let loading_matrix = false;
  let uid = 0;
  let index = 0;
  let current_folder = {};
  let doc = {};
  let current_filter = "";
  let investors = [];
  let dialog_rename_folder;
  let dialog_public_dataroom;
  let dialog_matrix;
  let dialog_document_access_confirmation;
  let loading_update_document_access = false;
  let snack;
  let snack_error;
  let snack_color = "primary";
  let snack_msg = "";
  let dragover = false;
  // mysnack.forceOpen();
  // mysnack.close();
  let folders = [
    {
      id: uid++,
      name: "Documnent-1",
      files: [{ name: "test.pdf" }, { name: "te.pdf" }],
    },
    { id: uid++, name: "Documnent-2", files: [] },
  ];
  let folders_0;
  let folders_1;
  let folders_2;
  let newFolder = "";
  let currentFolder = "";
  // let id = 0;
  let documents = [];
  let matrixInvestors = [];
  let matrixDocuments = [];
  let menuList = [];
  const cell_style = `
  position:relative;

  `;

  let color = {
    increment: -1,
    next: function () {
      this.increment++;
    },
    previous: function () {
      this.increment--;
    },
    getCurrentPattern: function () {
      switch (this.increment) {
        case 0:
          return "blue";
        case 1:
          return "orange";
        case 2:
          return "green";
        default:
          this.increment = 0;
          return "blue";
      }
    },
    nextPattern: function () {
      this.next();
      return this.getCurrentPattern();
    },
  };

  function openSnack(msg) {
    snack_msg = msg;
    snack_color = "primary";
    snack.forceOpen();
  }
  function errorSnack(msg = "Une erreur est survenue, veuillez recommencer") {
    snack_msg = msg;
    snack_color = "danger";
    snack.forceOpen();
  }
  const getIndexBySpecificId = (id) => {
    switch (typeof id) {
      // if index
      case "number":
        return id;
      // if fileName
      case "string":
        return documents.findIndex((el) => el.fileName === id);
      // else: error
      default:
        throw Error("Object 'loading' on document note found");
    }
  };
  const add_folder = async () => {
    if (loading_add_folder) return;
    loading_add_folder = true;
    try {
      dialog_folder.close();

      if (current_folder && current_folder.name){
        newFolder = current_folder.name + newFolder;
      }
      await API.post("folder", { bondId: data.id, newFolder });

      // const res_2 = await API.get("folder", { bondId: data.id });
      // folders = initFolders(res_2.folders)
      await reloadData();

      newFolder = "";
      snack.forceOpen();
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        snack.close();
        snack_msg = "alert";
        snack_color = "danger";
        snack_error.forceOpen();
      }, 100);
    } finally {
      loading_add_folder = false;
    }
  };

  const onInput = async (e, path = "", folder = false) => {
    try {
      const file = e.target ? e.target.files[0] : e.detail ? e.detail : e;
      const fullName = path + file.name;
      if (documents.find((d) => d.fileName == fullName)) {
        // prevent doubles
        return;
      }

      folder
        ? (current_folder.loading = true)
        : (btnAddLoading = true);
      // snack.close();
      
      let newDoc = await API.post("document", {
        fileName: fullName,
        contentType: file.type,
        bondId: data.id,
        access: "publicAccess",
      });
      try {
        await fetch(newDoc.url, { method: "PUT", body: file });
        newDoc = {
          created: new Date(),
          ...newDoc,
          ...options,
          authorized: [],
          // authorized: [...data.clubDealInvestors.map((el) => el.id)], // docs are now privateAccess by default
        };
        documents.splice(0, 0, newDoc);
        documents = documents;

        await reloadData();

        // svelte bug: without timeout, snack is null
        setTimeout(() => {
          snack.forceOpen();
        }, 100);
      } catch (err) {
        await API.del("document", { id: newDoc.id, bondId: data.id });
        console.log(err);
        // svelte bug: without timeout, snack is null
        ErrorSnack();
        setTimeout(() => {
          snack_error.forceOpen();
        }, 100);
      }
    } catch (e) {
      console.log(e);
      // svelte bug: without timeout, snack is null
      setTimeout(() => {
        snack_error.forceOpen();
      }, 100);
    } finally {
      folder
        ? (current_folder.loading = false)
        : (btnAddLoading = false);
    }
  };
  const openDialog = (id) => {
    index = getIndexBySpecificId(id);
    doc = { ...documents[index] };
    dialog.open();
  };
  const openFile = () => {
    file_dom.click();
  };
  function getClubDealName(cd) {
    if (cd.name && cd.name.length) {
      return cd.name;
    }
    if (cd.company.name && cd.company.name.length) {
      return cd.company.name;
    }
    return "Inconnu";
  }
  const onDownloadFolder = async (folderName) => {
    if (folderName.endsWith("/")){
      folderName = folderName.substring(0, folderName.length-1);
    }
    let docs = documents.filter((d) => d.fileName.startsWith(folderName + "/"));
    var zip = new JSZip();
    for (let doc of docs) {
      let freshDoc = await API.get("document", {
        bondId: data.id,
        id: doc.id,
      });
      let response = await fetch(freshDoc.url, { method: "GET" });
      // download file
      var newBlob = new Blob([await response.blob()], {
        type: freshDoc.contentType,
      });
      zip.file(doc.fileName, newBlob);
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(
        content,
        `FirmClosing_${getClubDealName(data)}_${folderName}.zip`
      );
    });
  };
  const onDownload = async (doc) => {
    try {
      let freshDoc = await API.get("document", {
        bondId: data.id,
        id: doc.id,
      });
      let response = await fetch(freshDoc.url, { method: "GET" });
      // download file
      var newBlob = new Blob([await response.blob()], {
        type: freshDoc.contentType,
      });
      const mydata = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = mydata;
      link.download = freshDoc.fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(mydata);
        snack.forceOpen();
      }, 100);
    } catch (e) {
      console.log(e);
      snack_error.forceOpen();
    }
  };
  const onRename = async (response) => {
    const { index, fileName } = response;
    try {
      await API.put("document", { ...documents[index], fileName });
      documents[index].fileName = fileName;
      snack.forceOpen();
    } catch (e) {
      console.error(e);
      snack_error.forceOpen();
    }
  };
  const onRenameFolder = async () => {
    dialog_rename_folder.close();
    if (current_folder.loading) return;
    current_folder.loading = true;
    try {
      await API.put("folder", {
        folder: current_folder.name,
        newFolder: currentFolder,
        bondId: data.id,
      });
      documents = documents.map((el) =>
        el.fileName.indexOf(current_folder.name) == 0
          ? {
              ...el,
              fileName: el.fileName.replace(
                current_folder.name,
                currentFolder + "/"
              ),
            }
          : el
      );
      current_folder.name = currentFolder + "/";

      // const res_2 = await API.get("folder", { bondId: data.id });
      // folders = initFolders(res_2.folders)
      await reloadData();
    } catch (e) {
      console.error(e);
      snack_error.forceOpen();
    } finally {
      current_folder.loading = false;
      snack.forceOpen();
    }
  };
  const onPublicDataroom = async () => {
    dialog_public_dataroom.close();
    try {
      await API.post("documentMakeAllPublic", { bondId: data.id });
      await reloadData();
    } catch (e) {
      console.error(e);
      snack_error.forceOpen();
    } finally {
      snack.forceOpen();
    }
  };
  
  // Matrix
  let shouldNotifyInvestors = false;
  let matrixDiscardChanges;
  let matrixUpdate;
  let matrixGetDocumentsAccessSettings;
  const onMatrix = async () => {
    dialog_document_access_confirmation.open();
  };
  const applyDocumentsAccessRights = async () => {
    loading_update_document_access = true;
    try {
      await API.post("documentUpdateAccessRights", { bondId: data.id, shouldNotifyInvestors, docs: matrixGetDocumentsAccessSettings() });
      await reloadData();
    } catch (e) {
      console.error(e);
      snack_error.forceOpen();
    } finally {
      loading_update_document_access = false;
      dialog_matrix.close();
      dialog_document_access_confirmation.close();
      snack.forceOpen();
    }
    dialog_matrix.close();

  }
  const discardDocumentsAccessRights = async () => {
    dialog_matrix.close();
    dialog_document_access_confirmation.close();
  }

  const onDeleteFolder = async (folder) => {
    if (folder.loading) return;
    folder.loading = true;

    try {
      await API.del("folder", {
        folder: folder.name,
        bondId: data.id,
      });
      const folderName = folder.name;
      documents = documents.filter((el) => el.fileName !== folderName);
      
      // const res_2 = await API.get("folder", { bondId: data.id });
      // folders = initFolders(res_2.folders)
      await reloadData();
      
      color.previous();
      snack.forceOpen();
    } catch (e) {
      console.error(e);
      folder.loading = false;
      snack_error.forceOpen();
    }
  };
  const onDelete = async (id) => {
    if (documents[getIndexBySpecificId(id)].loading) return;
    documents[getIndexBySpecificId(id)].loading = true;
    try {
      await API.del("document", {
        id: documents[getIndexBySpecificId(id)].id,
        bondId: data.id,
      });
      snack.forceOpen();
    } catch (e) {
      console.error(e);
      snack_error.forceOpen();
      typeof id === "number"
        ? (documents[getIndexBySpecificId(id)].loading = false)
        : null;
    }
    documents = documents.filter(
      (el) => el.id !== documents[getIndexBySpecificId(id)].id
    ); // to trigger svelte reactivity
  };
  const investorName = (id) => {
    const find = data.clubDealInvestors.find((el) => el.id === id);
    if (!find) {
      return "";
    }
    return (
      (find.firstName ? find.firstName.charAt(0) : "") +
      (find.lastName ? find.lastName.charAt(0) : "")
    );
  };
  function isInFolder(path, folderPath){
     return path.includes(folderPath) && path.substring(folderPath.length).indexOf("/") == -1;
  }
  function isInFolderOrSubfolders(path, folderPath){
     return path.startsWith(folderPath);
  }
  async function changeFolder(context, folder) {
    //context = doc id
    const current_document = documents.find((el) => el.id === context.id);

    if (isInFolder(current_document.fileName, folder.name)) {
      return;
    }
    
    const name = current_document.fileName.slice(
      current_document.fileName.lastIndexOf("/") + 1
    );
    if (
      documents
        .filter((el) => isInFolder(el.fileName, folder.name))
        .some((el) => el.fileName.includes(name))
    ) {
      return;
    }
    try {
      const fileName = folder.name + name;
      await API.put("document", { ...current_document, fileName });
      documents = documents.map((el) => {
        if (el.id === context.id) {
          return { ...el, fileName };
        }
        return { ...el };
      });
    } catch (e) {
      console.error(e);
    }
  }
  let current_cell = "";
  function filter(cellName) {
    SortStringCell(documents, cellName, (r, newCell) => {
      documents = [...r];
      current_cell = newCell;
    });
  }

  $: invalide_newFolder =
    newFolder == "/" ||
    newFolder == "" ||
    folders.find(
      (el) =>
        el.name.toLowerCase() == newFolder.trim().toLowerCase() ||
        el.name.toLowerCase() == newFolder.trim().toLowerCase() + "/"
    );

  $: invalide_currentFolder =
    currentFolder == "/" ||
    currentFolder == "" ||
    folders.find(
      (el) =>
        el.name.toLowerCase() == currentFolder.trim().toLowerCase() ||
        el.name.toLowerCase() == currentFolder.trim().toLowerCase() + "/"
    );

  //to get reactif menu when I add or delete a file.
  let _refs = [];
  $: refs = _refs && _refs.filter(Boolean);

  let _ref_folder = [];
  $: refs_folder = _ref_folder && _ref_folder.filter(Boolean);

  function sortByFileName(a, b){
    let aIsFolder = a.fileName.indexOf("/") == -1;
    let bIsFolder = b.fileName.indexOf("/") == -1;
    if (aIsFolder && !bIsFolder){
      return 1;
    } else if (!aIsFolder && bIsFolder){
      return -1;
    } else {
      return a.fileName.localeCompare(b.fileName);
    }
  }
  
</script>

<input
  style="display:none;"
  type="file"
  on:input={onInput}
  bind:this={file_dom}
/>
<input
  style="display:none;"
  type="file"
  on:input={(e) => onInput(e, `${current_folder.name}`, true)}
  bind:this={folder_file_dom}
/>
<AppSnackbar bind:value={snack} color="primary">Action effectuée</AppSnackbar>
<AppSnackbar bind:value={snack_error} color="danger"
  >Une erreur s'est produite</AppSnackbar
>
<Dialog style="z-index:9999;" bind:this={dialog_folder}>
  <Content>
    <AppText color="blue" size="large" weight="bolder">
      {$i18n("Ajouter un répertoire", "Add a folder")}
    </AppText>
    <Spacing y="12" />
    <Input
      crop
      type="text"
      label={$i18n("Nom du répertoire", "Folder name")}
      bind:value={newFolder}
      on:enter={() => !invalide_newFolder && add_folder()}
    />
    <Spacing y="12" />
    <AppButton block disabled={invalide_newFolder} on:click={add_folder}>
      {$i18n("Valider", "Validate")}
    </AppButton>
  </Content>
</Dialog>
<Dialog style="z-index:9999;" bind:this={dialog_rename_folder}>
  <Content>
    <AppText color="blue" size="large" weight="bolder">
      {$i18n("Renommer le répertoire", "Rename the folder")}
      <b style="font-weight:bolder">
        "{current_folder?.name}"
      </b>
    </AppText>
    <Spacing y="12" />
    <Input
      crop
      label={$i18n("Nom du nouveau répertoire", "New folder name")}
      bind:value={currentFolder}
      on:enter={() => !invalide_currentFolder && onRenameFolder()}
    />
    <Spacing y="12" />
    <AppButton
      block
      bind:disabled={invalide_currentFolder}
      on:click={onRenameFolder}>{$i18n("Valider", "Validate")}</AppButton
    >
  </Content>
</Dialog>
<Dialog style="z-index:9999;" bind:this={dialog_public_dataroom}>
  <Content>
    <AppText color="blue" size="large" weight="bolder">
      {$i18n("Rendre tous les documents accessibles aux participants", "Make all documents accessible to participants")}
    </AppText>
    <Spacing y="12" />
    <AppButton
      block
      on:click={onPublicDataroom}>{$i18n("Valider", "Validate")}</AppButton
    >
  </Content>
</Dialog>
<Dialog id="matrixDialogElt" style="z-index:9999;" bind:this={dialog_matrix} fullscreen surface:style="width: 850px; max-width: calc(100vw - 32px);">
  <Content>
    <AppText color="blue" size="large" weight="bolder">
      {$i18n("Gérer l'accès des participants aux répertoires/documents", "Manage participant access to folders/documents")}
    </AppText>
    <Spacing y="12" />

    {#if matrixInvestors.length && matrixDocuments.length}
      <Matrix investors={matrixInvestors} documents={matrixDocuments} bind:discardChanges={matrixDiscardChanges} bind:matrixUpdate={matrixUpdate} bind:getDocumentsAccessSettings={matrixGetDocumentsAccessSettings}/> 
      <Spacing y="12" />
      
      <AppButton
        block
        on:click={onMatrix}>{$i18n("Valider", "Validate")}</AppButton
      >
    {:else}
      <AppText>
        {$i18n("Vous pourrez gérer les droits d'accès lorsque votre dataroom comportera à la fois des documents et des participants", "You can manage access rights when your dataroom contains both documents and participants")}
      </AppText>
    {/if}
  </Content>
</Dialog>

<Dialog
  style="z-index:10000; width: 100%"
  bind:this={dialog_document_access_confirmation}
  >
  <Content>
    <AppText color="blue" weight="900" size="large"
      >{$i18n("Confirmation", "Confirmation")}</AppText
    >
    <Spacing y="8" />
    <AppText size="medium"
      >{$i18n(
        "Êtes-vous sûr(e) de vouloir appliquer ces droits d'accès aux documents?",
        "Are you sure you want to apply these document access rights?"
      )}</AppText
    >
    <Spacing y="8" />
    
    <FormField>
      <Checkbox bind:checked={shouldNotifyInvestors} touch title={
        $i18n(
          "Vous pouvez aussi les notifier document par document via les options activables au niveau de chaque document.", 
          "You can also notify them document by document via the options that can be activated at the level of each document."
        )}/>
      <span slot="label" style="font-size: medium; color: #4F4F4F">
        {$i18n(
          "Je souhaite notifier les participants : ils recevront un email leur indiquant qu'ils ont à présent accès à ces documents.", 
          "I would like to notify participants: they will receive an email telling them that they now have access to these documents."
        )}
      </span>
    </FormField>
    <Spacing y="16" />

    <AppButton 
      on:click={applyDocumentsAccessRights}
      loading={loading_update_document_access}
      >{$i18n("Oui", "Yes")}</AppButton
    >
    <AppButton on:click={discardDocumentsAccessRights} customStyle="position: absolute; right: 20px"
      >{$i18n("Non", "No")}</AppButton
    >
  </Content>
</Dialog>

<DocumentDialog
  bind:dialog
  on:delete={async (event) => onDelete(event.detail.index)}
  on:rename={async (event) => onRename(event.detail)}
  {index}
  bondId={data.id}
  document={doc}
  loading={false}
  invests={data.clubDealInvestors}
  on:change={(event) => {
    const { authorized, index } = event.detail;

    documents[index].authorized = authorized;
  }}
/>

<!-- #TODO -->
<Spacing y="8" />
<AppAlert material_icon="error_outline" color="progress">
  {$i18n(
    "Créez ici vos répertoires, téléchargez vos documents et choisissez les options de partage avec les participants. Par défaut, tous les documents sont accessibles aux participants, vous pouvez gérer leurs accès individuels une fois que vous les avez invités à se connecter.",
    "Create your folders, upload your documents and choose the options for sharing them with participants. By default, all documents are accessible to participants, but you can manage their individual access once you've invited them to connect."
  )}
</AppAlert>

<Spacing y="16" />
<div class="flex">
  <AppText color="blue" size="large" weight="900"
    >{$i18n("Répertoires", "Folders")}</AppText
  >

  <!-- <AppButton
    disabled={loadingPage}
    loading={loading_public_dataroom}
    color="secondary"
    icon
    on:click={() => {
      current_folder = {};
      !loading_public_dataroom ? dialog_public_dataroom.open() : null;
    }}
  >
  lock_open
    <span slot="text">{$i18n("Rendre tous les documents accessibles aux participants", "Make all documents accessible to participants")}</span>
  </AppButton> -->
  
  <AppButton
    disabled={loadingPage}
    loading={loading_public_dataroom}
    color="secondary"
    icon
    on:click={() => {
      current_folder = {};
      if (!loading_matrix){
        matrixDiscardChanges();
        dialog_matrix.open();
      } 
    }}
  >
  lock_open
    <span slot="text">{$i18n("Gérer l'accès des documents aux participants", "Manage participant access to documents")}</span>
  </AppButton>

  <AppButton
    disabled={loadingPage}
    loading={loading_add_folder}
    color="secondary"
    icon
    on:click={() => {
      !loading_add_folder ? dialog_folder.open() : null;
    }}
  >
    add
    <span slot="text">{$i18n("Ajouter un répertoire", "Add a folder")}</span>
  </AppButton>
</div>
<Spacing y="8" divider divider_props={{ top: 8, bottom: 12 }} />
{#if !loadingPage}
  {#if !folders.length}
    <section
      style="width 100%;height: 86px;display:flex;align-items: center;justify-content:center; background: rgb(226, 226, 226);border-radius: 4px;margin-bottom:16px;"
    >
      <span
        class="material-icons"
        style="width:24px; opacity: 0.15;margin-right: 8px;margin-bottom: 4px;"
        >folderopen</span
      >
      <AppText color="gray" size="medium"
        >{$i18n("Aucun Répertoire", "No folder")}</AppText
      >
    </section>
  {:else}
    {#each folders as folder, i}
      <div in:receive={{ key: folder.id }}>
        <ExpansionsPanels
          on:drop-file={async (e) => {
            current_folder = folder;
            currentFolder = current_folder.name;
            folder.loading = true;
            if (e.detail.dataTransfer) {
              await changeFolder(
                JSON.parse(e.detail.dataTransfer.getData("context")),
                folder
              );
            } else {
              await onInput(e, `${folder.name}`, true);
            }
            folder.loading = false;
          }}
        >
          <FormPanelHeader
            size={documents.filter((el) => isInFolderOrSubfolders(el.fileName, folder.name)).length}
            name={folder.name.slice(0, -1)}
            on:rename={() => {
              current_folder = folder;
              currentFolder = current_folder.name;
              dialog_rename_folder.open();
            }}
            on:delete={() => onDeleteFolder(folder)}
            on:add={() => {
              current_folder = folder;
              currentFolder = current_folder.name;
              folder_file_dom.click();
            }}
            on:addFolder={() => {
              current_folder = folder;
              currentFolder = current_folder.name;
              dialog_folder.open()
            }}
            bind:loading={folder.loading}
            color={color.nextPattern()}
            subFolders={true}
            draggable={false}
            download={true}
            on:download={onDownloadFolder(folder.name)}
            >
            
            {#each documents.filter( (el) => el.fileName.includes(folder.name) && el.fileName.substring(folder.name.length).indexOf("/") == -1 ).sort(sortByFileName) as document}
              <FormPanelContent
                name={document.fileName.replace(folder.name, "")}
                created={document.created}
                on:dragstart={(e) => {
                  e.detail.dataTransfer.setData(
                    "context",
                    JSON.stringify({ id: document.id })
                  );
                }}
                on:download={() => onDownload(document)}
                on:open={() => openDialog(document.fileName)}
                on:delete={() => onDelete(document.fileName)}
                bind:loading={document.loading}
              />
            {/each}

            <!-- second level of folders -->
            {#each folder.children as folder2, i}
              <div in:receive={{ key: folder2.id }}>
                <ExpansionsPanels
                  on:drop-file={async (e) => {
                    current_folder = folder2;
                    currentFolder = current_folder.name;
                    folder2.loading = true;
                    if (e.detail.dataTransfer) {
                      await changeFolder(
                        JSON.parse(e.detail.dataTransfer.getData("context")),
                        folder2
                      );
                    } else {
                      await onInput(e, `${folder2.name}`, true);
                    }
                    folder2.loading = false;
                  }}
                  >
                  <FormPanelHeader
                    size={documents.filter((el) => isInFolderOrSubfolders(el.fileName, folder2.name)).length}
                    name={folder2.name.slice(0, -1).substring(folder.name.length)}
                    on:rename={() => {
                      current_folder = folder2;
                      currentFolder = current_folder.name;
                      dialog_rename_folder.open();
                    }}
                    on:delete={() => onDeleteFolder(folder2)}
                    on:add={() => {
                      current_folder = folder2;
                      currentFolder = current_folder.name;
                      folder_file_dom.click();
                    }}
                    bind:loading={folder2.loading}
                    color={color.nextPattern()}
                    draggable={false}
                    download={true}
                    on:download={onDownloadFolder(folder2.name)}
                    >
                    {#each documents.filter( (el) => el.fileName.includes(folder2.name) ).sort(sortByFileName) as document}
                      <FormPanelContent
                        name={document.fileName.replace(folder2.name, "")}
                        created={document.created}
                        on:dragstart={(e) => {
                          e.detail.dataTransfer.setData(
                            "context",
                            JSON.stringify({ id: document.id })
                          );
                        }}
                        on:download={() => onDownload(document)}
                        on:open={() => openDialog(document.fileName)}
                        on:delete={() => onDelete(document.fileName)}
                        bind:loading={document.loading}
                      />
                    {/each}
                  </FormPanelHeader>
                </ExpansionsPanels>
              </div>
            {/each}
          </FormPanelHeader>
        </ExpansionsPanels>
      </div>
    {/each}
  {/if}
{:else}
  <SkeletonDirectory />
{/if}

<Spacing y="24" />

<div class="flex">
  <AppText color="blue" size="large" weight="900"
    >{$i18n("Liste des documents (vous pouvez les glisser déposer dans les répertoires)", "Documents list (you can drag and drop them into the folders)")}</AppText
  >
  <AppButton
    color="secondary"
    icon
    on:click={openFile}
    loading={btnAddLoading}
    disabled={btnAddLoading}
  >
    add
    <span slot="text">{$i18n("Ajouter un document", "Add a document")}</span>
  </AppButton>
</div>

<Spacing y="8" divider divider_props={{ top: 8, bottom: 12 }} />

<DataTable
  class={`table ${dragover ? "dragover" : ""}`}
  style="width: 100%"
  on:dragover={(e) => {
    e.preventDefault();
    if (!dragover) {
      dragover = true;
    }
  }}
  on:dragleave={(e) => {
    e.preventDefault();
    dragover = false;
  }}
  on:drop={(e) => {
    e.preventDefault();
    dragover = false;
    for (const item of e.dataTransfer.items) {
      if (item.kind === "file") {
        onInput(item.getAsFile());
        continue;
      }
    }
  }}
>
  <Head>
    <Row>
      <Cell on:click={() => filter("fileName")} style="max-width: 35vw">
        <div style="display:flex;">
          <AppText weight="700">
            {$i18n("Nom du fichier (modifiable)", "File name (modifiable)")}
          </AppText>
          <Arrow
            position={current_cell === "fileName"
              ? "down"
              : current_cell.includes("fileName")
              ? "up"
              : null}
          />
        </div>
      </Cell>
      <!-- <Cell>
        <AppText weight="700">{$i18n("Format", "Format")}</AppText>
      </Cell> -->
      <Cell>
        <AppText weight="700">{$i18n("Destinataires", "Recipients")}</AppText>
      </Cell>
      <!-- <Cell>
        <AppText weight="700">{$i18n("Notification", "Notification")}</AppText>
      </Cell> -->
      <!-- <Cell>
        <AppText weight="700">{$i18n("Signature", "Signature")}</AppText>
      </Cell> -->

      <Cell>
        <AppText weight="700">{$i18n("Options", "Options")}</AppText>
      </Cell>
    </Row>
  </Head>
  <Body>
    <!-- {#if documents.length == 0}
      <Row>
        <Cell style={cell_style} colspan="4">
          <p>{$i18n("Aucun document ajouté", "No document added")}</p>
        </Cell>
      </Row>
    {:else} -->
    {#if !loadingPage}
      {#each documents.sort(sortByFileName) as document, i}
        <Row
          style="position:relative;"
          draggable="true"
          on:dragstart={(e) => {
            e.dataTransfer.setData(
              "context",
              JSON.stringify({ id: document.id })
            );
          }}
        >
          <Cell style="max-width: 35vw">
            <div class="flex not-space">
              {#if document.loading}
                <Loading
                  background="rgba(255,255,255,0.7)"
                  radius={0}
                  color="red"
                  size="32"
                />
              {/if}
              {#if document && document.authorized && document.authorized.length === 0}
                <span
                  style="font-size: 18px;width: 16px;opacity:0.15;"
                  class="material-icons">lock_open</span
                >
              {:else}
              <span
              style="font-size: 18px;width: 16px;opacity:0.15;"
                  class="material-icons">locks</span
                >
              {/if}
              {#if document.fileName.length > 100}
                <a style="padding:8px 16px" on:click={() => onDownload(document)} title={document.fileName}>
                  {document.fileName.substring(0, 100) + "..."}
                </a>
              {:else}
                <a style="padding:8px 16px" on:click={() => onDownload(document)}>
                  {document.fileName}
                </a>
              {/if}
            </div>
          </Cell>

          <!-- <Cell style={cell_style}>
          <div style="display:flex; align-items: center;">
            <div class="icon">TD</div>
            <div class="icon">TG</div>
            <div class="icon">TG</div>
            <div class="icon disabled">+5</div>
          </div>
        </Cell> -->
          <Cell style={cell_style}>
            <!-- <AppButton icon color="light" on:click={() => onDownload(document)}>
            file_download
          </AppButton> -->

            <div style="display:flex; align-items: center;">
              {#if document.authorized && document.authorized.length > 0}
                {#each document.authorized.filter((_, i) => i < 2) as id}
                  <div class="icon">{investorName(id)}</div>
                {/each}
                {#if document.authorized && document.authorized.length > 2}
                  <div class="icon">
                    +{document.authorized.length - 2}
                  </div>
                {/if}
              {:else if document.authorized && document.authorized.length === 0}
                <AppText weight="bold">{$i18n("Aucun", "None")}</AppText>
              {/if}
            </div>
          </Cell>
          <!-- <Cell style={cell_style}>
          <div style="display:flex; align-items: center;">
            <div class="icon">TD</div>
            <div class="icon">TG</div>
            <div class="icon">TG</div>
            <div class="icon disabled">+5</div>
          </div>
        </Cell> -->
          <Cell style={cell_style}>
            <IconButton
              class="material-icons"
              on:click={() => refs[i].setOpen(true)}
              ripple={false}
            >
              more_vert
            </IconButton>
            <div style="position:absolute;top:0;z-index:999;">
              <Menu bind:this={refs[i]}>
                <List>
                  <ItemUser on:click={() => openDialog(i)} icon="build">
                    {$i18n("Options", "Options")}
                  </ItemUser>
                  <Item>
                    <AppButton
                      on:click={() => {
                        refs[i].setOpen(false);
                        onDelete(i);
                      }}
                      block
                      color="alert">{$i18n("Supprimer", "Delete")}</AppButton
                    >
                  </Item>
                </List>
              </Menu>
            </div>
          </Cell>
        </Row>
      {/each}
    {/if}
    <!-- {/if} -->
  </Body>
</DataTable>

<Spacing y="36" />

<style lang="scss">
  :global(#matrixDialogElt .mdc-dialog__surface){
    max-width: 90vw !important;
  }

  :global(.table) {
    overflow: visible;
    transition: all 200ms ease;
    &.dragover {
      border: 2px dotted rgba(62, 10, 250, 0.5);
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: relative; */
  }
  .flex.not-space {
    justify-content: left;
  }
  :global(tr.mdc-data-table__row):hover {
    .icon {
      border: 2px solid #f5f5f5;
    }
  }
  :global(.mdc-data-table.table) {
    overflow: visible;
  }
  :global(.mdc-menu-surface.mdc-menu) {
    overflow: visible;
  }

  a {
    color: #2f80ed;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
  .icon {
    width: 28px;
    height: 28px;
    background: #461af9;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-family: "Lato";
    font-size: 11px;
    border-radius: 8px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    /* position: absolute;
    z-index: 999; */

    /* &:nth-of-type(1) {
      left: 0;
      z-index: 100;
    } */
    &:nth-of-type(2) {
      /* left: 26px;
      z-index: 99; */
      background: #863cff;
    }
    &:nth-of-type(3) {
      /* left: 52px;
      z-index: 98; */
      /* background: #1ee13d; */
      color: #858585;
      background: #e0e0e0;
    }

    /* &:nth-last-of-type(){ */
    /* left: 78px;
      z-index: 0; */
    /* color: #bdbdbd;
      background: #e0e0e0;
      font-weight: bold; */
    /* } */
  }
</style>
