// separated from "main.js" to avoid cyclic references (main => RouteAdvisor => main)
import RouteAdvisorPrestation from "./RouteAdvisorClubDeal/RouteAdvisorPrestation.svelte"
import RouteAdvisorInformationsSociety from "./RouteAdvisorClubDeal/RouteAdvisorInformationsSociety.svelte"
import RouteAdvisorCaracteristiques from "./RouteAdvisorClubDeal/RouteAdvisorCaracteristiques.svelte"
import RouteAdvisorInvestors from "./RouteAdvisorClubDeal/RouteAdvisorInvestors.svelte"
import RouteAdvisorDocuments from "./RouteAdvisorClubDeal/RouteAdvisorDocuments.svelte"
import RouteAdvisorValidation from "./RouteAdvisorClubDeal/RouteAdvisorValidation.svelte"
import * as Constants from "../constants"

export const advisor_clubdeal = [
    {
        path: Constants.ADVISOR_CLUBDEAL_INFORMATIONS_SOCIETY_PATH,
        component: RouteAdvisorInformationsSociety
    },
    {
        path: Constants.ADVISOR_CLUBDEAL_INFORMATIONS_CARACTERISTIQUES_PATH,
        component: RouteAdvisorCaracteristiques
    },
    {
        path: Constants.ADVISOR_CLUBDEAL_INFORMATIONS_INVESTORS_PATH,
        component: RouteAdvisorInvestors
    },
    {
        path: Constants.ADVISOR_CLUBDEAL_DOCUMENTS_PATH,
        component: RouteAdvisorDocuments
    },
    {
        path: Constants.ADVISOR_CLUBDEAL_VALIDATION_PATH,
        component: RouteAdvisorValidation
    },
    {
        path: Constants.ADVISOR_CLUBDEAL_PRESTATION_PATH,
        component: RouteAdvisorPrestation
    },
]
