<Item on:SMUI:action={onClick}>
    <div>
        <img {src} alt={name}/>
        <AppText capitalize>{name}</AppText>
    </div>
</Item>

<script >
    import { Item } from '@smui/list'
    import AppText from "../Text/AppText.svelte"
    
    export let src
    export let name
    export let onClick
</script>

<style lang="scss">
    div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 84px;
        img {
            height: 18px;
            width: 27px;
        }
    }
</style>