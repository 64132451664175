<script>
  import AppContent from "./AppContent.svelte";
  import AppText from "../Text/AppText.svelte";
  import Spacing from "../Spacing/Spacing.svelte";
  import { fade } from "svelte/transition";

  export let loading = false;
  export let src = undefined;
  export let title;
</script>

<AppContent>
  {#if !loading}
    <div transition:fade>
      <section>
        <AppText center size="xxl" color="blue" weight="900">{title}</AppText>
        <Spacing y="12" />
        <slot />
        {#if src}
          <Spacing y="24" />
          <img {src} alt="icon" />
        {/if}
      </section>
    </div>
  {:else}
    <div transition:fade class="loading">
      <img src="/assets/tail-spin.svg" alt="icon" />
    </div>
  {/if}
</AppContent>

<style lang="scss">
  div {
    width: 90%;
    padding: 16px 0;
    margin: 0 auto;
    height: calc(100vh - 113px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 200ms ease;
    overflow: hidden;
    @include respond-to("md") {
      width: 720px;
      padding: 40px 0;
      height: calc(100vh - 160px);
    }
    section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      img {
        width: 124px;
        margin: 0 auto;
      }
    }
  }
  div.loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 999;
    width: 100%;
    display: flex;
    height: calc(100% - 64px);
    img {
      width: 80px;
      height: 80px;
    }
  }
</style>
