import RouteAccount from "./RouteAccount.svelte"
import RouteClubDeal from "./RouteClubDeal.svelte"
import RouteClubDealInfo from "./RouteClubDealInfo.svelte"
import RouteKyc from "./RouteKyc.svelte"
import RouteLogin from "./RouteLogin.svelte"
import RouteAdvisor from "./RouteAdvisor.svelte"
import RouteAdvisorClubDeal from "./RouteAdvisorClubDeal/RouteAdvisor.svelte"
import RouteAdvisorClubDealDetail from "./RouteAdvisorClubDealDetail.svelte"
import RouteThankYou from "./RouteThankYou.svelte"
import RouteAdvisorThankYou from "./RouteAdvisorThankYou.svelte"
import RouteRegistration from "./RouteRegistration.svelte"
import RouteRegistrationThankYou from "./RouteRegistrationThankYou.svelte"
import RouteConfirmation from "./RouteConfirmation.svelte";
import RouteUnsubscribe from "./RouteUnsubscribe.svelte";
import RouteNewPassword from "./RouteNewPassword.svelte";
import RouteTest from "./RouteTest.svelte";
import * as Constants from "../constants"

export const global_routes = [
    {
        path: Constants.REGISTRATION_THANKYOU,
        component: RouteRegistrationThankYou
    },
    {
        path: Constants.UNSUBSCRIBE_PATH,
        component: RouteUnsubscribe,
        rules: /^\/unsubscribe\/.*$/gm
    },
    {
        path: Constants.REGISTRATION,
        component: RouteRegistration
    },
    {
        path: Constants.CONFIRMATION_PATH,
        component: RouteConfirmation,
        rules: /^\/confirmation\/.*$/gm
    },
    {
        path: Constants.LOGIN_PATH,
        component: RouteLogin
    },
    {
        path: Constants.NEW_PASSWORD_PATH,
        component: RouteNewPassword,
        rules: /^\/new-password\/.*$/gm
    },
    {
        path: Constants.TEST_PATH,
        component: RouteTest
    },
]
export const subscriber_routes = [
    ...global_routes,
    {
        path: Constants.CLUBDEAL_INFO_PATH,
        component: RouteClubDealInfo
    },
    {
        path: Constants.CLUBDEAL_PATH,
        component: RouteClubDeal
    },
    {
        path: Constants.ACCOUNT_PATH,
        component: RouteAccount
    },
    {
        path: Constants.KYC_PATH,
        component: RouteKyc
    },
    {
        path: Constants.SUBSCRIBER_THANKYOU_PATH,
        component: RouteThankYou
    }
]

export const advisor_routes = [
    ...global_routes,
    {
        path: Constants.ADVISOR_PATH,
        component: RouteAdvisor
    },
    {
        path: Constants.ADVISOR_CLUBDEAL_DETAIL_PATH,
        component: RouteAdvisorClubDealDetail
    },
    {
        path: Constants.ADVISOR_CLUBDEAL_PATH,
        component: RouteAdvisorClubDeal
    },
    {
        path: Constants.ADVISOR_THANKYOU_PATH,
        component: RouteAdvisorThankYou
    },
    {
        path: Constants.ACCOUNT_PATH,
        component: RouteAccount
    },
]