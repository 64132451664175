<script>
  import AppDocument from "../../components/Layout/AppDocument.svelte";
  import AppCard from "../../components/Card/AppCard.svelte";
  import AppFlex from "../../components/Layout/AppFlex.svelte";
  import API from "../../utils/api";
  import { i18n } from "../../stores/i18n";
  import { SortStringCell } from "../../utils/functions/Sort";
  import AppText from "../../components/Text/AppText.svelte";
  import Arrow from "../../components/DataGrid/Arrow.svelte";
  import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
  import ExpansionsPanels from "../../components/ExpansionsPanels/ExpansionsPanels.svelte";
  import FormPanelHeader from "../../components/ExpansionsPanels/FormPanelHeader.svelte";
  import FormPanelContent from "../../components/ExpansionsPanels/FormPanelContent.svelte";
  import JSZip from "jszip";
  import { onMount } from "svelte";
  export let clubDeal;
  export let documents = [];
  export let activeDocumentPath = null;

  onMount(async () => {
    // console.log("onMount: ", activeDocumentPath)
  });

  function getClubDealName(cd) {
    if (cd.name && cd.name.length) {
      return cd.name;
    }
    if (cd.company.name && cd.company.name.length) {
      return cd.company.name;
    }
    return "Inconnu";
  }

  function isInFolder(path, folderPath){
     return path.includes(folderPath) && path.substring(folderPath.length).indexOf("/") == -1;
  }
  function isInFolderOrSubfolders(path, folderPath){
     return path.startsWith(folderPath);
  }
  // $: protected_documents =
  //   documents && documents.length
  //     ? documents.filter((el) => el.access == "protectedAccess")
  //     : [];
  // $: public_documents =
  //   documents && documents.length
  //     ? documents.filter((el) => el.access == "publicAccess")
  //     : [];
  const onDownloadFolder = async (folderName) => {
    if (folderName.endsWith("/")){
      folderName = folderName.substring(0, folderName.length-1);
    }
    let docs = documents.filter((d) => d.fileName.startsWith(folderName + "/"));
    var zip = new JSZip();
    for (let doc of docs) {
      let freshDoc = await API.get("document", {
        bondId: clubDeal.id,
        id: doc.id,
      });
      let response = await fetch(freshDoc.url, { method: "GET" });
      // download file
      var newBlob = new Blob([await response.blob()], {
        type: freshDoc.contentType,
      });
      zip.file(doc.fileName, newBlob);
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(
        content,
        `FirmClosing_${getClubDealName(clubDeal)}_${folderName}.zip`
      );
    });
  };
  const onDownload = async (doc) => {
    try {
      let freshDoc = await API.get("document", {
        bondId: clubDeal.id,
        id: doc.id,
      });
      let response = await fetch(freshDoc.url, { method: "GET" });
      // download file
      var newBlob = new Blob([await response.blob()], {
        type: freshDoc.contentType,
      });
      const mydata = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = mydata;
      link.download = freshDoc.fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(mydata);
      }, 100);
    } catch (e) {
      console.log(e);
    }
  };
  let current_cell = "";

  function filter(cellName) {
    SortStringCell(documents, cellName, (r, newCell) => {
      documents = [...r];
      current_cell = newCell;
    });
  }
  function sortByFileName(a, b) {
    let aIsFolder = a.fileName.indexOf("/") == -1;
    let bIsFolder = b.fileName.indexOf("/") == -1;
    if (aIsFolder && !bIsFolder) {
      return 1;
    } else if (!aIsFolder && bIsFolder) {
      return -1;
    } else {
      return a.fileName.localeCompare(b.fileName);
    }
  }

  let folders = [
    ...new Set(
      documents
        .filter((d) => d.fileName.indexOf("/") > -1)
        .map((d) => d.fileName.substring(0, d.fileName.lastIndexOf("/")))
    ),
  ].sort((a, b) => a.localeCompare(b));

  function initFolders(apiFolders){
    let uid = 0;
    let folders = apiFolders
      .filter((el) => el !== "/")
      .sort((a, b) => a.localeCompare(b))
      .map((el) => {
        return {
          name: el,
        };
      })

    let foldersMap = new Map();
    foldersMap.set("/", {
      name: "/",
      id: uid++,
      loading: false,
      files: [],
      children: [],
    })
    for(let f of folders){ // create the full hierarchy
      let names = f.name.split("/");
      let fullName = ""
      for(let name of names){
        if (!name) continue;
        fullName += name + "/"
        if (!foldersMap.get(fullName)){
          foldersMap.set(fullName, {
            name: fullName,
            id: uid++,
            loading: false,
            files: [],
            children: []
          })
        }
      }
    }

    for(let [name, folder] of foldersMap){
      if (name == "/") continue;
      let index = name.substring(0, name.length-1).lastIndexOf("/");
      let parentName = index == -1? "": name.substring(0, index);
      parentName += "/";
      let parent = foldersMap.get(parentName);
      parent.children.push(folder);
    }
    folders = foldersMap.get("/").children;
    return folders
  }

  folders = initFolders(folders);

</script>

{#if documents.length}
  <!-- <AppCard
    title={$i18n(
      "Documents mis à disposition par le gestionnaire",
      "Documents made available by the manager"
    )}
    padding_bottom="0"
  >
    <AppFlex width_child="148px" margin_right="8">
      {#each public_documents as doc}
        <AppDocument title={doc.fileName} on:click={() => onDownload(doc)} />
      {/each}
    </AppFlex>
  </AppCard> -->

  <DataTable class="table" style="width: 100%; margin: auto; overflow-y: clip">
    <Head>
      <Row>
        <Cell on:click={() => filter("fileName")}>
          <div style="display:flex;">
            <AppText weight="900" color="blue" size="medium">
              {$i18n(
                "Documents mis à disposition par le gestionnaire",
                "Documents made available by the manager"
              )}
            </AppText>
            <Arrow
              position={current_cell === "fileName"
                ? "down"
                : current_cell.includes("fileName")
                ? "up"
                : null}
            />
          </div>
        </Cell>
      </Row>
    </Head>
    <Body>
      {#each documents
        .filter((d) => d.fileName.indexOf("/") == -1)
        .sort(sortByFileName) as document}
        <Row style="position:relative;">
          <Cell>
            <div class="flex not-space">
              {#if document.fileName.length > 70}
                <a style="padding:8px 16px" on:click={(e) => { e.preventDefault(); onDownload(document); }} title={document.fileName}>
                  {document.fileName.substring(0, 70) + "..."}
                </a>
              {:else}
                <a style="padding:8px 16px" on:click={(e) => { e.preventDefault(); onDownload(document); }}>
                  {document.fileName}
                </a>
              {/if}
            </div>
          </Cell>
        </Row>
      {/each}

      {#each folders as folder}
        <div>
          <ExpansionsPanels>
            <FormPanelHeader
              name={folder.name.slice(0, -1)}
              size={documents.filter((d) => isInFolderOrSubfolders(d.fileName, folder.name)).length}
              readonly={true}
              download={true}
              open={activeDocumentPath && activeDocumentPath.startsWith(folder.name)}
              on:download={onDownloadFolder(folder.name)}
            >
            {#each documents
              .filter((d) => isInFolder(d.fileName, folder.name))
              .sort(sortByFileName) as document}

              {#if document.fileName.replace(folder.name, "").length > 70}
                <FormPanelContent
                  name={document.fileName.replace(folder.name, "").substring(0, 70) + "..."}
                  created={document.created}
                  on:download={() => onDownload(document)}
                  bind:loading={document.loading}
                  readonly="true"
                />
              {:else}
                <FormPanelContent
                  name={document.fileName.replace(folder.name, "")}
                  created={document.created}
                  on:download={() => onDownload(document)}
                  bind:loading={document.loading}
                  readonly="true"
                />
              {/if}
            
            {/each}
            
            {#each folder.children as folder2}
                <div>
                  <ExpansionsPanels>
                    <FormPanelHeader
                      name={folder2.name.replace(folder.name, "").slice(0, -1)}
                      size={documents.filter((d) => isInFolderOrSubfolders(d.fileName, folder2.name)).length}
                      readonly={true}
                      download={true}
                      open={activeDocumentPath && activeDocumentPath.startsWith(folder2.name)}
                      on:download={onDownloadFolder(folder2.name)}
                    >
                      {#each documents
                        .filter((d) => isInFolder(d.fileName, folder2.name))
                        .sort(sortByFileName) as document}

                        {#if document.fileName.replace(folder2.name, "").length > 70}
                          <FormPanelContent
                            name={document.fileName.replace(folder2.name, "").substring(0, 70) + "..."}
                            created={document.created}
                            on:download={() => onDownload(document)}
                            bind:loading={document.loading}
                            readonly="true"
                          />
                        {:else}
                          <FormPanelContent
                            name={document.fileName.replace(folder2.name, "")}
                            created={document.created}
                            on:download={() => onDownload(document)}
                            bind:loading={document.loading}
                            readonly="true"
                          />
                        {/if}
                        
                      {/each}
                    </FormPanelHeader>
                  </ExpansionsPanels>
                </div>
              {/each}
            </FormPanelHeader>
          </ExpansionsPanels>
        </div>
      {/each}

      <!-- {#each protected_documents.sort(sortByFileName) as document, i}
        <Row style="position:relative;">
          <Cell>
            <div class="flex not-space">
              <a
                style="padding:8px 16px"
                href=""
                on:click={(e) => {
                  e.preventDefault();
                  onDownload(document);
                }}
              >
                {document.fileName}
              </a>
            </div>
          </Cell>
        </Row>
      {/each}
      {#each public_documents.sort(sortByFileName) as document, i}
        <Row style="position:relative;">
          <Cell>
            <div class="flex not-space">
              <a
                style="padding:8px 16px"
                href=""
                on:click={(e) => {
                  e.preventDefault();
                  onDownload(document);
                }}
              >
                {document.fileName}
              </a>
            </div>
          </Cell>
        </Row>
      {/each} -->
    </Body>
  </DataTable>
{/if}

<style lang="scss">
  a {
    color: #2f80ed;
    font-size: 14px;
    text-decoration: none;
    // text-decoration: underline;
    cursor: pointer;
  }
</style>
