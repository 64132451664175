<script>
    import AppContent from "../components/Layout/AppContent.svelte";
    import AppText from "../components/Text/AppText.svelte";
    import Spacing from "../components/Spacing/Spacing.svelte";
    import AppButton from "../components/Button/AppButton.svelte";
    import { navigate } from "svelte-routing";
    import { i18n } from "../stores/i18n";
</script>

<AppContent>
    <div>
        <section>
            <AppText center size="xxl" color="blue" weight="900">
                {$i18n("Merci !", "Thank you !")}
            </AppText>
            <Spacing y="16" />
            <AppText weight="500">
                {$i18n(
                    `Merci d’avoir créé une dataroom.`,
                    `Thank you for creating a dataroom.`
                )}
            </AppText>
            <Spacing y="8" />
            <AppText weight="500">
                {$i18n(
                    `Vous pouvez à présent accéder à votre dataroom, ajouter les participants et les documents en vous connectant à votre espace personnel.`,
                    `You can now access your dataroom, add participants and documents by logging on to your personal space.`
                )}
            </AppText>
            <Spacing y="8" />
            <AppText weight="500">
                {$i18n(
                    `Vous bénéficiez d'une semaine d'accès gratuit, il faudra ensuite régler en ligne par carte bancaire un montant de 450€ HT pour 4 semaines d'accès à votre dataroom en illimité (nombre de participants, nombre et taille de fichiers téléchargés, etc.). Après ces 4 semaines, vous pourrez, si vous le souhaitez, prolonger l'accès à votre dataroom pour 100€ HT par mois supplémentaire (paiement sécurisé par carte bancaire également depuis votre espace client).`,
                    `You'll get one week's free access, after which you'll have to pay €450 (excl. VAT) online by credit card for 4 weeks' unlimited access to your dataroom (number of participants, number and size of files downloaded, etc.). After these 4 weeks, if you wish, you can extend access to your dataroom for an additional €100 (excl. VAT) per month (secure payment by credit card, also from your customer area).`
                )}
            </AppText>
            <Spacing y="8" />
            <AppText weight="500">
                {$i18n(
                    `A bientôt!`,
                    `See you soon!`
                )}
            </AppText>
            <Spacing y="8" />
            <img src={"/assets/mail-send.svg"} alt="icon" />
        </section>
        <AppButton icon size="big" block on:click={() => navigate("/manager")}>
            arrow_back
            <span slot="text">
                <AppText color="white"
                    >{$i18n(
                        "Retourner à mes datarooms",
                        "Back to my datarooms"
                    )}</AppText
                >
            </span>
        </AppButton>
    </div>
</AppContent>

<style lang="scss">
    div {
        width: 90%;
        padding: 40px 0;
        margin: 0 auto;
        height: calc(100vh - 160px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include respond-to("md") {
            width: 720px;
        }
        section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            img {
                width: 124px;
                margin: 0 auto;
            }
        }
    }
</style>
