<script>
  import AppContent from "../components/Layout/AppContent.svelte";
  import { onMount } from "svelte";
  import { profile } from "../stores/profile";
  import { navigate } from "svelte-routing";
  import CardClubDeal from "../components/Card/CardClubDeal.svelte";
  import AppAlert from "../components/Alert/AppAlert.svelte";
  import API from "../utils/api";
  import Spacing from "../components/Spacing/Spacing.svelte";
  import { i18n } from "../stores/i18n";
  let clubDeals = [];
  let loading = true;
  onMount(async () => {
    try {
      const response = await API.get("clubdeal");
      clubDeals = response.clubDeals;
      loading = false;
    } catch (e) {
      console.error(e);
    }
  });
</script>

<AppContent {loading} title="{$i18n("Mes datarooms", "My datarooms")}">
  {#if $profile.kycState === "inProgress"}
    <Spacing x="32">
      <AppAlert color="progress"
      
        >{$i18n("Vous êtes invité à réaliser votre KYC dans la rubrique", "You are invited to perform your KYC in the section")}
        <b> « {$i18n("Mon Compte / Mon KYC", "My Account / Mon KYC")} »</b>
        </AppAlert
      >
    </Spacing>
    <Spacing y="12" />
  {/if}

  <article>
    {#each clubDeals as clubdeal}
      <div>
        <!-- <CardClubDeal
          id={clubdeal.id}
          state={clubdeal.state}
          rate={clubdeal.rate}
          duration={clubdeal.maturity}
          companyName={clubdeal.company.name}
          instrument={clubdeal.instrument}
          amount={clubdeal.amount}
          subscriptionAmount={clubdeal.subscriptionAmount}
          nbSubscribers={clubdeal.nbSubscribers}
          on:click={() =>
            navigate(`/dossiers/${clubdeal.id}`)}
        /> -->
        <CardClubDeal
          id={clubdeal.id}
          companyName={clubdeal.company.name}
          instrument={clubdeal.instrument}
          subscriptionAmount={clubdeal.subscriptionAmount}
          advisor={`${clubdeal.advisor.firstName || ""} ${clubdeal.advisor.lastName || ""}`}
          on:click={() => navigate(`/dossiers/${clubdeal.id}`)}
        />
      </div>
    {/each}
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </article>
</AppContent>

<style lang="scss">
  article {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 4px 24px 0 24px;
    @include respond-to("lg") {
      justify-content: space-around;
    }
    padding-bottom: 12px;
    div {
      margin-bottom: 8px;
      width: 100%;
      @include respond-to("sm") {
        width: calc(100% - 8px);
      }
      @include respond-to("md") {
        width: calc(50% - 8px);
      }
      @include respond-to("lg") {
        width: 396px;
      }
    }
  }
</style>
