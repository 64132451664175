<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  /**
   * @props {string: primary} - color
   * - primary
   * - secondary
   * - light
   * - alert
   */
  export let color = "primary";
  const TYPES_LIST = ["primary", "secondary", "light", "alert"];
  if (!TYPES_LIST.includes(color)) {
    throw new Error(
      `props 'color' equal to '${color}' in <AppButton> is undefined, must be 'primary' or 'alert'`
    );
  }
  /**
   * @props {string: normal} - size
   * - small
   * - normal
   * - big
   */
  export let size = "normal";
  const SIZES_LIST = ["small", "normal", "big"];
  if (!SIZES_LIST.includes(size)) {
    throw new Error(
      `props 'size' equal to '${size}' in <AppButton> is undefined, must be 'normal' or 'big'`
    );
  }
  export let icon = false;
  export let loading = false;
  export let error = false;
  export let block = false;
  export let disabled = false;
  export let round = false;
  export let width = "auto";
  export let error_message = "";
  export let customStyle = "";

  const onClick = () => {
    if (!disabled) {
      dispatch("click");
    }
  };

  const display = block ? "block" : "normal";
  const width_style = block ? "100%" : width;
  const style = `width: ${width_style}; display: ${display}; ${customStyle}`;
</script>

<button
  on:click|stopPropagation={onClick}
  {style}
  class="c-appbutton c-appbutton--{color} {size}"
  class:error
  class:loading
  class:disabled
  class:round
  class:icon
>
  {#if loading}
    <div><span><b /></span></div>
  {/if}
  {#if !icon}
    {#if error && error_message !== ""}
      <span>{error_message}</span>
    {:else}
      <slot />
    {/if}
  {:else}
    <main>
      <i class="material-icons"><slot /></i>
      {#if $$slots.text}
        <aside>
          <slot name="text" />
        </aside>
      {/if}
    </main>
  {/if}
</button>

<style lang="scss">
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  button {
    outline: 0;
    cursor: pointer;
    font-family: "Lato";
    border-radius: $border-radius_button;
    transition: 200ms ease all;
    border: 0;
    position: relative;
    &.big {
      border-radius: 4px;
    }
    &.loading {
      div {
        display: block;
        width: 100%;
        height: 100%;
        background: $danger;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        span {
          display: inline-block;
          position: relative;
          width: 24px;
          height: 24px;
          b {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border: 2px solid #fff;
            border-radius: 50%;
            animation: rotate 800ms cubic-bezier(0.5, 0, 0.2, 0.8) infinite;
            border-color: #fff transparent transparent transparent;
          }
        }
      }
    }
    &.icon {
      div {
        span {
          width: 14px;
          height: 14px;
        }
      }
    }
    &.round {
      border-radius: 50%;
    }
    &.icon {
      border: 0;
      opacity: 1;
      display: flex;
      align-items: center;
      i {
        font-size: 16px;
      }
    }
    &.c-appbutton {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &--primary {
        color: $white;
        background-color: $blue;
        position: relative;
        overflow: hidden;
        &.normal {
          padding: 10px 16px;
          &.round {
            padding: 10px;
          }
        }
        &.small {
          padding: 8px;
        }
        &.big {
          font-size: 16px;
          padding: 11px 16px;
          &.round {
            padding: 12px;
          }
          &.icon {
            i {
              font-size: 15px;
            }
          }
        }
        &:hover {
          box-shadow: 0px 2px 8px 0px rgba(146, 157, 255, 0.6);
        }
        &.error {
          background-color: $danger;
        }

        &.loading {
          div {
            background: $blue;
          }
        }
        &.disabled {
          color: rgb(143, 143, 143);
          background-color: rgb(209, 209, 209);
          cursor: default;
          box-shadow: none;
        }
      }
      &--secondary {
        display: block;
        width: 100%;
        padding: 16px;
        background-color: $blue_light;
        color: $blue;
        font-weight: bold;
        &.disabled {
          background: $gray;
          color: $gray_dark;
          cursor: default;
          &:hover {
            box-shadow: none;
          }
        }
        &.loading {
          div {
            background: $blue_light;
          }
          b {
            border-color: $blue transparent transparent transparent;
          }
        }
        // border: thin solid $blue;
        &.normal {
          padding: 10px 16px;
        }
        &.big {
          padding: 12px 16px;
          .round {
            padding: 12px 12px;
          }
        }
        &:hover {
          box-shadow: 0px 2px 8px 0px rgba(146, 157, 255, 0.2);
        }
      }
      &--light {
        width: 100%;
        padding: 16px;
        background-color: transparent;
        font-weight: bold;
        border: 1px solid;
        border-radius: 3px;
        color: $blue;
        border-color: $blue;
        &.loading {
          div {
            background: white;
          }
          b {
            border-color: $blue transparent transparent transparent;
          }
        }
        &.icon {
          align-items: center;
          display: flex;
          padding: 7px;
        }
        &.round {
          border-radius: 50%;
          width: auto;
          border-color: transparent;
          transition: all 200ms ease;
          &:hover {
            background: rgb(243, 243, 243);
            color: $black;
          }
        }
        &.small {
          padding: 8px;
        }

        &.big {
          padding: 12px 16px;
          &.round {
            padding: 12px 12px;
          }
        }
      }
      &--alert {
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: rgb(255, 194, 194);
        color: $danger;
        font-weight: bold;
        &.disabled {
          color: rgb(143, 143, 143);
          background-color: rgb(209, 209, 209);
          cursor: default;
          box-shadow: none;
        }
        &.normal {
          padding: 10px 16px;
        }
        &.big {
          padding: 12px 16px;
        }
        &.loading {
          div {
            background: $danger;
          }
        }
        &.icon {
          align-items: center;
          display: flex;
          padding: 7px;
          border: 0;
          color: $white;
          opacity: 0.9;
          background-color: $danger;
        }
        &:hover {
          box-shadow: 0px 2px 4px 0px rgba(255, 183, 183, 0.4);
          opacity: 0.8;
        }
      }
    }
    main {
      display: flex;
      align-items: center;
      justify-content: center;
      aside {
        margin-left: 8px;
      }
    }
  }
</style>
