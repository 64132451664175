import { writable, derived } from "svelte/store"
import {profile} from "./profile"

const fake_data = [
    {
        type:"Cession",
        id: "1",
        rate: 2.3,
        duration: 32,
        amount: 21.09,
        product: "Obligataire convertible",
        companyName: "BMW France",
        category: "Cession majoritaire",
        country: "Auvergne-Rhône-Alpes",
        business: "Industrie",
        ca: "20",
        ebitda:"5",
        advancement: "refund"
    },
    {
        type:"Financement",
        id: "2",
        rate: 10.2,
        duration: 76,
        amount: 2.4,
        companyName: "BMW France",
        product: "Mix equity/obligataire",
        category: "Cession majoritaire",
        country: "Auvergne-Rhône-Alpes",
        business: "Industrie",
        ca: "20",
        ebitda:"5",
        color: "primary",
        advancement: "done"
    },
    {
        id: "3",
        rate: 5,
        duration: 24,
        amount: 12.2,
        product: "Obligataire",
        companyName: "FirmFunding",
        category: "Cession majoritaire",
        country: "Auvergne-Rhône-Alpes",
        business: "Industrie",
        ca: "10",
        ebitda:"5",
        advancement: "subscription"
    },
    {
        id: "4",
        rate: 9.9,
        duration: 36,
        amount: 13.2,
        product: "Obligataire",
        companyName: "FirmFunding",
        category: "Cession majoritaire",
        country: "Auvergne-Rhône-Alpes",
        business: "Industrie",
        ca: "10",
        ebitda:"5",
        advancement: "subscription"
    }
]
function createData(){
    const { subscribe, set, update } = writable(fake_data);
    return {
        subscribe,
        init: (arr)   => update(state => [...state,...arr] ),
        toggleFavById: (id) => update(state => {
            return state.map(el=> {
                if(el.id === id){
                    return {...el,fav: !el.fav}
                }
                return el
            })
        })
        // ,
        // reset: ()    => set([])
    }
}

export const data = createData()
export const favorites = derived(
    [data,profile],
    ([$data,$profile]) => $profile.favorites.length !== 0 ? $data.filter(el=> $profile.favorites.includes(el.id)) : []
)
