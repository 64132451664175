<script>
  import AppContent from "../../components/Layout/AppContent.svelte";
  import AppAlert from "../../components/Alert/AppAlert.svelte";
  import AppText from "../../components/Text/AppText.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import AppIcon from "../../components/Img/AppIcon.svelte";
  import AppButton from "../../components/Button/AppButton.svelte";
  import API from "../../utils/api";
  import { i18n } from "../../stores/i18n";
  import { id, clubdeal } from "../../stores/clubdeal";
  export let increment_step;
  let loading = false;
  const onClick = async () => {
    if (!$id) {
      try {
        loading = true;
        clubdeal.set(await API.post("clubdeal", {}));
        $id = $clubdeal.id;
      } catch (e) {
        clubdeal.reset();
      } finally {
        loading = false;
      }
    }
    increment_step();
  };
</script>

<style lang="scss">
  main {
    display: block;
    width: 100%;
    @include respond-to("md") {
      width: 672px;
    }
    margin: 0 auto;
    padding-bottom: 12px;
    header {
      padding: 16px 24px 16px 24px;
      background: $blue;
      border-top-right-radius: $border-radius_normal;
      border-top-left-radius: $border-radius_normal;
    }
    section {
      padding: $spacing_normal;
      border-left: 1px solid $gray;
      border-bottom: 1px solid $gray;
      border-right: 1px solid $gray;
      border-bottom-right-radius: $border-radius_normal;
      border-bottom-left-radius: $border-radius_normal;
      div {
        display: flex;
        align-items: center;
        &.top {
          align-items: flex-start;
          margin-top: 7px;
          margin-bottom: 7px;
        }
        &.column {
          flex-direction: column;
          align-items: start;
        }
        ul {
          display: block;
          width: 95%;
          margin: 0 auto;
          li {
            list-style-type: "- ";
            font-family: "Lato";
            color: $blue;
            padding-left: 8px;
            margin-left: 8px;
          }
        }
      }
    }
  }
</style>

<AppContent type="advisor" title={$i18n('Notre prestation', 'Our service')}>
  <AppAlert material_icon="error_outline" size="medium">
    {$i18n(`Vous pouvez modifier les informations renseignées, les documents
      téléchargés, ou arrêter le processus pour le reprendre plus tard. Votre
      dataroom sera sauvegardé dans votre espace`, `You can modify the information filled in, the documents
      downloaded, or stop the process and resume it later. Your
      dataroom will be saved in your space`)}
    <b>{$i18n(`« en préparation »`, `« in preparation »`)}</b>.
  </AppAlert>
  <Spacing y="24" />
  <main>
    <header>
      <AppText color="white" size="large">
        {$i18n(`Gestion documentaire`, `Document management`)}
      </AppText>
    </header>
    <section>
      <div>
        <AppIcon material_icon="check" size="large" flat />
        <AppText weight="500" size="medium">
          {$i18n(`Création de votre espace dataroom`, `Creation of your dataroom space`)}
        </AppText>
      </div>
      <div>
        <AppIcon material_icon="check" size="large" flat />
        <AppText weight="500" size="medium">
          {$i18n(`Sélection des participants`, `Selection of participants`)}
        </AppText>
      </div>
      <div>
        <AppIcon material_icon="check" size="large" flat />
        <AppText weight="500" size="medium">
          {$i18n(`Dépôt et mise à disposition de la documentation à l'attention des participants`, `Filing and provision of documentation for the attention of participants`)}
        </AppText>
      </div>
      <div>
        <AppIcon material_icon="check" size="large" flat />
        <AppText weight="500" size="medium">
          {$i18n(`Détermination des droits d'accès des participants aux documents`, `Determining participants' access rights to documents`)}
        </AppText>
      </div>
      <!-- <div>
        <AppIcon material_icon="check" size="large" flat />
        <AppText weight="500" size="medium">
          {$i18n(`Signature électronique`, `Electronic signature`)}
        </AppText>
      </div> -->
    </section>
    <!-- <Spacing y="12" />
    <header>
      <AppText color="white" size="medium" weight="500">
        2./
          {$i18n(`Gestion des flux financiers`, `Management of financial flows`)}
      </AppText>
    </header>
    <section>
      <div>
        <AppIcon material_icon="check" size="large" flat />
        <AppText weight="500">
          {$i18n(`KYC des participants aux opérations de paiement`, `KYC of payment transaction participants`)}
        </AppText>
      </div>
      <div>
        <AppIcon material_icon="check" size="large" flat />
        <AppText weight="500">
          {$i18n(`Réception et répartition des flux financiers entre les participants`, `Receipt and distribution of financial flows between participants`)}
        </AppText>
      </div>
      <div>
        <AppIcon material_icon="check" size="large" flat />
        <AppText weight="500">
          {$i18n(`Possibilité de gérer la rémunération des intermédiaires à l'opération`, `Possibility to manage the remuneration of intermediaries on a transaction basis`)}
        </AppText>
      </div>
      <div>
        <AppIcon material_icon="check" size="large" flat />
        <AppText weight="500">
          {$i18n(`Réalisation des contrôles obligatoires, afférents à la justification et au montant des paiements réalisés`, `Carrying out mandatory checks on the justification and amount of payments made`)}
        </AppText>
      </div>
    </section> -->
    <Spacing y="24" />
    <AppButton {loading} block on:click={onClick} size="big">
      {$i18n(`Continuer`, `Continue`)}
    </AppButton>
  </main>
</AppContent>
