<script>
  import AppText from "../Text/AppText.svelte";
  import Spacing from "../Spacing/Spacing.svelte";

  export let label;
  export let no_diviser = false;
</script>

<main>
  <div>
    <AppText weight="bold">{label}</AppText>
    <AppText><slot /></AppText>
  </div>
  {#if !no_diviser}
    <Spacing y="12" divider />
  {/if}
</main>

<style lang="scss">
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
