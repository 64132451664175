<script>
  import Spacing from "../Spacing/Spacing.svelte";
  import AppText from "../Text/AppText.svelte";
  import { fade } from "svelte/transition";
  export let loading = false;
  export let title;
  export let type = "subscriber";
  $: divider = type === "subscriber";
</script>

<aside in:fade>
  {#if !loading}
    <aside transition:fade|local={{ delay: 500 }}>
      <div class={type}>
        {#if title}
          <span class="title">
            <AppText size="xl" weight="900" color="blue">{title}</AppText>
          </span>
          <Spacing  y="12" divider />
        {/if}
        <section>
          <main>
            <slot />
          </main>
          {#if $$slots.footer}
            <main style="margin: 12px 0;">
              <slot name="footer" />
            </main>
          {/if}
        </section>
      </div>
    </aside>
  {:else}
    <figure transition:fade|local>
      <img src="/assets/tail-spin.svg" />
    </figure>
  {/if}
</aside>

<style lang="scss">
  .title{
    margin-bottom: 8px;
    @include respond-to("md") {
      margin-bottom: 10px;
    }
  }
  figure {
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 72px;
    }
  }
  div {
    margin-top: 64px;
    min-height: calc(100vh - 80px);
    display: block;
    padding-top: 16px;
    overflow: hidden;
    span {
      display: block;
      margin: 0;
      padding-left: 32px;
      padding-right: 32px;
    }
    &.advisor {
      margin-top: 0;
      padding-top: 0;
      min-height: calc(100vh - 96px);
      span {
        padding-left: 0;
        padding-right: 0;
      }
      section {
        margin: 0 auto;
        /* padding: 12px; */
        /* margin-left: auto; */
        /* width:calc(100% - 24px); */
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 164px);
        justify-content: space-between;
      }
    }
  }
</style>
