import { writable } from "svelte/store"
// add session or cookie here
function createLogin(){
    let initial = localStorage.getItem("token") != null // + TODO: check token with an API request
    const { subscribe, set } = writable(initial)
    return {
        subscribe,
        set: (bool) => {
            set(bool)
            if (!bool){
                localStorage.removeItem("token")
            }
        }
    }
}

export const login = createLogin()