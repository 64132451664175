<script>
  import { createEventDispatcher } from "svelte";

  export let type = "primary";
  export let disabled;
  const dispatch = createEventDispatcher();
  const onClick = () => dispatch("click");
</script>

<button on:click={onClick} class="c-applink--{type}" {disabled}>
  <slot />
</button>

<style lang="scss">
  button {
    font-family: "Lato";
    margin: 0;
    padding: 0;
    background: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    outline: 0;
    cursor: pointer;
    &.c-applink {
      &--primary {
        border-bottom: thin solid $blue;
        color: $blue;
        &:disabled,
        &[disabled] {
          cursor: default;
          color: gray;
          border-bottom: thin solid gray;
        }
      }
      &--secondary {
        color: $color_primary;
        border-bottom: 0;
        font-weight: 500;
        transition: opacity 200ms ease;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
</style>
