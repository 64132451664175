import { writable, derived } from "svelte/store"
import { ADVISOR_PATH, CLUBDEAL_PATH } from "../constants"
import { i18n } from "./i18n"

const fake_profile = {
    afiteMember: null,
    type: null, //subscriber or advisor
    billingAddressCity: null,
    billingAddressCountry: null,
    billingAddressNumberAndStreet: null,
    billingAddressPostalCode: null,
    company: null,
    confirmed: null,
    created: null,
    email: null,
    termsSigned: null,
    favorites: null,
    firstName: null,
    language: null,
    lastName: null,
    lastSignin: null,
    lastSigning: null,
    kycUrl: null,
    kycState: null,
    kycPdfUrl: null,
    // ndaNotSigned: null,
    phone: null,
    turnover: null,
    validated: null,
    doubleAuthentification: null
}

function createProfile() {
    const { subscribe, update, set } = writable(fake_profile);
    return {
        subscribe,
        toggle: () => update(state => state.type === "subscriber" ? { ...state, type: "advisor" } : { ...state, type: "subscriber" }),
        setType: (type) => update(state => { return { ...state, type } }),
        set: (el) => set(el)
    }
}

export const profile = createProfile()
export const favorites_id = derived(
    profile,
    $profile => $profile.type
)
export const type = derived(
    profile,
    $profile => $profile.type || false
)

export const username = derived(
    profile,
    $profile => $profile.firstName && $profile.lastName ? $profile.firstName + " " + $profile.lastName : "Inconnu"
)
const ko = () => { throw new Error('type is undefined') }

export const profile_links = derived(
    [profile, i18n],
    ([$profile, $i18n]) => {
        const subscribers_links = [
            {
                to: CLUBDEAL_PATH,
                text: $i18n("Mes datarooms", "My datarooms")
            }
        ]

        const advisor_links = [
            {
                to: ADVISOR_PATH,
                text: $i18n("Mes datarooms", "My datarooms")
            }
        ]
        return $profile?.type === "subscriber" || $profile?.type === "notQualifiedSubscriber" ? subscribers_links :
            $profile?.type === "advisor" ? advisor_links :
                []
    }
)
export const profile_default_path = derived(
    profile,
    $profile => {
        return $profile.type === "subscriber" || $profile.type === "notQualifiedSubscriber" ? CLUBDEAL_PATH :
            $profile.type === "advisor" ? ADVISOR_PATH :
                "/login"
    }
)
