<div>
    <Link getProps="{getProps}" {to}><slot/></Link>
</div>
<script>
export let to
import { Link } from "svelte-routing"

const getProps = ({ href, isPartiallyCurrent, isCurrent }) =>{
// const isActive = href === "/" ? isCurrent : isPartiallyCurrent || isCurrent
const isActive = href === "/" ? false : isCurrent

// The object returned here is spread on the anchor element's attributes
if (isActive) {
    return { class: "active" }
}
return {}
}
</script>

<style lang="scss">
div {
    height: 100%;
    &> :global(a) {
        text-decoration: none;
        display:block;
        color:$blue;
        height: calc(100% - 2px);
        display: flex;
        align-items: center;
        padding:$spacingX_small;
        font-family: "Lato";
        transition: all ease 300ms;
        padding: 10px;
        border-radius: 3px;
        &:hover {
            background: rgba(41, 23, 255, 0.04);
        }
        &.active {
            background: rgba(41, 23, 255, 0.12);
        }
    }    
}
</style>