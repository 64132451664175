

<button on:click={disconnectEvent}><slot/></button>
<script>
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();
function disconnectEvent() {
    dispatch('click');
}
</script>
<style lang="scss">
button {
    display: block;
    width: 100%;
    border: none;
    background-color: $danger;
    color: $white;
    outline: none;
    cursor: pointer;
    padding: $spacing_small;
    border-radius: 3px;
}
</style>