<script>
  import IconButton from "@smui/icon-button";
  import Menu from "@smui/menu";
  import List from "@smui/list";
  import ItemUser from "../Item/ItemUser.svelte";
  import AppButton from "../Button/AppButton.svelte";
  import { Item } from "@smui/list";
  import { createEventDispatcher } from "svelte";
  import Loading from "../Loading/Loading.svelte";
  import { i18n } from "../../stores/i18n";

  const dispatch = createEventDispatcher();

  export let name = "Documents";
  export let size = 0;
  export let color = "blue";
  export let loading = false;
  export let readonly = false;
  export let download = false;
  export let subFolders = false;
  export let draggable = true;
  let wrapper;
  let menu;
  export let open = false;

  let scroll;
  const checkNodes = () => {
    setTimeout(() => {
      scroll = wrapper.childNodes.length > 5;
    }, 20);
  };

  // if I haven't childs, then the panel will not open;
  const onDelete = () => {
    menu.setOpen(false);
    dispatch("delete");
  };

  const onDownload = () => {
    menu.setOpen(false);
    dispatch("download");
  };

  const onClick = () => {
    // I always have a child in my node

    if (wrapper.childNodes?.length < 2) {
      if (open) {
        open = false;
      }
      return;
    }
    open = !open;
    // checkNodes()
  };

  const setMenu = () => {
    menu.setOpen(true);
  };
  $: {
    if (wrapper) {
      checkNodes();
    }
  }
</script>

<section class="formpanelheader">
  <div class="header" class:open on:click={onClick}>
    <div class="material" class:open>
      <div class={`material-main ${color}`}>
        {#if loading}
          <Loading size="20" {color} />
        {:else}
          <span class="material-icons">folder</span>
        {/if}
      </div>
      <div class="material-main">
        <span class="material-icons">keyboard_arrow_down</span>
      </div>
    </div>
    <div class="header-main" class:open>
      <p class="header-title" draggable>{name}</p>
      <div class="header-informations">
        <p>{$i18n("Nombre de fichiers", "Number of files")}</p>
        <p>{size}</p>
      </div>
    </div>
    <div class="option">
      <span on:click|stopPropagation={setMenu}>
        <IconButton class="material-icons gray" ripple={false}>
          more_horiz
        </IconButton>
      </span>
      <div>
        <Menu bind:this={menu}>
          <List>
            {#if !readonly}
              <ItemUser
                on:click={async () => {
                  if (loading) return;
                  await dispatch("add");
                  checkNodes();
                }}
                icon="add"
              >
                {$i18n("Ajouter un fichier", "Add file")}
              </ItemUser>
              {#if subFolders}
                <ItemUser
                  on:click={async () => {
                    if (loading) return;
                    await dispatch("addFolder");
                    checkNodes();
                  }}
                  icon="add"
                  >
                  {$i18n("Ajouter un répertoire", "Add folder")}
                </ItemUser>
              {/if}
              <ItemUser on:click={() => dispatch("rename")} icon="edit">
                {$i18n("Renommer", "Rename")}
              </ItemUser>
              {#if download}
                <ItemUser on:click={onDownload} icon="download">
                  {$i18n("Télécharger", "Download")}
                </ItemUser>
              {/if}
              <Item>
                <AppButton block color="alert" on:click={onDelete}>{$i18n("Supprimer", "Delete")}</AppButton>
              </Item>
            {:else}
              {#if download}
                <ItemUser on:click={onDownload} icon="download">
                  {$i18n("Télécharger", "Download")}
                </ItemUser>
              {/if}
            {/if}
      </List>
        </Menu>
      </div>
    </div>
  </div>
</section>
<section class={`wrapper`} class:scroll class:open>
  <section class="content" bind:this={wrapper}>
    <slot />
  </section>
</section>

<style lang="scss">
  :global(.mdc-menu-surface--open) {
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    left: initial !important;
    bottom: initial !important;
  }
  .option {
    position: absolute;
    top: -4px;
    right: 13px;
    color: #bdbdbd;
  }
  .header {
    display: flex;
    font-family: "Lato";
    align-items: center;
    cursor: pointer;
    padding-bottom: 0;
    transition: padding 200ms ease;
    border-bottom: 1px solid transparent;
    position: relative;
    &.open {
      padding-bottom: 20px;
      border-bottom: 1px solid #f2f2f2;
    }
    &-main {
      //width: calc(90% - 37px);
      padding-left: 16px;
      height: 37px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-informations {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #bdbdbd;
      width: 124px;
      font-weight: 600;
    }
    &-title {
      font-size: 18px;
      color: #333333;
      font-weight: 900;
    }
  }
  section {
    .material {
      position: relative;
      width: 37px;
      height: 37px;
      &.open {
        .material-main:first-of-type {
          opacity: 0;
        }
        .material-main:last-of-type {
          transform: rotate(0deg);
        }
      }
      &:hover {
        .material-main:first-of-type {
          opacity: 0;
          border-radius: 50%;
        }
        .material-main:last-of-type {
          background: rgba(180, 180, 180, 0.3);
        }
      }
    }
    .material-main {
      width: 37px;
      height: 37px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      &:first-of-type {
        transition: all 300ms ease;
        span {
          font-size: 20px;
        }
        z-index: 2;
      }
      &:last-of-type {
        color: #828282;
        background: rgba(180, 180, 180, 0.2);
        z-index: 1;
        border-radius: 50%;
        transition: all 200ms ease-out;
        transform: rotate(-180deg);
        font-size: 24px;
      }
      /* &.loading {
        border-radius: 50%;
      } */
      &.blue {
        color: #0500ff;
        background: #e1e0ff;
        border: 1px solid transparent;
      }
      &.orange {
        color: #ff7800;
        background: #fff3e0;
      }
      &.green {
        color: #02ed36;
        background: #e1ffe0;
      }
    }
    &.wrapper {
      position: relative;
      max-height: 0;
      opacity: 0;
      //width: calc(90% + 16px);
      // overflow-y: auto;
      // overflow-y: hidden;
      // width: 80%;
      transition: opacity 400ms ease, max-height 400ms ease;
      //padding-right: 170px;
      // margin-right: 170px;
      &.open {
        opacity: 1;
        max-height: fit-content;
        // max-height: 340px;
        //overflow-y: visible;
        &.scroll {
          // overflow-y: auto;
          width: calc(100% + 16px);
          //padding-bottom: 108px;
        }
      }
      .content {
        height: 100%;
      }
    }
    &.content {
      width: 100%;
    }
  }
</style>
