export const LOGO_PATH = "/assets/logo/logo-fr.svg"
export const LOGIN_PATH = "/login"
export const NEW_PASSWORD_PATH = "/new-password/*id"

export const TEST_PATH = "/test"

export const ACCESS_PATH = "/access"
export const ACCOUNT_PATH = "/account"
export const REGISTRATION = "/registration"
export const REGISTRATION_THANKYOU = "/registration/thankyou"
export const CLUBDEAL_PATH = "/dossiers"
export const CLUBDEAL_INFO_PATH = "/dossiers/:id"
export const KYC_PATH = "/kyc"
export const CONFIRMATION_PATH = "/confirmation/*id"
export const UNSUBSCRIBE_PATH = "/unsubscribe/*id"

export const SUBSCRIBER_THANKYOU_PATH = "/participant/thankyou"

export const ADVISOR_PATH = "/manager"
export const ADVISOR_THANKYOU_PATH = "/manager/thankyou"
export const ADVISOR_CLUBDEAL_DETAIL_PATH = "/manager/:id"
export const ADVISOR_CLUBDEAL_PATH = "/manager/dossier/*"
export const ADVISOR_CLUBDEAL_PRESTATION_PATH = "prestation/*id"
export const ADVISOR_CLUBDEAL_INFORMATIONS_SOCIETY_PATH = "informations/society/*id"
export const ADVISOR_CLUBDEAL_INFORMATIONS_CARACTERISTIQUES_PATH = "informations/caracteristiques/*id"
export const ADVISOR_CLUBDEAL_INFORMATIONS_INVESTORS_PATH = "informations/participants/*id"
export const ADVISOR_CLUBDEAL_DOCUMENTS_PATH = "documents/*id"
export const ADVISOR_CLUBDEAL_VALIDATION_PATH = "validation/*id"

export const REGION_LIST = [
    {
        value: "auvergneRhoneAlpes",
        label: "Auvergne-Rhône-Alpes"
    },
    {
        value: "bourgogneFrancheComte",
        label: "Bourgogne-Franche-Comté"
    },
    {
        value: "bretagne",
        label: "Bretagne"
    },
    {
        value: "centreValDeLoire",
        label: "Centre-Val de Loire"
    },
    {
        value: "grandEst",
        label: "Grand Est"
    },
    {
        value: "hautsDeFrance",
        label: "Hauts-de-France"
    },
    {
        value: "ileDeFrance",
        label: "Île-de-France"
    },
    {
        value: "normandie",
        label: "Normandie"
    },
    {
        value: "nouvelleAquitaine",
        label: "Nouvelle-Aquitaine"
    },
    {
        value: "occitanie",
        label: "Occitanie"
    },
    {
        value: "paysDeLaLoire",
        label: "Pays de la Loire"
    },
    {
        value: "provenceAlpesCoteDAzur",
        label: "Provence-Alpes-Côte d'Azur"
    },
    {
        value: "corse",
        label: "Corse"
    },
    {
        value: "guadeloupe",
        label: "Guadeloupe"
    },
    {
        value: "guyane",
        label: "Guyane"
    },
    {
        value: "martinique",
        label: "Martinique"
    },
    {
        value: "laReunion",
        label: "La Réunion"
    },
    {
        value: "mayotte",
        label: "Mayotte"
    }
]

export const COUNTRY_LIST = [
    {
        value: "austria",
        label: ["Autriche", "Austria"]
    },
    {
        value: "belgium",
        label: ["Belgique", "Belgium"]
    },
    {
        value: "bulgaria",
        label: ["Bulgarie", "Bulgaria"]
    },
    {
        value: "croatia",
        label: ["Croatie", "Croatia"]
    },
    {
        value: "canada",
        label: ["Canada", "Canada"]
    },
    {
        value: "cyprus",
        label: ["Chypre", "Cyprus"]
    },
    {
        value: "czechia",
        label: ["République tchèque", "Czechia"]
    },
    {
        value: "denmark",
        label: ["Danemark", "Denmark"]
    },
    {
        value: "estonia",
        label: ["Estonie", "Estonia"]
    },
    {
        value: "unitedStates",
        label: ["États-Unis", "United States"]
    },
    {
        value: "finland",
        label: ["Finlande", "Finland"]
    },
    {
        value: "France",
        label: ["France", "France"]
    },
    {
        value: "germany",
        label: ["Allemagne", "Germany"]
    },
    {
        value: "greece",
        label: ["Grèce", "Greece"]
    },
    {
        value: "hungary",
        label: ["Hongrie", "Hungary"]
    },
    {
        value: "ireland",
        label: ["Irlande", "ireland"]
    },
    {
        value: "iceland",
        label: ["Islande", "Iceland"]
    },
    {
        value: "italy",
        label: ["Italie", "Italy"]
    },
    {
        value: "latvia",
        label: ["Lettonie", "Latvia"]
    },
    {
        value: "lithuania",
        label: ["Lithuanie", "Lithuania"]
    },
    {
        value: "luxembourg",
        label: ["Luxembourg", "Luxembourg"]
    },
    {
        value: "malta",
        label: ["Malte", "Malta"]
    },
    {
        value: "monaco",
        label: ["Monaco", "Monaco"]
    },
    {
        value: "norway",
        label: ["Norvège", "Norway"]
    },
    {
        value: "netherlands",
        label: ["Pays-Bas", "Netherlands"]
    },
    {
        value: "poland",
        label: ["Pologne", "Poland"]
    },
    {
        value: "portugal",
        label: ["Portugal", "Portugal"]
    },
    {
        value: "romania",
        label: ["Roumanie", "Romania"]
    },
    {
        value: "slovakia",
        label: ["Slovaquie", "Slovakia"]
    },
    {
        value: "slovenia",
        label: ["Slovénie", "Slovenia"]
    },
    {
        value: "spain",
        label: ["Espagne", "Spain"]
    },
    {
        value: "sweden",
        label: ["Suède", "Sweden"]
    },
    {
        value: "unitedKingdom",
        label: ["Royaume-Uni", "United Kingdom"]
    },
    {
        value: "switzerland",
        label: ["Suisse", "Switzerland"]
    },
]

export const INSTRUMENTS_LISTS = [
    {
        value: "equity",
        label: ["Equity", "Equity"]
    },
    {
        value: "bond",
        label: ["Obligation sèche", "Bond"]
    },
    {
        value: "convertibleBond",
        label: ["Obligation convertible", "Convertible bond"]
    },
    {
        value: "bondEquityMix",
        label: ["Mix equity / obligataire", "Mix equity / bond"]
    },
]
export const TYPOLOGIES_LISTS = [
    {
        value: "capitalIncrease",
        label: ["Augmentation de capital", "Capital increase"]
    },
    {
        value: "securitiesTransfer",
        label: ["Cession de titres", "Securities transfer"]
    },
    {
        value: "bondIssue",
        label: ["Emission obligataire", "Bond issue"]
    },
    {
        value: "other",
        label: ["Autre", "Other"]
    },
]
export const PERIODICITIES_LIST = [
    {
        value: "monthly",
        name: ["Mensuelle", "Monthly"]
    },
    {
        value: "quarterly", 
        name: ["Trimestrielle", "Quarterly"] 
    },
    {
        value: "twiceYearly", 
        name: ["Semestrielle", "Biannual"]
    },
    {
        value: "yearly", 
        name: ["Annuelle", "Annual"]
    },
];
export const EMAIL_RULE = /[\w\._-]+@[\w_-]+[.][a-z]+/;
