<script>
  import AppText from "../../components/Text/AppText.svelte";
  import AppAlert from "../../components/Alert/AppAlert.svelte";
  import AppTooltip from "../../components/Text/AppTooltip.svelte";
  import AppSnackbar from "../../components/Snackbar/AppSnackbar.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
  import AppButton from "../../components/Button/AppButton.svelte";
  import { i18n } from "../../stores/i18n";
  import Dialog, { Content } from "@smui/dialog";
  import Input from "../../components/Form/Input.svelte";
  import API from "../../utils/api";
  export let disabled;
  export let data;
  
  let sliderDialog;
  let snackbar;
  let snack_msg = "";
  let sliderDialogConfirmation;
  let loading = false;
  let firstName = "";
  let lastName = "";
  let company = "";
  let email = "";
  let snack_color = "primary";
  const email_rule = /[\w\._-]+@[\w-]+[.][a-z]+/;
  $: clubDealCoAdvisors = data.clubDealCoAdvisors.map(el=>{return{...el,loading:false}})
  const clubDealCoAdvisorsContains = (email) => {
    return data.clubDealCoAdvisors
      ? data.clubDealCoAdvisors.map((i) => i.email).includes(email)
      : false;
  };
  const format = (date, lang = "fr-FR") => {
    return new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };
  $: isdisabled = !email_rule.test(email) || clubDealCoAdvisorsContains(email);

  const addInvestor = async () => {
    if (!disabled || loading) return;
    sliderDialog.close();
    sliderDialogConfirmation.close();
    try {
      loading = true;
      const response = await API.put("clubdeal", {
        id: data.id,
        clubDealCoAdvisors: [
          ...data.clubDealCoAdvisors,
          { firstName, lastName, company, email },
        ],
      });
      data.clubDealCoAdvisors = [...response.clubDealCoAdvisors];
      firstName = lastName = company = email = "";
    } catch (e) {
      console.error(e);
    } finally {
      loading = false;
    }
  };
  const onClubdealAdvisorCallBack = async (index,subscriberId) => {
    if (clubDealCoAdvisors[index].loading) return;
    clubDealCoAdvisors[index].loading = true;
    try {
      await API.post("clubdealAdvisorCallBack", {
        bondId: data.id,
        subscriberId
      });
      const res = clubDealCoAdvisors[index].firstName +" "+ clubDealCoAdvisors[index].lastName
      snack_color = "primary"
      snack_msg = $i18n(`Relance effectuée pour le co-gestionnaire ${res}`,`Dunning done for the co-advisor ${res}`)
    } catch (e) {
      console.error(e);
      snack_color = "danger"
      snack_msg = $i18n(`Une erreur est survenue`,`An error has occurred`)
    } finally {
      snackbar.forceOpen();
      clubDealCoAdvisors[index].loading = false;
    }
  };
  const removeCoAdvisor = async (advisorEmail) => {
    if (!disabled || loading) return;
    try {
      loading = true;
      let coAdvisors = data.clubDealCoAdvisors.filter(
        (i) => i.email != advisorEmail
      );
      await API.put("clubdeal", {
        id: data.id,
        clubDealCoAdvisors: [...coAdvisors],
      });
      data.clubDealCoAdvisors = [...coAdvisors];
    } catch (e) {
      console.error(e);
    } finally {
      loading = false;
    }
  };

  const cell_style = `
  text-align:center;
  background: rgb(226, 226, 226);
  color: rgb(180, 180, 180);
  font-weight: 500;
  font-family: "Lato";
  font-weight: 700;
  `;
</script>

<AppSnackbar bind:color={snack_color} bind:value={snackbar} labelText={snack_msg}/>

<Spacing y="8" />
<AppAlert material_icon="error_outline" color="progress">
  {$i18n(
    "Dans cette section, vous pouvez renseigner tous les « co-gestionnaires » qui, comme vous, pourront gérer et modifier ce dataroom.",
    "In this section, you can fill in all « co-advisors » who, like you, can manage and modify this dataroom."
  )}
</AppAlert>
<Spacing y="24">
  <Dialog
    style="z-index:1999; width: 100%"
    bind:this={sliderDialogConfirmation}
  >
    <Content>
      <AppText color="blue" weight="900" size="large"
        >{$i18n("Confirmation", "Confirmation")}</AppText
      >
      <Spacing y="8" />
      <AppText
        >{$i18n(
          "Êtes-vous sûr de vouloir ajouter ce co-gestionnaire ?",
          "Are you sure you want to add this co-advisor?"
        )}</AppText
      >
      <Spacing y="8" />
      <AppText>{$i18n("Nom", "Name")}: {firstName} {lastName}</AppText>
      <Spacing y="8" />
      <AppText>{$i18n("Société", "Company")}: {company}</AppText>
      <Spacing y="8" />
      <AppText>{$i18n("Email", "Email")}: {email}</AppText>
      <Spacing y="8" />
      <AppButton block on:click={addInvestor}
        >{$i18n("Ajouter", "Add")}</AppButton
      >
    </Content>
  </Dialog>

  <Dialog style="z-index:999;width: 100%" bind:this={sliderDialog}>
    <Content>
      <AppText color="blue" size="large" weight="900">
        {$i18n("Ajouter un nouveau co-gestionnaire", "Add a new co-advisor")}
      </AppText>
      <Spacing y="12" />
      <div>
        <Input
          label={$i18n("Prénom", "First name")}
          bind:value={firstName}
          crop
        />
        <Input label={$i18n("Nom", "Last name")} bind:value={lastName} crop />
      </div>
      <Spacing y="8" />
      <Input label={$i18n("Société", "Company")} bind:value={company} />
      <Spacing y="8" />
      <Input label="Email" bind:value={email} />
      <AppButton
        disabled={isdisabled}
        block
        on:click={() => sliderDialogConfirmation.open()}
        >{$i18n("Ajouter", "Add")}</AppButton
      >
    </Content>
  </Dialog>
  <DataTable class="table" style="width: 100%">
    <Head>
      <Row>
        <Cell>
          <AppText weight="700">{$i18n("Prénom", "First name")}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n("Nom", "Last name")}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n("Société", "Company")}</AppText>
        </Cell>
        <Cell>
          <AppText weight="700">{$i18n("Email", "Email")}</AppText>
        </Cell>
        {#if data.state == "notSubmitted" || data.state == "subscription"}
          <Cell>
            <AppText weight="700" center="true"
              >{$i18n("Suppression", "Deletion")}</AppText
            >
          </Cell>
        {/if}
      </Row>
    </Head>

    <Body>
      {#if clubDealCoAdvisors.length}
        {#each clubDealCoAdvisors as advisor,index}
          <Row>
            <Cell>{advisor.firstName ? advisor.firstName : ""}</Cell>
            <Cell>{advisor.lastName ? advisor.lastName : ""}</Cell>
            <Cell>{advisor.company ? advisor.company : ""}</Cell>
            <Cell>{advisor.email}</Cell>
            {#if data.state == "notSubmitted" || data.state == "subscription"}
              <Cell style="text-align: center">
                <button
                  class="material-icons"
                  style="border: 0; background-color: white; color: #f02f22; cursor: pointer;"
                  on:click={() => {
                    removeCoAdvisor(advisor.email);
                  }}>delete</button
                >
              </Cell>
            {/if}
          </Row>
        {/each}
      {:else}
        <Row>
          <Cell style={cell_style} colspan="5">
            <p>{$i18n("Aucun co-gestionnaire ajouté", "No co-advisor added")}</p>
          </Cell>
        </Row>
      {/if}
    </Body>
  </DataTable>
  <Spacing y="12" />
  {#if data.state == "notSubmitted" || data.state == "subscription"}
    <AppButton
      {loading}
      icon
      size="big"
      block
      on:click={() => sliderDialog.open()}
    >
      person_add
      <span slot="text"
        ><AppText color="white">
          {$i18n("Ajouter un nouveau co-gestionnaire", "Add a new co-advisor")}
        </AppText></span
      >
    </AppButton>
  {/if}
</Spacing>

<style lang="scss">
  div {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
</style>
