<script>
  export let color = "#fff";
  export let size = "24";
  export let background = "rgba(0,0,0,0)";
  export let radius = "8px";
  const color_style = `
 border-color:${color};
 border-color: ${color} transparent transparent transparent;
`;
  const size_style = `
width: ${size}px;
height: ${size}px;
`;
</script>

<div style={`background:${background};border-radius:${radius}px`}>
  <span style={size_style}><b style={color_style} /></span>
</div>

<style lang="scss">
  div {
    display: block;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.1); */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    /* border-radius: 8px; */
    span {
      display: inline-block;
      position: relative;
      /* width: 24px;
        height: 24px; */
      b {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px solid;
        /* border-color:#fff; */
        border-radius: 50%;
        animation: rotate 800ms cubic-bezier(0.5, 0, 0.2, 0.8) infinite;
        /* border-color: #fff transparent transparent transparent; */
      }
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
