<script>
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import AppCard from "../../components/Card/AppCard.svelte";
  import AppRows from "../../components/DataGrid/AppRows.svelte";
  import { INSTRUMENTS_LISTS, PERIODICITIES_LIST, TYPOLOGIES_LISTS } from "../../constants/index";
  import { i18n, formatNumber, parseNumber } from "../../stores/i18n";
  export let clubDeal = {};

  $: instrument = INSTRUMENTS_LISTS.find(
    (el) => el.value === clubDeal.instrument
  );
  $: typology = TYPOLOGIES_LISTS.find(
    (el) => el.value === clubDeal.typology
  );
  $: periodicity = PERIODICITIES_LIST.find(
    (el) => el.value == clubDeal.periodicity
  );
</script>

<AppCard title={$i18n("Contacter le gestionnaire", "Contact the owner")}>
  <AppRows label={$i18n("Nom", "Name")}>
    {clubDeal.advisor.lastName} {clubDeal.advisor.firstName}
  </AppRows>
  <AppRows label={$i18n("Société", "Company")}>
    {clubDeal.company.name}
  </AppRows>
  <AppRows label={$i18n("Email", "Email")}>
    {clubDeal.advisor.email}
  </AppRows>
  <AppRows label={$i18n("Téléphone", "Phone")} no_diviser>
    {clubDeal.advisor.phone}
  </AppRows>
</AppCard>
<Spacing y="12" />

<AppCard title={$i18n("Caractéristiques de la dataroom", "Features of the dataroom")}>
  <AppRows label={$i18n("Montant total", "Total a mount")}>
    {$formatNumber(clubDeal.amount / 1e6)} M€
  </AppRows>
  {#if instrument && instrument.value && instrument.value != "equity"}
    <AppRows label={$i18n("Taux", "Rate")}>
      {$formatNumber(clubDeal.rate)}%
    </AppRows>
  {/if}

  <AppRows label={$i18n("Typologie de l'opération", "Typology of the operation")}>
    {typology?.label[0] || ""}
  </AppRows>

  <!-- <AppRows label={$i18n("Typologie de financement", "Typology of funding")}>
    {instrument?.label[0] || ""}
  </AppRows> -->
  <!-- {#if clubDeal.instrument == "bondEquityMix"}
    <AppRows label={$i18n("Montant equity", "Equity amount")}>
      {$formatNumber(clubDeal.equityAmount)}
    </AppRows>
  {/if} -->
  {#if clubDeal.maturity > 0}
  <AppRows label={$i18n("Durée", "Duration")}>
    {clubDeal.maturity} {$i18n("mois", "months")}
  </AppRows>
  {/if}
  <!-- {#if instrument && instrument.value && instrument.value != "equity"}
    <AppRows
      label={$i18n("Fréquence des coupons", "Coupon frequency")}
      no_diviser
    >
      {periodicity?.name[0]}
    </AppRows>
  {/if} -->
</AppCard>

<!-- <Spacing y="12" /> -->
