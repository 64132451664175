<script>
  import { createEventDispatcher } from "svelte";
  import AppText from "../Text/AppText.svelte";
  import AppIcon from "../Img/AppIcon.svelte";
  const dispatch = createEventDispatcher();
  let dragover = false;
  export let style = "";

  function onDrop(event) {
    for (const item of event.dataTransfer.items) {
      if (item.kind === "file") {
        dispatch("drop-file", item.getAsFile());
      } else if (item.kind === "string") {
        dispatch("drop-file", event);
      }
    }
    dragover = false;
  }
</script>

<main
  class:dragover
  on:dragover|preventDefault|stopPropagation={() => {
    if (!dragover) {
      dragover = true;
    }
  }}
  on:drop|preventDefault|stopPropagation={onDrop}
  on:dragleave|preventDefault={() => (dragover = false)}
  {style}
>
  <section class:dragover>
    <span style="opacity:0.4;">
      <AppIcon material_icon="file_download" flat size="large" color="normal" />
    </span>
    <AppText weight="bold">Déposer ici</AppText>
  </section>
  <slot />
</main>

<style lang="scss">
  main {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    /* width: calc(100% - 24px); */
    padding: 24px 24px 24px 24px;
    height: 100%;
    border: 3px dotted transparent;
    /* overflow:visible; */
    margin-bottom: 24px;
    transition: all 300ms ease;
    position: relative;
    width: 90%;
    margin: auto;
    &.dragover {
      background: rgba(255, 255, 255, 0.5);
      border: 3px dotted rgba(62, 10, 250, 0.5);
    }
    section {
      position: absolute;
      z-index: 0;
      height: 42px;
      width: calc(90% - 48px);
      box-sizing: content-box;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all 300ms ease;
      cursor: default;
      transform: scale(1.1);
      &.dragover {
        opacity: 0.4;
      }
    }
  }
</style>
