<script>
    import { Icon } from "@smui/icon-button";
    import AppText from "../Text/AppText.svelte";

    export let material_icon = undefined;
    export let color = "blue";
    export let outlined = false;
    export let center = false;
    export let size = "normal";
</script>

<div class="c-appalert--{color}" class:outlined>
    {#if typeof material_icon == "string"}
        <span>
            <Icon class="material-icons">{material_icon}</Icon>
        </span>
    {/if}
    <main class:center>
        <AppText {color} {size}><slot /></AppText>
    </main>
</div>

<style lang="scss">
    div {
        padding: $spacing_large;
        border-radius: 3px;
        display: flex;
        span {
            margin-right: $spacing_medium;
            display: flex;
        }
        main {
            display: flex;
            align-items: center;
            &.center {
                justify-content: center;
                width: 100%;
            }
        }
        &.c-appalert {
            &--blue {
                color: $blue;
                background: rgb(220, 220, 255);
                &.outlined {
                    border: 1px solid $blue;
                    background: none;
                }
            }
            &--progress {
                color: $progress;
                background: rgb(255, 233, 191);
                &.outlined {
                    border: 1px solid $progress;
                    background: none;
                }
            }
        }
    }
</style>
