<script>
  import { navigate } from "svelte-routing";
  import ButtonMenuLanguage from "../Button/ButtonMenuLanguage.svelte";
  import ButtonMenuUser from "../Button/ButtonMenuUser.svelte";
  import LinkNav from "../Link/LinkNav.svelte";
  import LinkLogo from "../Link/LinkLogo.svelte";
  import ItemUser from "../Item/ItemUser.svelte";
  import { CLUBDEAL_PATH, ADVISOR_PATH, LOGIN_PATH } from "../../constants";
  import { login } from "../../stores/index";
  import { profile, profile_links, type } from "../../stores/profile";
  import { username } from "../../stores/profile";
  import { i18n } from "../../stores/i18n";

  $: to =
    !$login || !$type
      ? LOGIN_PATH
      : $login && ($type === "subscriber" || $type === "notQualifiedSubscriber")
      ? CLUBDEAL_PATH
      : $login && $type === "advisor"
      ? ADVISOR_PATH
      : LOGIN_PATH;

  let language = "fr";
  const set_language = (newLanguage) => {
    language = newLanguage.detail;
  };

  async function disconnect_user() {
    login.set(false);
    localStorage.removeItem("token");
    $profile = {};
    navigate(LOGIN_PATH, { replace: true });
    // window.location.reload();
  }
</script>

<header>
  <LinkLogo {to} />
  <div class="c-theheader">
    <!-- TODO: uncomment when all the pages are translated -->

    <div style="margin-left:16px;">
      <ButtonMenuLanguage
        on:set-language={set_language}
        title={$i18n("Langue", "Language")}
      />
    </div>
    {#if $login}
      <span class="desktop">
        {#each $profile_links as { to, text }}
          <LinkNav {to}>{text}</LinkNav>
        {/each}
      </span>
      <div class="c-theheader_user">
        <ButtonMenuUser on:disconnect={disconnect_user}>
          <span class="mobile">
            {#each $profile_links as { to, text }}
              <ItemUser on:click={() => navigate(to)} icon="person_search">
                {text}
              </ItemUser>
            {/each}
          </span>
        </ButtonMenuUser>
      </div>
    {/if}
  </div>
</header>

<style lang="scss">
  .desktop {
    display: none;
    @include respond-to("sm") {
      display: block;
    }
  }
  .mobile {
    display: block;
    @include respond-to("sm") {
      display: none;
    }
  }
  header {
    display: block;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    background: $white;
    height: 64px;
    display: flex;
    align-items: center;
    padding: $spacingX_xxl;
    box-shadow: $bg-shadow;
    justify-content: space-between;
    .c-theheader {
      display: flex;
      align-items: center;
      height: 100%;
      &_user {
        width: auto;
        margin-left: 8px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
</style>
