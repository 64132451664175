import { get, derived, writable } from 'svelte/store';

const { subscribe, set } = writable("fr");
export const i18nLang = {
    subscribe,
    set: l => {
        if (["fr", "en"].includes(l)) {
            set(l)
        }
    }
}

export const i18n = derived(i18nLang, lang => {
    return (fr, en) => lang == "fr" || !en ? fr : en
})

export const formatNumber = derived(i18nLang, lang => {
    return (number) => new Intl.NumberFormat(lang, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(number);
})

export const parseNumber = derived(i18nLang, lang => {
    return (value) => {
        if (typeof(value) == "number"){
            return value;
        } else if (typeof(value) == "string"){
            if (lang == "fr"){
                value = value.replace(/\s/g, "").replace(/,/g, "."); // 1 000 000,20 => 1000000.20
            } else {
                value = value.replace(/\s/g, "").replace(/,/g, ""); // 1,000,000.20 => 1000000.20
            }
            return parseFloat(value)
        }
        return NaN
    }
})

export const formatDate = derived(i18nLang, lang => {
    return (date) => {
        return new Date(date).toLocaleDateString(lang, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        });
    };
})
