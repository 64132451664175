<script>
  import AppText from "../Text/AppText.svelte";
  import Spacing from "../Spacing/Spacing.svelte";
  // import AppBadge from "../Badge/AppBadge.svelte"
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  // export let ca
  // export let amount
  export let disabled = false;
  const onClick = (e) => {
    if (disabled) return;
    dispatch("click", e);
  };
</script>

<main class:disabled on:click|stopPropagation={onClick}>
  <Spacing x="12">
    <header>
      <AppText weight="900"><slot>Inconnu</slot></AppText>
      {#if $$slots.button}
        <aside>
          <slot name="button" />
        </aside>
      {/if}
    </header>
    <!-- <span>
            <i class="material-icons">location_on</i>
            <AppText size="small" color="gray" weight="700">
                <slot name="location">Siège social non renseigné</slot>
            </AppText>
        </span> -->
    <Spacing y="6" />
    <div>
      <!-- {#if amount}
                <AppBadge color="gradient">
                    <div class="badge-1">
                        <AppText color="white" size="small" weight="700">Montant</AppText>
                        <AppText color="white" size="small">{amount}</AppText>
                    </div>
                </AppBadge>
            {/if}
            {#if ca}
                <AppBadge color="white" dark>
                    <div class="badge-2">
                        <AppText size="small" weight="700">CA</AppText>
                        <AppText size="small" color="progress" weight="500">{ca}</AppText>
                    </div>
                </AppBadge>
            {/if} -->
      <!-- {#if !ca && !amount && !disabled}
                <AppBadge color="disabled">
                    <span class="center">
                        <i class="material-icons">error_outline</i>
                        <Spacing x="4"/>
                        Aucune information
                    </span>
                </AppBadge>
            {/if} -->
    </div>
  </Spacing>
</main>

<style lang="scss">
  main {
    width: calc(100% - 24px);
    display: inline-block;
    background: $white;
    padding-top: 12px;
    cursor: pointer;
    margin: 0 12px 12px 12px;
    border-radius: 3px;
    box-shadow: $bg-shadow;
    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      height: 26px;
      aside {
        /* position: absolute; */
        top: 0;
        right: 0;
        z-index: 1;
      }
    }
    /* span {
            display: flex;
            align-items: flex-end;
            position: relative;
            margin-top: 1px;
            left: -2px;
            &.center {
                align-items: center;
            }
            i {
                margin-right: 2px;
                font-size: 14px;
                color: $gray_dark;
            }
        } */
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* &.badge {
                &-1 {
                    width: 88px;
                }
                &-2 {
                    width: 64px;
                }
            } */
    }
  }
</style>
