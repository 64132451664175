<script>
  import { onMount } from "svelte";
  import { Router, Route, navigate } from "svelte-routing";
  import { LOGIN_PATH } from "./constants";
  import { login } from "./stores/index";
  import RouteLogin from "./routes/RouteLogin.svelte";
  import AppHeader from "./components/Layout/AppHeader.svelte";
  import CguDialog from "./components/Dialog/CguDialog.svelte";
  import {
    subscriber_routes,
    advisor_routes,
    global_routes,
  } from "./routes/main";
  import { profile } from "./stores/profile";
  import API from "./utils/api";
  import { i18nLang } from "./stores/i18n";

  let routesLoaded = false;
  $: routes =
    $profile.type === "subscriber" || $profile.type === "notQualifiedSubscriber"
      ? subscriber_routes
      : $profile.type === "advisor"
      ? advisor_routes
      : global_routes;

  onMount(async () => {
    let indexOfToken = window.location.href.indexOf("?token=")
    if (indexOfToken > -1){
      let token = window.location.href.substring(indexOfToken + "?token=".length);
      localStorage.setItem("token", token)
    }
    try {
      // only useful on browser refresh / hot reload
      if (!$profile.type && localStorage.getItem("token")) {
        // TODO
        $profile = {...await API.get("profile"),doubleAuthentification: false};
        $i18nLang = $profile.language;
      }
      const match_public = routes.findIndex((route) => {
        if (route.rules) {
          return route.rules.test(location.pathname);
        }
        return route.path === location.pathname;
      });
      if (match_public == -1 && !$login) {
        navigate(LOGIN_PATH, { replace: true });
      }
      routesLoaded = true;
    } catch (error) {
      login.set(false);
      localStorage.removeItem("token");
      $profile = {};
      navigate(LOGIN_PATH, { replace: true });
      window.location.reload();
    }
  });
</script>

{#if routesLoaded}
  <Router url="">
    {#if ($profile.type === "subscriber" || $profile.type === "notQualifiedSubscriber") && $profile.termsSigned === null}
      <CguDialog />
    {/if}
    <AppHeader />
    {#each routes as { path, component }}
      <Route let:params {path} {component} />
    {/each}
    <Route path="*badAccess" component={RouteLogin} />
  </Router>
{/if}

<style lang="scss">
  :global(.mdc-button) {
    text-transform: unset !important;
  }
  :global(.mdc-checkbox) {
    --mdc-ripple-left: 8px !important;
    --mdc-ripple-top: 8px !important;
    --mdc-ripple-fg-size: 24px !important;
    --mdc-ripple-fg-scale: 1.66667 !important;
  }
  :global(.tooltip) {
    width: auto;
    z-index: 99999999999999;
  }
  :global(.default-tip) {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    min-width: 100px;
    width: 400px;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
  :global(.mdc-menu-surface) {
    box-shadow: $bg-shadow !important;
  }
  :global(.mdc-select) {
    height: 48px !important;
  }
  :global(.mdc-form-field) {
    font-size: 11px !important;
    font-family: "Lato" !important;
  }
  :global(.mdc-radio) {
    width: 36px !important;
    height: 36px !important;
  }
  :global(.mdc-form-field > label) {
    margin-top: 4px;
  }
  :global(.rangeSlider > .rangePips > .pip) {
    font-size: 11px;
    margin-top: 8px;
  }
  :global(.mdc-radio__inner-circle) {
    border-width: 9px !important;
  }
  :global(.rangeSlider),
  :global(.rangeHandle) {
    font-family: "Lato";
    border: 0;
    outline: 0;
  }
  :global(.rangeSlider > .rangeHandle > .rangeFloat) {
    padding: 8px;
  }
  :global(*) {
    --range-float: rgb(21, 21, 255);
    --range-handle: rgb(21, 21, 255);
    --range-handle-inactive: rgb(21, 21, 255);
    --range-handle-focus: rgb(21, 21, 255);
  }
  :global(.date-field) {
    --primary: #2917ff;
  }
  :global(.themed) {
    --placeholderColor: rgb(180, 180, 180);
    --height: 48px;
    --border: 1px solid rgb(170, 170, 170);
    --itemIsActiveBG: rgb(21, 21, 255);
    --borderFocusColor: rgb(21, 21, 255);
    --itemHoverBG: rgb(230, 230, 255);
    --multiItemMargin: 8px 6px 0 0;
    --multiItemActiveBG: rgb(21, 21, 255);
    &.crop {
      --height: 46px;
    }
    font-family: "Lato";
    :global(.selectContainer input) {
      width: calc(100% - 32px) !important;
    }
  }
</style>
