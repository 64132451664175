<script>
  import { onMount, onDestroy, tick } from "svelte";
  import AppContent from "../../components/Layout/AppContent.svelte";
  import AppText from "../../components/Text/AppText.svelte";
  import Input from "../../components/Form/Input.svelte";
  import Radio from "@smui/radio";
  import FormField from "@smui/form-field";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import AppButton from "../../components/Button/AppButton.svelte";
  import RangeSlider from "svelte-range-slider-pips";
  import { clubdeal, sync } from "../../stores/clubdeal";
  import { INSTRUMENTS_LISTS, PERIODICITIES_LIST, TYPOLOGIES_LISTS } from "../../constants";
  import { autoUpdate } from "../../utils/functions/autoUpdate";
  import { i18n } from "../../stores/i18n";
  import AppSnackbar from "../../components/Snackbar/AppSnackbar.svelte";

  export let increment_step;
  export let decrement_step;

  let snackSaved;
  let amount = [0];
  let rate = [0];
  let maturity = [0];
  let loaded = false;

  autoUpdate(
    clubdeal,
    onMount,
    onDestroy,
    (state) => {
      snackSaved.forceOpen();
    },
    async (state) => {
      amount = [$clubdeal.amount / 1e6];
      rate = [$clubdeal.rate];
      maturity = [$clubdeal.maturity];
      await tick();
      loaded = true;
    }
  );

  $: if (loaded) {
    $clubdeal.amount = amount[0] * 1e6;
    $clubdeal.maturity = maturity[0];
    $clubdeal.rate = rate[0];
  }
</script>

<AppContent
  type="advisor"
  title={$i18n(
    "Informations: Caractéristiques de l'opération",
    "Information: Characteristics of the operation"
  )}
>
  <AppText weight="700">{$i18n("Montant:", "Amount:")}</AppText>

  <div class="slider">
    <aside>
      <Input
        min={0.5}
        max={50}
        suffix="M€"
        type="number"
        bind:value={amount[0]}
        on:change={(customEvent) => {
          amount[0] = customEvent.detail.target.value;
        }}
      />
    </aside>
    <section class="pips">
      <RangeSlider
        min={0.5}
        max={50}
        pips
        pipstep={100}
        first="label"
        last="label"
        step={0.1}
        suffix=" M€"
        float
        bind:values={amount}
      />
    </section>
  </div>
  {#if $clubdeal.instrument != "equity"}
    <AppText weight="700"
      >{$i18n(
        "Taux (si applicable - opération obligataire):",
        "Rate (if applicable - bond transaction):"
      )}</AppText
    >
    <div class="slider">
      <aside>
        <Input
          min={0}
          max={15}
          suffix="%"
          type="number"
          bind:value={rate[0]}
          on:change={(customEvent) => {
            rate[0] = customEvent.detail.target.value;
          }}
        />
      </aside>
      <section class="pips">
        <RangeSlider
          min={0}
          max={15}
          last="label"
          first="label"
          pips
          pipstep={50}
          step={0.1}
          suffix="%"
          float
          bind:values={rate}
        />
      </section>
    </div>
  {/if}
  <AppText weight="700">{$i18n("Durée (si applicable):", "Duration (if applicable):")}</AppText>
  <div class="slider">
    <aside>
      <Input
        min={0}
        max={108}
        suffix="m"
        type="number"
        bind:value={maturity[0]}
        on:change={(customEvent) => {
          maturity[0] = customEvent.detail.target.value;
        }}
      />
    </aside>
    <section class="pips">
      <RangeSlider
        min={0}
        max={108}
        last="label"
        first="label"
        pips
        pipstep={2}
        step={6}
        suffix={$i18n(" mois", " months")}
        float
        bind:values={maturity}
      />
    </section>
  </div>
  <AppText weight="700">
    {$i18n("Typologie de l'opération:", "Typology of operation:")}
  </AppText>
  <Spacing y="4" />
  <!-- {#each INSTRUMENTS_LISTS as instrument}
    <FormField>
      <Radio
        color="primary"
        bind:group={$clubdeal.instrument}
        value={instrument.value}
      />
      <span style="margin-right:4px;" slot="label"
        >{$i18n(...instrument.label)}</span
      >
    </FormField>
  {/each} -->
  {#each TYPOLOGIES_LISTS as typology}
    <FormField>
      <Radio
        color="primary"
        bind:group={$clubdeal.typology}
        value={typology.value}
      />
      <span style="margin-right:4px;" slot="label"
        >{$i18n(...typology.label)}</span
      >
    </FormField>
  {/each}
  <!-- <Spacing y="8" />
  {#if $clubdeal.instrument == "bondEquityMix"}
    <Input
      label={$i18n("Montant equity", "Equity amount")}
      min={0}
      type="number"
      suffix="€"
      bind:value={$clubdeal.equityAmount}
    />
  {/if} -->
  <!-- {#if $clubdeal.instrument != "equity"}
    <AppText weight="700">
      {$i18n(
        "Fréquence des coupons (si applicable):",
        "Coupon frequency (if applicable):"
      )}
    </AppText>
    <Spacing y="8" />
    {#each PERIODICITIES_LIST as periodicity}
      <FormField>
        <Radio
          color="primary"
          bind:group={$clubdeal.periodicity}
          value={periodicity.value}
        />
        <span style="margin-right:4px;" slot="label"
          >{$i18n(...periodicity.name)}</span
        >
      </FormField>
    {/each}
    <Spacing y="12" />
  {/if} -->
  <div slot="footer">
    <AppButton on:click={() => decrement_step()}>
      {$i18n("Précédent", "Previous")}
    </AppButton>
    <AppButton on:click={() => increment_step()}>
      {$i18n("Suivant", "Next")}
    </AppButton>
  </div>
  <AppSnackbar
    bind:value={snackSaved}
    labelText={$i18n(
      "Vos informations ont bien été enregistrées",
      "Your information has been saved"
    )}
  />
</AppContent>

<style lang="scss">
  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &.slider {
      align-items: center;
      aside {
        width: 104px;
        height: auto;
      }
      section {
        flex: 1 1 auto;
        margin-left: 20px;
        position: relative;
        bottom: 8px;
        &.pips {
          bottom: 0px;
        }
      }
    }
  }
</style>
