<script>
  import IconButton from "@smui/icon-button";
  import Menu from "@smui/menu";
  import List from "@smui/list";
  import ItemUser from "../Item/ItemUser.svelte";
  import AppButton from "../Button/AppButton.svelte";
  import { i18n } from "../../stores/i18n";
  import { Item } from "@smui/list";
  import { createEventDispatcher } from "svelte";
  import Loading from "../Loading/Loading.svelte";

  export let name;
  export let loading = false;
  export let created = new Date();
  export let isPrivated = false;
  export let readonly = false;
  let menu;
  const dispatch = createEventDispatcher();

  const creationDate_format = (lang = "fr-FR") => {
    created = new Date(created);
    return created.toLocaleDateString(lang, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };
  const setMenu = () => {
    if (!loading) {
      menu.setOpen(true);
    }
  };

  const deleteEvent = async () => {
    await dispatch("delete");
    menu.setOpen(false);
  };
  function openEvent() {
    dispatch("open");
  }

  $: icon = isPrivated ? "lock" : "lock_open";
</script>

<main
  class="formpanelcontent"
  draggable="true"
  on:dragstart={(event) => dispatch("dragstart", event)}
>
  <div class="formpanelcontent-lockstate">
    {#if !loading}
      <span class="material-icons">{icon}</span>
    {:else}
      <Loading color="rgb(180, 180, 180)" />
    {/if}
  </div>
  <div class="formpanelcontent-main" class:loading>
    <a on:click={() => dispatch("download")}>{name}</a>
    <p>
      {$i18n(
        `Créé le ${creationDate_format()}`,
        `Created in ${creationDate_format("en-EN")}`
      )}
    </p>
  </div>
  {#if !readonly}
    <div class="option" class:loading>
      <span on:click|stopPropagation={setMenu}>
        <IconButton class="material-icons gray">more_vert</IconButton>
      </span>
      <div>
        <Menu bind:this={menu}>
          <List>
            <ItemUser on:click={openEvent} icon="build"
              >Options</ItemUser
            >
            <Item>
              <AppButton block color="alert" on:click={deleteEvent}>
                Supprimer
              </AppButton>
            </Item>
          </List>
        </Menu>
      </div>
    </div>
  {/if}
</main>

<style lang="scss">
  .formpanelcontent {
    height: 64px;
    display: flex;
    align-items: center;
    position: relative;
    max-width: 100%;
    transition: background 200ms ease-out;
    .loading {
      cursor: default;
    }
    &:hover {
      background: #f7f7f7;
      .formpanelcontent-lockstate {
        color: #bdbdbd;
      }
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid #f2f2f2;
    }
    &-lockstate {
      width: 37px;
      display: flex;
      justify-content: center;
      color: #e0e0e0;
      position: relative;
    }
    &-main {
      padding-left: 16px;
      width: calc(100% - 131px);
      font-family: "Lato";
      &.loading {
        a {
          cursor: default;
          color: $gray-dark;
        }
      }
      a {
        color: #2f80ed;
        font-size: 14px;
        text-decoration: none;
        // text-decoration: underline;
        cursor: pointer;
      }
      p {
        margin-top: 6px;
        font-size: 12px;
        color: #bdbdbd;
        font-weight: 700;
      }
    }
    .option {
      position: absolute;
      right: 12px;
      color: #bdbdbd;
    }
  }
</style>
