<script>
  import AdvisorContent from "../../components/Layout/AdvisorContent.svelte";
  import { advisor_clubdeal } from "../main_advisor_club_deal";
  import { Router, Route, navigate } from "svelte-routing";
  import SteppersItem from "../../components/Steppers/SteppersItem.svelte";
  import RouteLogin from "../RouteLogin.svelte";
  import AppButton from "../../components/Button/AppButton.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import { onMount, onDestroy } from "svelte";
  import { id, clubdeal } from "../../stores/clubdeal";
  import API from "../../utils/api";
  import { i18n } from "../../stores/i18n";

  let skipped;
  let loading = false;
  let steppers = [
    {
      value: `/manager/dossier/prestation`,
      text: ["Notre prestation", "Our service"],
    },
    {
      value: `/manager/dossier/informations`,
      text: ["Informations", "Information"],
      children: [
        {
          value: `/manager/dossier/informations/society`,
          text: ["Société", "Company"],
        },
        {
          value: `/manager/dossier/informations/caracteristiques`,
          text: [
            "Caractéristiques de l'opération",
            "Operation characteristics",
          ],
        },
        // {
        //   value: `/manager/dossier/informations/participants`,
        //   text: ["Participants", "Participants"],
        // },
      ],
    },
    // {
    //   value: `/manager/dossier/documents`,
    //   text: ["Documents", "Documents"],
    // },
    {
      value: `/manager/dossier/validation`,
      text: ["Validation", "Validation"],
    },
  ];

  let flat_steppers = steppers.flatMap((s) => (s.children ? s.children : s));
  let stepper_index = flat_steppers.findIndex(
    (s) => s.value == location.pathname
  );

  onMount(async () => {
    window.onpopstate = () => {
      stepper_index = flat_steppers.findIndex((s) => {
        return s.value == location.pathname;
      });
      navigate(location.pathname);
    };
    if ($id) {
      try {
        loading = true;
        clubdeal.set(await API.get("clubdeal", { id: $id }));
      } catch (e) {
        clubdeal.reset();
      } finally {
        loading = false;
      }
    }
  });
  onDestroy(() => {
    window.onpopstate = null;
  });

  function increment_step() {
    if (stepper_index < flat_steppers.length) {
      navigate(flat_steppers[++stepper_index].value);
    }
  }
  function decrement_step() {
    if (stepper_index > 0) {
      navigate(flat_steppers[--stepper_index].value);
    }
  }
  function goTo(pathname) {
    if (!skipped) {
      skipped = true;
    }
    stepper_index = flat_steppers.findIndex((s) => s.value == pathname);
    navigate(pathname);
  }
</script>

<AdvisorContent {loading}>
  <div slot="left">
    <div class="main">
      <main>
        {#each steppers as stepper}
          <SteppersItem
            bind:steppers
            bind:flat_steppers
            bind:stepper_index
            bind:stepper
          />
        {/each}
      </main>
      <span />
    </div>
  </div>
  <main style="width:100%;">
    <Router url="">
      {#each advisor_clubdeal as { path, component }}
        <Route
          let:params
          {goTo}
          {decrement_step}
          {increment_step}
          {path}
          {component}
        />
      {/each}
      <Route path="*badAccess" component={RouteLogin} />
      {#if skipped && stepper_index !== flat_steppers.length - 1}
        <div class="skipped">
          <AppButton
            block
            color="secondary"
            on:click={() => goTo("/manager/dossier/validation")}
          >
            {$i18n(
              "Passer à l'étape de validation",
              "Proceed to the validation stage"
            )}
          </AppButton>
        </div>
        <Spacing y="12" />
      {/if}
    </Router>
  </main>
</AdvisorContent>

<style lang="scss">
  div.main {
    position: relative;
    span {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      background: $gray;
      left: 19px;
      z-index: 1;
    }
    main {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
    }
  }
  div.skipped {
    width: calc(100% - 32px);
    margin-right: 32px;
  }
</style>
