<script>
  import AppContent from "../components/Layout/AppContent.svelte";
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import Tab, { Label } from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import Informations from "./RouteAdvisorTab/Informations.svelte";
  import Subscriptions from "./RouteAdvisorTab/Subscriptions.svelte";
  import Investors from "./RouteAdvisorTab/Investors.svelte";
  import Documents from "./RouteAdvisorTab/Documents.svelte";
  import CoAdvisors from "./RouteAdvisorTab/CoAdvisors.svelte";
  import Payments from "./RouteAdvisorTab/Payments.svelte";
  import Log from "./RouteAdvisorTab/Log.svelte";
  import API from "../utils/api";
  import { i18n } from "../stores/i18n";
  import { clubdeal } from "../stores/clubdeal";

  export let id;

  let loading = true;
  let error = false;
  let disabled = true;
  let data = {};

  onMount(async () => {
    try {
      data = await API.get("clubdeal", { id });
      clubdeal.set(data);
      if (data.state == "notSubmitted") {
        Promise.reject("state not authorized");
        error = true;
      }
    } catch (e) {
      console.log(e);
      error = true;
    } finally {
      if (error) {
        navigate("/manager");
      }
      loading = false;
    }
  });

  let tabs = [
    {
      value: "information",
      component: Informations,
      label: ["Informations", "Informations"],
    },
    {
      value: "subscribersSelection",
      component: Investors,
      label: ["Participants", "Participants"],
    },
    {
      value: "documents",
      component: Documents,
      label: ["documents", "documents"],
    },
    // {
    //   value: "subscriptions",
    //   component: Subscriptions,
    //   label: ["Souscriptions", "Subcriptions"],
    // },
    // {
    //   component: Payments,
    //   label: ["Paiements", "Payments"],
    // },
    {
      component: Log,
      label: ["Journal", "Log"],
    },
    {
      value: "coAdvisorsSelection",
      component: CoAdvisors,
      label: ["Co-gestionnaires", "Co-advisors"],
    },
  ];
  let activeTab = tabs[0];
  let name;
  $: {
    if (data.name) {
      name = data.name;
    } else if (data.company && data.company.name) {
      name = data.company.name;
    } else {
      name = "";
    }
  }
  const resetTab = () => {
    activeTab = tabs[0]
  }

</script>

<AppContent
  bind:loading
  title={$i18n("" + name, "" + name)}
>
  <div>
    <TabBar {tabs} let:tab bind:active={activeTab}>
      <Tab {tab}>
        <Label>{$i18n(...tab.label)}</Label>
      </Tab>
    </TabBar>
    <svelte:component this={activeTab.component} {data} {disabled} {resetTab} />
  </div>
</AppContent>

<style lang="scss">
  div {
    width: calc(100% - 64px);
    margin: 0 32px;
    @include respond-to("lg") {
      width: 1140px;
      margin: 0 auto;
    }
  }
</style>
