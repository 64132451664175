<script>
  import AppContent from "../components/Layout/AppContent.svelte";
  import AppAlert from "../components/Alert/AppAlert.svelte";
  import Spacing from "../components/Spacing/Spacing.svelte";
  import TextField from "../components/Text/TextField.svelte";
  import AppText from "../components/Text/AppText.svelte";
  import Input from "../components/Form/Input.svelte";
  import AppButton from "../components/Button/AppButton.svelte";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import AppSnackbar from "../components/Snackbar/AppSnackbar.svelte";
  import API from "../utils/api";
  import { i18n } from "../stores/i18n";
  import { REGISTRATION_THANKYOU } from "../constants";
  import { navigate } from "svelte-routing";
  import { EMAIL_RULE } from "../constants";
  import { delay } from "../utils/functions/delay";
  import { storeEmail } from "../stores/email";
  let lastName = "";
  let firstName = "";
  let company = "";
  let email = "";
  let email_v = "";
  let password = "";
  let pw_v = "";
  let phone = "";
  let mfa = "";
  let validation_snackbar;
  let validation_condition = false;
  let validation_error = false;
  let validation_count = 5;
  const ApiValidate = async () => {
    try {
      validation_loading = true;
      const mine = new Function(await API.get("account", {}))();
      return await API.post("account", {
        lastName,
        firstName,
        company,
        email,
        password,
        phone,
        ...mine(password),
        mfa,
      });
    } catch (e) {
      return Promise.reject(e);
    } finally {
      validation_loading = false;
    }
  };
  $: valide_pw = password !== "" && pw_v !== "" && password === pw_v;
  $: valide_email = email !== "" && email_v !== "" && email === email_v;
  $: error_message = $i18n("Erreur", "Error") + ` ( ${validation_count} s.)`;
  $: is_not_validated =
    lastName === "" ||
    firstName === "" ||
    company === "" ||
    !valide_pw ||
    !valide_email ||
    !EMAIL_RULE.test(email) ||
    phone === "" ||
    !validation_condition;
  let validation_loading = false;
  const onValidate = async () => {
    if (validation_loading || is_not_validated || validation_error) return;
    validation_loading = true;
    try {
      await ApiValidate();
      storeEmail.set(email);
      navigate(REGISTRATION_THANKYOU);
    } catch (e) {
      validation_snackbar.forceOpen();
      console.error(e);
      validation_loading = false;
      validation_error = true;
      await delay(6000, (time_left) => (validation_count = time_left));
      validation_error = false;
    }
  };
</script>

<AppContent
  title={$i18n(
    "Formulaire d'inscription Gestionnaire",
    "Manager registration form"
  )}
>
  <AppSnackbar bind:value={validation_snackbar} color="danger">
    {$i18n(
      "Une erreur est survenue, veuillez recommencer.",
      "An error occurred, please try again."
    )}
  </AppSnackbar>
  <Spacing x="32">
    <AppAlert material_icon="info">
      {@html $i18n(
        "Remplissez les informations ci-dessous pour vous créer un compte <b>Gestionnaire</b>",
        "Fill out the information below to create a <b>Manager</b> account"
      )}
    </AppAlert>
    <Spacing y="8" />
    <TextField>{$i18n("Vos informations", "Your information")}</TextField>
    <Spacing x="32" y="16">
      <div>
        <Input
          required
          label={$i18n("Nom", "Last name")}
          bind:value={lastName}
        />
        <Input
          required
          label={$i18n("Prénom", "First name")}
          bind:value={firstName}
        />
      </div>
      <Input
        required
        label={$i18n("Société", "Company")}
        bind:value={company}
      />
    </Spacing>
    <TextField>{$i18n("Vos identifiants", "Your credentials")}</TextField>
    <Spacing x="32" y="16">
      <Input
        id="email"
        rules={[EMAIL_RULE]}
        message_error={$i18n(
          "Veuillez entrer un email valide.",
          "Please enter a valid email."
        )}
        required
        label={$i18n("Email", "Email")}
        bind:value={email}
      />
      <Input
        id="emailConfirmation"
        rules={[valide_email]}
        message_error={$i18n(
          "Veuillez entrer le même email.",
          "Please enter the same email."
        )}
        required
        label={$i18n("Email de confirmation", "Confirmation email")}
        bind:value={email_v}
      />
      <Input
        id="phone"
        required
        label={$i18n("Votre numéro de téléphone", "Your phone number")}
        bind:value={phone}
      />
      <Input
        id="password"
        required
        type="password"
        label={$i18n("Mot de passe", "Password")}
        bind:value={password}
      />
      <Input
        id="passwordConfirmation"
        required
        rules={[valide_pw]}
        type="password"
        message_error={$i18n(
          "Veuillez entrer le même mot de passe.",
          "Please enter the same password."
        )}
        label={$i18n("Mot de passe de confirmation", "Confirmation password")}
        bind:value={pw_v}
      />
      <Input
        id="mfa"
        required
        label={$i18n(
          "Second facteur d'authentification",
          "Second authentication factor"
        )}
        bind:value={mfa}
      />
      <AppText weight="bolder">
        {$i18n("Conditions d'utilisation:", "Terms and conditions of use:")}
      </AppText>
      <FormField>
        <Checkbox bind:checked={validation_condition} input$required />
        <span slot="label"
          >{$i18n(
            "J'accepte les condition d'utilisation de FirmClosing",
            "I accept the FirmClosing terms of use."
          )}
          (
          <a
            href={$i18n("/assets/terms-fr.pdf", "/assets/terms-en.pdf")}
            target="_blank"
            >{$i18n(
              "Lire les conditions générales d'utilisation",
              "Read the general conditions of use"
            )}</a
          >
          ).</span
        >
      </FormField>
      <section>
        <AppButton
          loading={validation_loading}
          error={validation_error}
          on:click={onValidate}
          {error_message}
          disabled={is_not_validated}
        >
          {$i18n("S'inscrire", "Sign up")}
        </AppButton>
      </section>
    </Spacing>
    <Spacing y="8" />
  </Spacing>
</AppContent>

<style lang="scss">
  div {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  section {
    display: flex;
    justify-content: flex-end;
  }
  a {
    color: $blue;
    font-weight: bold;
  }
</style>
