<script>
  import AppText from "../Text/AppText.svelte";

  export let title;
</script>

<section>
  <h1>
    <AppText color="white" weight="700">{title}</AppText>
  </h1>
  <aside>
    <slot name="header" />
    <slot />
  </aside>
</section>

<style lang="scss">
  section {
    width: 100%;
    margin-bottom: 16px;
    @include respond-to("md") {
      width: 32%;
      max-width: 340px;
    }

    border-radius: 4px;
    background: #ebecf0;
    height: auto;
    min-height: 102px;
    @include respond-to("md") {
      height: calc(100vh - 168px);
      min-height: 412px;
    }
    transition: all 400ms ease;
    h1 {
      height: 48px;
      background: $blue;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      display: flex;
      align-items: center;
      padding-left: $spacing_medium;
    }
    aside {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 12px;
      width: 100%;
      overflow: auto;
      height: calc(100% - 60px);
    }
  }
</style>
