<script>
  import Snackbar, { Label } from "@smui/snackbar";
  export let value;
  export let leading = false;
  export let color = "primary";
  export let timeoutMs = 4000;
  export let labelText = "";
  export let style;
  const LISTS_COLORS = ["primary", "danger"];
  if (!LISTS_COLORS.includes(color)) {
    throw new Error(
      `color:${color} in AppSnackbar is not possible, try 'primary' or 'danger' value`
    );
  }
</script>

<div>
  <Snackbar
    class="snackbar--{color}"
    variant="stacked"
    {style}
    {leading}
    {timeoutMs}
    bind:this={value}
  >
    <Label>
      {#if labelText === ""}
        <slot />
      {:else}
        <span>{labelText}</span>
      {/if}
    </Label>
  </Snackbar>
</div>

<style lang="scss">
  :global(.snackbar--primary .mdc-snackbar__surface) {
    background-color: $blue !important;
  }
  :global(.snackbar--danger .mdc-snackbar__surface) {
    background-color: $danger !important;
  }
</style>
